<template>
  <div>
    <div class="jobs-wrapper">
      <div
        v-for="(job, index) in jobs"
        :key="index"

        class="box"
        :style="`--tw-delay: ${index + 1}`"
      >
        <div class="single-job" @click="openLink(job.descriptionHref)">
          <h2>{{ job.title }}</h2>
          <div class="location">
            <icon-location />
            <p>{{ job.location }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Job } from '../../content'
  import { fetchJobs } from '../../content'
  import { locale } from '../../init/i18n'

  export default defineComponent({
    components: {
    },
    data: () => ({
      active_jobs: [] as number[],
      jobs: [] as Job[],
    }),
    async mounted() {
      this.jobs = await fetchJobs(locale.value)
    },
    methods: {
      openModal(job: Job) {
      },
      openLink(link: string) {
        window.open(link, '_blank')
      },
    },
  })
</script>

