<!--==================================================================+
| MODAL: YES/NO                                                       |
+===================================================================-->

<template>
  <juit-modal
    ref="modal"
    class="small"
    @dismiss-modal="dismiss(confirmed)"
  >
    <slot>
      <div class="w-full text-center">
        <div>
          <h2 class="mt-0 mb-3">
            {{ modalContent.title }}
          </h2>
          <p v-if="modalContent.description">
            {{ modalContent.description }}
          </p>
          <juit-button class="w-full small mt-4" @click="closeModal(true)">
            {{ modalContent.yes }}
          </juit-button>
          <div class="w-full juit-link mt-2 -mb-1" @click="closeModal(false)">
            {{ modalContent.no }}
          </div>
        </div>
      </div>
    </slot>
  </juit-modal>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'

  import JuitModal from './modal.vue'
  import juitButton from '../widgets/juit-button.vue'

  interface modalContent {
    title: string,
    description?: string,
    yes: string,
    no: string,
  }

  export default defineComponent({
    components: { juitButton, JuitModal },

    props: {
      modalContent: {
        type: Object as PropType<modalContent>,
        reqired: true,
        default: null,
      },
    },
    emits: {
      dismissModal: (confirm: boolean) => true,
    },
    data: () => ({
      confirmed: false,
    }),

    computed: {
      modal() {
        return this.$refs['modal'] as InstanceType<typeof JuitModal>
      },
    },

    methods: {
      closeModal(confirm: boolean) {
        this.confirmed = confirm
        this.modal.closeModal()
      },
      dismiss(confirm: boolean) {
        this.$emit('dismissModal', confirm)
      },
    },
  })
</script>
