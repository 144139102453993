import { createCommentVNode, DefineComponent, defineComponent, h, PropType, warn } from '@vue/runtime-core'
import { Module } from '../content'

import dataContent from './data-content.vue'
import dishCarousel from './dish-carousel.vue'
import dishShowcase from './dish-showcase.vue'
import boxList from './box-list.vue'
import testimonialList from './testimonial-list.vue'
import userRatings from './user-ratings.vue'
import iconTextList from './icon-text-list.vue'
import panel from './panel.vue'
import panelList from './panel-list.vue'
import pressLogoList from './logo-wall.vue'
import qaList from './faq.vue'

type AnyComponent = DefineComponent<any, any, any, any, any, any, any, any, any, any, any, any>

const components: Record<Module['$type'], AnyComponent | false> = {
  boxList: boxList,
  dataContent: dataContent,
  dishCarousel: dishCarousel,
  dishShowcase: dishShowcase,
  iconTextList: iconTextList,
  qaList: qaList,
  pressLogoList: pressLogoList,
  testimonialList: testimonialList,
  panel: panel,
  panelList: panelList,
  userRatings: userRatings,
}

export default defineComponent({
  inheritAttrs: false,
  props: {
    module: {
      type: Object as PropType<Module>,
      required: true,
    },
  },
  render() {
    const component = components[this.module.$type]
    const module = this.module
    if (component) {
      return h(component, { ...this.$attrs, module }, this.$slots)
    } else {
      warn(`No component for module of type "${this.module.$type}"`)
      return createCommentVNode(` module "${this.module.$type}" missing - ${JSON.stringify(this.$attrs)}`)
    }
  },
})
