<!--==================================================================+
| WIDGET: CAROUSEL                                                    |
+===================================================================-->

<template>
  <div ref="wrapper" class="carousel-and-arrows">
    <!--==================================================================+
    | CAROUSEL ARROWS                                                     |
    +===================================================================-->
    <div
      class="arrow-wrapper"
      :class="{ hide: arrow_invisible_left }"
      :style="dynamicArrowHeight ? `height: ${cardWidth}px` : ''"
      @click="scrollFowrard(false)"
    >
      <arrow class="arrow" />
    </div>
    <div
      class="arrow-wrapper right"
      :class="{ hide: arrow_invisible_right }"
      :style="dynamicArrowHeight ? `height: ${cardWidth}px` : ''"
      @click="scrollFowrard(true)"
    >
      <arrow class="arrow" />
    </div>
    <!--==================================================================+
    | CAROUSEL OR SHOWCASE VIEW                                           |
    +===================================================================-->
    <div class="roller-wrapper">
      <div
        ref="roller"
        class="tiled no-scrollbar"
        @scroll.passive="handleScroll"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { arrow } from '../assets/async'

  export default defineComponent({
    components: {
      arrow,
    },
    props: {
      scrollMultiples: {
        type: Number,
        default: 1,
      },
      cardWidth: {
        type: Number,
        default: 0,
      },
      dynamicArrowHeight: {
        type: Boolean,
        default: false,
      },
      scrollReset: {
        type: Boolean,
        default: false,
      },
    },
    emits: [ 'scrollEnd', 'activeIndex', 'arrow' ],
    data: () => ({
      arrow_invisible_left: true,
      arrow_invisible_right: true,
      observer: undefined as undefined | MutationObserver,
      testimonials_snap_point: 0,
    }),

    computed: {
      /* ========================================================================== *
       * Carousel related                                                           *
       * -------------------------------------------------------------------------- */
      wrapper(): HTMLElement {
        return this.$refs.wrapper as HTMLElement
      },
      roller(): HTMLElement {
        return this.$refs.roller as HTMLElement
      },
      scroll_value(): number {
        return this.scrollMultiples ? (this.cardWidth * this.scrollMultiples) : 300
      },
    },

    /* ========================================================================== *
     * We observe the scrollReset property coming from the testimonial-list       *
     * -------------------------------------------------------------------------- */

    watch: {
      scrollReset() {
        if (this.scrollReset === true) this.roller.scroll({ left: 0, behavior: 'smooth' })
      },
    },

    /* ========================================================================== *
     * Checking carousel arrow visibility on carousel DOM change and resize       *
     * -------------------------------------------------------------------------- */
    mounted() {
      window.addEventListener('resize', this.handleScroll)
      if (this.roller) {
        this.arrowVisibilityCheck()
        this.setObserver()
      }
    },
    unmounted() {
      window.removeEventListener('resize', this.handleScroll)
      if (this.observer) this.observer.disconnect()
    },


    methods: {
      /* ========================================================================== *
       * Carousel related                                                           *
       * -------------------------------------------------------------------------- */
      // when the user clicks the arrows:
      scrollFowrard(forward: boolean) {
        this.roller.scrollLeft = (Math.round(this.roller.scrollLeft / this.scroll_value) + (forward ? 1 : -1)) * this.scroll_value
        // by the end of the scroll, we emit an event to the testimonial-list component, to fetch more comments
        if (this.roller.scrollLeft + this.roller.clientWidth >= this.roller.scrollWidth - (this.cardWidth * 25)) this.$emit('scrollEnd')
        this.$emit('arrow', forward)
      },

      // when scrolling and on resize:
      handleScroll() {
        if (!this.roller) return
        this.arrowVisibilityCheck()
        // by the end of the scroll, we emit an event to the testimonial-list component, to fetch more comments
        if (this.roller.scrollLeft + this.roller.clientWidth >= this.roller.scrollWidth - (this.cardWidth * 25)) this.$emit('scrollEnd')
        // If scrollMultiples is 1, emit the index of currently visible card
        if (this.scrollMultiples === 1) this.$emit('activeIndex', Math.round(this.roller.scrollLeft / this.cardWidth))
      },

      arrowVisibilityCheck() {
        if (!this.roller) return
        if (this.roller.scrollLeft > 1) this.arrow_invisible_left = false
        else this.arrow_invisible_left = true

        if (this.roller.scrollLeft + this.roller.clientWidth >= this.roller.scrollWidth - 2) this.arrow_invisible_right = true
        else this.arrow_invisible_right = false
      },

      // Setup the observer
      setObserver() {
        this.observer = new MutationObserver(() => this.handleScroll())
        this.observer.observe(
          this.roller,
          { attributes: true, childList: true, characterData: true, subtree: true },
        )
      },
    },
  })
</script>

<style scoped lang="pcss">
.carousel-and-arrows {
  @apply relative w-full;

  .roller-wrapper {
    @apply flex overflow-hidden;
    @apply justify-center sm:justify-start;
    @apply transition-all duration-700;
    > .tiled {
      @apply w-full flex flex-nowrap m-0;
      @apply overflow-y-hidden md:overflow-x-scroll;
      @apply md:justify-start;
      @apply transition-all duration-700;
      @apply snap-mandatory snap-x;
      :deep() .tile {
        @apply px-1 mobile:py-1;
      }
    }
  }

  .arrow-wrapper {
    text-align: initial;
    @apply absolute left-0 h-full flex z-30;
    @apply opacity-100 pointer-events-auto transition-opacity cursor-pointer;
    &:before {
      content: '';
      @apply absolute transform top-1/2 -translate-y-1/2;
    }
    .arrow {
      @apply text-black w-6 relative self-center transform rotate-180;
    }
    &.right {
      @apply left-auto right-0;
      &:before {
        @apply right-0;
      }
      .arrow {
        @apply rotate-0;
      }
    }
    &.hide {
      @apply opacity-0 pointer-events-none;
    }
  }
}

/* ====================================================================== *
 * Arrow styles variants                                                  *
 * ====================================================================== */

/* Arrows with solid colored box as background */
.solid-arrow {
  .arrow-wrapper {
    &:before {
      @apply bg-black w-12 h-14;
    }
    .arrow {
      @apply left-2.5 text-ash;
    }
    &.right .arrow {
      @apply left-auto right-2.5;
    }
  }
  &.small-arrow {
    .arrow-wrapper {
      &:before {
        @apply w-8 h-10;
      }
      .arrow {
        @apply left-1;
      }
      &.right .arrow {
        @apply left-auto right-1;
      }
    }
  }
  &.mobile-only-small {
    .arrow-wrapper {
      &:before {
        @apply mobile:w-8 mobile:h-10;
      }
      .arrow {
        @apply mobile:left-0.5;
      }
      &.right .arrow {
        @apply mobile:left-auto mobile:right-0.5;
      }
    }
  }
}

/* Arrows with color gradient background */
.gradient-arrow {
  .arrow-wrapper {
    &:before {
      width: 160%;
      @apply h-full;
      @apply bg-gradient-to-r from-ash via-ash pointer-events-none;
    }
    &.right {
      &:before {
        @apply bg-gradient-to-l;
      }
    }
  }
  &.desktop-only-gradient {
    .arrow-wrapper:before {
      @apply h-0 sm:h-full;
    }
  }
}

/* Smaller Arrows */
.small-arrow {
  .arrow {
    @apply transform scale-65 -left-1;
  }
  .arrow-wrapper.right .arrow {
    @apply left-auto -right-1;
  }
}
.mobile-only-small .arrow {
  @apply scale-100 mobile:scale-65;
}

/* Arrows only on desktop */
.desktop-only-arrow {
  .arrow-wrapper {
    @apply hidden md:flex;
  }
  .roller-wrapper > .tiled {
    @apply flex-wrap md:flex-nowrap;
  }
}

/* Color variations */
.white-carousel-bg {
  .carousel-and-arrows .arrow-wrapper:before {
    @apply from-white via-white;
  }
}
</style>
