import { log } from '../init/log'

/* ========================================================================== *
 * LOADING LINKEDIN TAG                                                       *
 * ========================================================================== */

const loadedLinkedinTags = new Set<string>()

export function loadLinkedinTag(pixelId: string): void {
  if (!pixelId) return log('No Linkedin Tag on dev') // Track prod only
  if (loadedLinkedinTags.has(pixelId)) return log(`Linkedin Tag ${pixelId} already loaded`)

  log('Loading Linkedin Tag', pixelId)
  const script = document.createElement('script')
  script.text = `
    _linkedin_partner_id = "${pixelId}";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    (function(l) {
    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
    window.lintrk.q=[]}
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);})(window.lintrk);
  `
  // Append our script to the end of the head
  document.head.appendChild(script)
  loadedLinkedinTags.add(pixelId)
}
