<!--==================================================================+
| MODULE-COMPONENT: TESTIMONIALS                                      |
+===================================================================-->

<template>
  <div v-if="module" class="container">
    <h1>{{ module.title }}</h1>

    <!--==================================================================+
    | CAROUSEL ROLLER                                                     |
    +===================================================================-->
    <juit-carousel
      class="solid-arrow mobile-only-small"
      :card-width="cardwidth"
      @active-index="activeindex = $event"
    >
      <div
        v-for="(item, $index) in module.list"
        :key="$index"
        ref="card"
        class="tile snap-start"
      >
        <div class="card">
          <juit-image :src="item.image.src" />
          <div class="text">
            <rich-text class="comment" :class="[ locale ]" :document="item.description" />
            <p class="name">
              {{ item.title }}
            </p>
            <p class="subtitle -mt-0.5">
              {{ item.subtitle }}
            </p>
          </div>
        </div>
      </div>
    </juit-carousel>
    <div class="flex mx-auto mt-4 h-4 sm-testm:hidden">
      <div
        v-for="(item, $index) in module.list"
        :key="$index"
        class="indicator"
        :class="{ active: $index === activeindex }"
        @cardwidth="cardwidth = $event"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { TestimonialList } from '../content/convert'
  import juitCarousel from '../widgets/juit-carousel.vue'
  import { locale } from '../init/i18n'

  export default defineComponent({
    components: { juitCarousel },
    props: {
      module: {
        type: Object as PropType<TestimonialList>,
        default: null,
      },
    },
    data: () => ({
      activeindex: 0,
      cardwidth: 0,
      locale,
    }),

    mounted() {
      this.getCardWidth()
      window.addEventListener('resize', this.getCardWidth)
    },
    unmounted() {
      window.removeEventListener('resize', this.getCardWidth)
    },

    methods: {
      getCardWidth() {
        const cards = this.$refs.card as HTMLElement[]
        if (cards?.length) this.cardwidth = cards[0].getBoundingClientRect().width
      },
    },
  })

</script>

<style scoped lang="pcss">
:deep() .carousel-and-arrows {
  .roller-wrapper {
    @apply sm:-mx-1.5 lg:-mx-3 mt-4;
    .tiled {
      @apply mx-0 py-0;
      .tile {
        @apply px-1 py-0 sm:px-1.5 lg:px-3;
      }
    }
  }
  .arrow-wrapper {
    @apply md:hidden -left-4 sm:-left-6 md:-left-8 lg:-left-16;
    &.right {
      @apply left-auto -right-4 sm:-right-6 md:-right-8 lg:-right-16;
    }
  }
}
.indicator {
  @apply w-2.5 h-2.5 bg-black mx-1.5 self-center transition-all;
  &.active {
    @apply w-4 h-4 -mt-0.5;
  }
}
.tile {
  @apply w-full sm-testm:w-1/2 md:w-1/3;
  .card {
    @apply text-left border-2 h-full border-black bg-white flex flex-col text-center;
    .text {
      @apply py-6 px-2 sm:px-4;
      .name {
        @apply text-red font-headline text-4xl lg:text-5xl mt-3;
      }
      .comment {
        @apply leading-tight font-semibold px-6 relative;
        &:before {
          content: '„';
          @apply absolute text-5xl sm:text-6xl font-headline font-bold left-1.5 sm:left-0 -bottom-3;
        }
        &:after {
          content: '“';
          @apply absolute text-5xl sm:text-6xl font-headline font-bold -top-3 right-1.5 sm:right-0;
        }
        &.en {
          &:before {
            content: '“';
            @apply -top-3;
          }
          &:after {
            content: '”';
            @apply -bottom-8 sm:-bottom-11 top-auto;
          }
        }
      }
    }
  }
}
</style>
