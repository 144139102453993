/* ========================================================================== *
 * VUE INTERNATIONALIZATION                                                   *
 * -------------------------------------------------------------------------- *
 * Attempt to determine the initial state of our i18n from the browser, but   *
 * let's make sure to support also SSR.                                       *
 * ========================================================================== */
import { readonly, ref } from '@vue/runtime-core'
import { App } from 'vue'
import { DefaultLocale, fetchTranslations, fetchTranslationsRich, Locale } from '../content'
import { env } from './env'
import { log } from './log'

/* -------------------------------------------------------------------------- *
 * Initial Values                                                             *
 * -------------------------------------------------------------------------- */

/** Our Locale type */
export type { Locale } from '../content'

/** The fallback (default) locale is "en" */
export const fallbackLocale: Locale & DefaultLocale = 'en'

/** An array of all available locales */
export const availableLocales: readonly Locale[] = [ 'en', 'de' ]

/** The current locale, either determined by the browser or the fallback */
export const browserLocale: Locale = (() => {
  // If we're rendering server-side, initial locale is always fallback
  if (env.SSR) return fallbackLocale

  // First check if the _path_ of the browser's location is a locale
  if (globalThis.window.location.pathname) {
    const match = /^\/(en|de)(\/|$)/.exec(globalThis.window.location.pathname)
    if (match) return match[1] as Locale
  }

  // The "navigator.languages" property doesn't exist on IE, it seems
  if (globalThis.navigator.languages) {
    for (const language of navigator.languages) {
      const locale = toLocale(language)
      if (locale) return locale
    }
  }

  // The "navigator.language" property doesn't exist on IE < 11, it seems
  if (globalThis.navigator.language) {
    const locale = toLocale(navigator.language)
    if (locale) return locale
  }

  // We couldn't determine the locale from the browser, so default it
  return fallbackLocale
})()

/** Check if a language (e.g. "en-US") is in our locales */
function toLocale(language: string = ''): Locale | undefined {
  const locale = language.trim().split(/-|_/)[0].toLowerCase() as Locale
  if (availableLocales.includes(locale)) return locale
}

/* -------------------------------------------------------------------------- *
 * Vue I18n Plugin                                                            *
 * -------------------------------------------------------------------------- */

/** Initial messages (simple translation of language names) */
const messages = {
  en: { en: 'English', de: 'German' } as Record<string, string>,
  de: { en: 'Englisch', de: 'Deutsch' } as Record<string, string>,
} as const

/** Our "$t" translation function */
function $t(key: string, props?: Record<string, string | number | undefined>): string {
  // Check that we actually _do_ have a translation message with a key
  if (!(key in messages[locale.value])) {
    log(`Translation key "%c${key}%c" for locale "%c${locale.value}%c" not found`,
        'color: crimson', 'color: inherit', 'color: crimson', 'color: inherit')
    return `[${key}]`
  }

  // Get the message, and if no options were specified, return it
  let message = messages[locale.value][key]
  if (! props) return message

  // Replace any property `{ prop }` with the associated value
  for (const [ prop, value ] of Object.entries(props)) {
    const string = // eslint-disable-next-line new-cap
      typeof value === 'number' ? Intl.NumberFormat(locale.value).format(value) :
      typeof value === 'string' ? value :
      value == undefined ? '' : // loose check
      String(value)

    // Expression matches `{ xxx }` where `{` is _not_ preceded by a '\'
    const expr = new RegExp(`([^\\\\]|^)({\\s*${prop}\\s*})`, 'gi')
    message = message.replaceAll(expr, string)
  }
  // Done, translated!
  return message
}

/** Plugin install fuinction */
export function i18n(app: App): void {
  app.config.globalProperties.$t = $t
  app.config.globalProperties.$i18n = {
    get locale() {
      return locale.value
    },
  }
}

/** Plugin type declaration */
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $t: typeof $t
    $i18n: {
      readonly locale: Locale
    },
  }
}

/* -------------------------------------------------------------------------- *
 * Locale switching                                                           *
 * -------------------------------------------------------------------------- */

/** Our watchable `ref` containing the locale */
const localeRef = ref(browserLocale)

/** Flags indicating whether translations for the given locale are merged */
const hasTranslations: Partial<Record<Locale, true>> = {}

/** A `Locale` type guard */
export function isLocale(locale: any): locale is Locale {
  return availableLocales.includes(locale as any)
}

/** A watchable ref for the _current_ locale value */
export const locale = readonly(localeRef)

/** Asynchronously set the locale after downloading translations */
export async function setLocale(locale: Locale): Promise<void> {
  if (! isLocale(locale)) throw new Error(`Wrong locale "${locale}"`)

  if (! hasTranslations[locale]) {
    // Get plain and rich translations _in parallel_
    const [ translations /* richTranslations */ ] = await Promise.all([
      fetchTranslations(locale),
      fetchTranslationsRich(locale),
    ])

    // Normalize translations to `prefix.key` for quick access
    for (const [ prefix, values ] of Object.entries(translations)) {
      for (const [ key, value ] of Object.entries(values)) {
        messages[locale][`${prefix}.${key}`] = value
      }
    }

    // We got the translations, never fetch them again
    hasTranslations[locale] = true
  }

  // Remember the locale we switched to...
  localeRef.value = locale
}
