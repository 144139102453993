<!--==================================================================+
| SUBMITTING OVERLAY AND SPINNER                                      |
+===================================================================-->
<template>
  <div v-if="visible" class="overlay">
    <juit-spinner :class="{ large }" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'JuiSubmitting',
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
      large: {
        type: Boolean,
        default: true,
      },
    },
  })
</script>

<style scoped lang="pcss">
  .overlay {
    @apply top-0 left-0;
    @apply w-full h-full;
    @apply z-30;
    @apply absolute;
    @apply bg-white bg-opacity-70;

    .large {
      @apply top-1/2 left-1/2;
      @apply absolute;
      @apply transform -translate-x-1/2 -translate-y-1/2;
      @apply z-50;
    }
  }
</style>

