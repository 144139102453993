<!--==================================================================+
| VIEW: MAINTAINENCE                                                  |
+===================================================================-->
<template>
  <main>
    <div class="info">
      <h1>JUIT IST GLEICH ZURÜCK!</h1>
      <div class="subheading mt-5">
        Wir nehmen eine kurze Auszeit für Wartungsarbeiten.<br>
        Bitte versuche es in ein paar Minuten nochmal.
      </div>
      <div class="border-b border-gray-300 w-96 max-w-[90%] my-4 mx-auto" />
      <div class="subheading">
        We are taking time out for maintenance.<br>
        Please try again in a few minutes.
      </div>
    </div>
  </main>
</template>

<style scoped lang="pcss">
  .info {
    @apply text-center mt-32 px-8;
  }
</style>
