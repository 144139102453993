<template>
  <div class="constrained no-scrollbar">
    <h2 class="mt-1 mb-2">
      {{ $t( 'cookies.settings-title' ) }}
    </h2>
    <p class="mb-5">
      {{ $t( 'cookies.settings-description' ) }}
    </p>
    <!-- COOKIE TIERS SELECTION -->
    <div class="mb-4">
      <label class="mb-3 radio-label">
        <input
          v-model="currentTier"
          type="radio"
          value="1"
        >
        <div class="radio-checked" />
        <p class="pl-6 font-semibold mb-1">
          {{ $t( 'cookies.settings-tier-1-title' ) }}
        </p>
        <p class="pl-6 text-sm">
          {{ $t( 'cookies.settings-tier-1-description' ) }}
        </p>
      </label>
      <label class="mb-3 radio-label">
        <input
          v-model="currentTier"
          type="radio"
          value="2"
        >
        <div class="radio-checked" />
        <p class="pl-6 font-semibold mb-1">
          {{ $t( 'cookies.settings-tier-2-title' ) }}
        </p>
        <p class="pl-6 text-sm">
          {{ $t( 'cookies.settings-tier-2-description' ) }}
        </p>
      </label>
      <label class="mb-3 radio-label">
        <input
          v-model="currentTier"
          type="radio"
          value="3"
        >
        <div class="radio-checked" />
        <p class="pl-6 font-semibold mb-1">
          {{ $t( 'cookies.settings-tier-3-title' ) }}
        </p>
        <p class="pl-6 text-sm">
          {{ $t( 'cookies.settings-tier-3-description' ) }}
        </p>
      </label>
    </div>
  </div>

  <!-- BACK TO INTRO OR SAVE COOKIE -->
  <div class="modal-footer">
    <div class="juit-link py-3 pr-3" @click="$emit('back')">
      {{ $t( 'cookies.back' ) }}
    </div>
    <juit-button class="small" @click="$emit('save', currentTier)">
      {{ $t( 'cookies.save' ) }}
    </juit-button>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { PrivacyTier } from '../../analytics/privacy'

  export default defineComponent({
    name: 'JuitCookiesSettings',
    props: {
      tier: {
        type: Number as PropType<PrivacyTier>,
        required: true,
      },
    },
    emits: {
      back: () => true,
      save: (tier: PrivacyTier) => true,
    },
    data() {
      return { currentTier: this.tier }
    },
  })
</script>


<style scoped lang="pcss">
  .constrained:deep() {
    max-height: calc(60vh - 5rem);
    @apply overflow-y-scroll overflow-x-hidden pb-4;
  }
  .modal-footer {
    @apply flex-row -mb-2 sm:-mb-4;
  }
</style>
