/* ===================================================================+
| WIDGET: RATING STARS (NOT INTERACTIVE)                              |
+=================================================================== */

import { defineComponent, h } from '@vue/runtime-core'

import {
  stars0,
  stars1,
  stars2,
  stars3,
  stars4,
  stars5,
  stars05,
  stars15,
  stars25,
  stars35,
  stars45,
  stars375,
  stars425,
  stars475,
} from '../assets/async'

const stars = [
  stars0,
  stars1,
  stars2,
  stars3,
  stars4,
  stars5,
  stars05,
  stars15,
  stars25,
  stars35,
  stars45,
  stars375,
  stars425,
  stars475,
]

export default defineComponent({
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      required: true,
      validator: (value: number) => [ 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5 ].includes(value),
    },
  },
  render() {
    // Full stars
    if (Number.isInteger(this.value)) return h(stars[this.value], this.$attrs)
    // Half stars
    else if (this.value.toString().length === 3) return h(stars[Math.floor(this.value)+6], this.$attrs)
    // Quarter stars
    else if (this.value === 3.75) return h(stars[11], this.$attrs)
    else if (this.value === 4.25) return h(stars[12], this.$attrs)
    else if (this.value === 4.75) return h(stars[13], this.$attrs)
  },
})
