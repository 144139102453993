<!--==================================================================+
| MODAL: RATE DISH                                                    |
+===================================================================-->

<template>
  <juit-modal
    ref="modal"
    class="small text-center"
    :locked="locked"
  >
    <slot>
      <div class="flex flex-wrap">
        <h2 class="mt-0 w-full">
          {{ title }}
        </h2>
        <p v-if="description" class="w-full">
          {{ description }}
        </p>
        <juit-button
          v-if="url"
          class="mt-4 mx-auto"
          @click="closeModal(url)"
        >
          {{ $t( 'checkout.alert-ok' ) }}
        </juit-button>
        <juit-button
          v-else
          class="mt-4 mx-auto"
          @click="location.reload()"
        >
          {{ $t( 'checkout.alert-ok' ) }}
        </juit-button>
      </div>
    </slot>
  </juit-modal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import JuitModal from './modal.vue'

  export default defineComponent({
    components: { JuitModal },

    props: {
      title: {
        type: String,
        reqired: true,
        default: '',
      },
      description: {
        type: String,
        reqired: true,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
      locked: {
        type: Boolean,
        reqired: true,
        default: false,
      },
    },

    data: () => ({
      location: window.location,
    }),

    computed: {
      modal() {
        return this.$refs['modal'] as InstanceType<typeof JuitModal>
      },
    },

    methods: {
      closeModal(url: string) {
        this.modal.closeModal()
        if (url) this.$router.push(url)
      },
    },
  })
</script>
