<!--==================================================================+
| COMPONENT: GENERAL PROFILE-ALERT                                    |
+===================================================================-->

<template>
  <div
    v-if="show && notification"
    class="profile-alert-wrapper"
    :class="{ closing }"
    @animationend="() => closing ? resolveEvent() : undefined"
  >
    <div class="profile-alert">
      <h2>{{ $t( `notification.${notification}-headline` ) }}</h2>
      <span class="subheading">{{ $t( `notification.${notification}-subline` ) }}</span>
      <div class="flex">
        <juit-button
          class="button mr-3 xs:mr-4"
          @click="close()"
        >
          {{ $t( `notification.${notification}-button` ) }}
        </juit-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { alertNotification } from '../../init/state'

  export default defineComponent({
    data: () => ({
      closing: false,
    }),

    computed: {
      // Only show on profile page
      show() {
        return this.$route.path.includes('profile')
      },
      notification() {
        return alertNotification.value
      },
    },

    methods: {
      // Close the box and update the last-ingored timestamp
      close() {
        this.closing = true
      },

      resolveEvent() {
        alertNotification.value = ''
      },
    },

  })
</script>

<style scoped lang="pcss">
  .profile-alert-wrapper {
    @apply fixed z-40;
    @apply right-0 xs:right-4 top-28 sm:right-6 lg:right-10 md:top-32 lg:top-36;
    @apply max-w-lg;
    @apply px-4 xs:px-0 animate-slideinright;
    &.closing {
      @apply pointer-events-none;
      @apply animate-slideoutright;
    }
    .profile-alert {
      @apply bg-white;
      @apply relative border-2 border-black;
      @apply shadow-2xl;
      @apply px-4 xs:px-5 md:px-6 pt-3 pb-3 xs:py-4 md:py-6;
      .subheading {
        @apply block text-sm md:text-base md:mb-2;
      }
      .button {
        @apply mobile-sm:text-xl mobile-sm:py-1 mt-2 whitespace-nowrap;
        &.later {
          @apply bg-white border-none text-gray-400 pl-0;
        }
      }
    }
  }
</style>
