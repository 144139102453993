import { log } from '../init/log'
import { GAEvent, GAEvents, GAItem, isAnalyticsEvent } from './google'

/* ========================================================================== *
 * LOADING TIKTOK TAG                                                         *
 * ========================================================================== */

const loadedTikTokTags = new Set<string>()

export function loadTikTokTag(pixelId: string): void {
  if (loadedTikTokTags.has(pixelId)) {
    return log(`TikTok Tag ${pixelId} already loaded`)
  }

  log('Loading TikTok Tag', pixelId)
  const script = document.createElement('script')
  script.text = `
  !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];
    ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
    ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)
    ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},
    ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
    ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
    var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];
    a.parentNode.insertBefore(o,a)};
    ttq.load('${pixelId}')
    ttq.page()
  }(window, document, 'ttq');
  `

  // Append our script to the end of the head
  document.head.appendChild(script)
}

/* ========================================================================== *
 * TIKTOK TYPES                                                               *
 * ========================================================================== */

type TikTokContentType = 'product' | 'product_group'

type TikTokCurrency = 'AED' | 'ARS' | 'AUD' | 'BDT' | 'BHD' | 'BIF' | 'BOB'
    | 'BRL' | 'CAD' | 'CHF' | 'CLP' | 'CNY' | 'COP' | 'CRC' | 'CZK' | 'DKK'
    | 'DZD' | 'EGP' | 'EUR' | 'GBP' | 'GTQ' | 'HKD' | 'HNL' | 'HUF' | 'IDR'
    | 'ILS' | 'INR' | 'ISK' | 'JPY' | 'KES' | 'KHR' | 'KRW' | 'KWD' | 'KZT'
    | 'MAD' | 'MOP' | 'MXN' | 'MYR' | 'NGN' | 'NIO' | 'NOK' | 'NZD' | 'OMR'
    | 'PEN' | 'PHP' | 'PHP' | 'PKR' | 'PLN' | 'PYG' | 'QAR' | 'RON' | 'RUB'
    | 'SAR' | 'SEK' | 'SGD' | 'THB' | 'TRY' | 'TWD' | 'UAH' | 'USD' | 'VES'
    | 'VND' | 'ZAR'

interface TikTokSingleContentEvent {
  content_type: TikTokContentType,
  content_id: string,
  content_category?: string,
  content_name: string,
  currency?: TikTokCurrency,
  price?: number // the _individual_ price of an item
  quantity: number, // the total quantity of items ordered
  value?: number, // the _total_ amount of the order (price * quantity)
}

interface TikTokMultipleContentsItem {
  content_type: TikTokContentType,
  content_id: string,
  content_category?: string,
  content_name: string,
  price?: number // the _individual_ price of an item
  quantity: number, // the total quantity of items ordered
}

interface TikTokMultipleContentsEvent {
  contents: TikTokMultipleContentsItem[],
  currency?: TikTokCurrency,
  value?: number, // the _total_ amount of the order (price * quantity)
}

type TikTokContentEvent = TikTokSingleContentEvent | TikTokMultipleContentsEvent

interface TikTokSearchEvent {
  search: string,
}

declare global {
  interface Window {
    /**
     * The TikTok pixel interface.
     *
     * See:
     * * https://ads.tiktok.com/help/article?aid=10028
     * * https://bytedance.feishu.cn/docs/doccnl7uGy6QrIehnfVnhkh3kEb
     */
    ttq?: {
      /** Record a page view (URL from `window.location`) */
      page(): void
      /** When a visitor adds their payment information at checkout. */
      track(event: 'AddPaymentInfo'): void
      /** When a visitor adds an item to the cart. */
      track(event: 'AddToCart', params: TikTokContentEvent): void

      /** When a visitor adds an item to the wishlist. */
      track(event: 'AddToWishlist', params: TikTokContentEvent): void

      /**
       * When a visitor taps a button.
       *
       * TikTok recommends tracking website buttons important to your business,
       * such as social media buttons.
       */
      track(event: 'ClickButton'): void

      /**
       * When a visitor makes a payment.
       *
       * TikTok recommends using this event when placing an order and making a
       * payment are the same.
       */
      track(event: 'CompletePayment', params: TikTokContentEvent): void

      /** When a visitor signs up for something such as account registration. */
      track(event: 'CompleteRegistration'): void

      /** When a visitor contacts you. */
      track(event: 'Contact'): void

      /** When a visitor downloads something from your website. */
      track(event: 'Download'): void

      /** When a visitor proceeds to checkout. */
      track(event: 'InitiateCheckout'): void

      /**
       * When a visitor places an order.
       *
       * TikTok recommends using this event when placing an order and making a
       * payment are not the same.
       */
      track(event: 'PlaceAnOrder', params: TikTokContentEvent): void

      /** When a visitor searches. */
      track(event: 'Search', params: TikTokSearchEvent): void

      /** When a visitor submits a form. */
      track(event: 'SubmitForm'): void

      /**
       * When a visitor subscribes on your website including follows, content,
       * or paid subscriptions.
       */
      track(event: 'Subscribe'): void

      /**
       * When a visitor views a specific page.
       *
       * TikTok recommends tracking pages important to your business such as
       * product comparison, announcement, or release pages.
       */
      track(event: 'ViewContent', params: TikTokContentEvent): void
    }
  }
}

/* ========================================================================== *
 * TIKTOK IMPLEMENTATION                                                      *
 * ========================================================================== */

function tiktokParams(params: { value?: number, items: GAItem[] }): TikTokMultipleContentsEvent {
  return {
    currency: 'EUR',
    value: params.value || 0,
    contents: params.items.map((item) => ({
      content_type: 'product',
      content_id: item.item_id,
      content_category: item.category,
      content_name: item.item_name,
      quantity: item.quantity,
      price: item.price,
    })),
  }
}

export function tiktokEvent<K extends GAEvent>(event: K, params: GAEvents[K]): void {
  // For `page_view` events TikTok has a different call, called `page()`. This
  // records the page view from the URL in the browser's location bar, so this
  // needs to be called in the router's own `afterEach` callback!
  if (isAnalyticsEvent('page_view', event, params)) return globalThis.window?.ttq?.page()

  // All other event types (simply mapping from GA to TikTok events)
  //
  // * add_payment_info => AddPaymentInfo
  // * add_to_cart      => AddToCart
  // * begin_checkout   => InitiateCheckout
  // * checkout         => PlaceAnOrder
  // * purchase         => CompletePayment
  // * sign_up          => CompleteRegistration
  // * view_item        => ViewContent

  if (isAnalyticsEvent('add_payment_info', event, params)) {
    return globalThis.window?.ttq?.track('AddPaymentInfo')
  }

  if (isAnalyticsEvent('add_to_cart', event, params)) {
    return globalThis.window?.ttq?.track('AddToCart', tiktokParams(params))
  }

  if (isAnalyticsEvent('begin_checkout', event, params)) {
    return globalThis.window?.ttq?.track('InitiateCheckout')
  }

  if (isAnalyticsEvent('checkout', event, params)) {
    return globalThis.window?.ttq?.track('PlaceAnOrder', tiktokParams(params))
  }

  if (isAnalyticsEvent('purchase', event, params)) {
    return globalThis.window?.ttq?.track('CompletePayment', tiktokParams(params))
  }

  if (isAnalyticsEvent('sign_up', event, params)) {
    return globalThis.window?.ttq?.track('CompleteRegistration')
  }

  if (isAnalyticsEvent('view_item', event, params)) {
    return globalThis.window?.ttq?.track('ViewContent', tiktokParams(params))
  }
}
