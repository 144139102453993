<!--==================================================================+
| COMPONENT: NAVIGATION                                               |
+===================================================================-->

<template>
  <nav class="profile-nav">
    <div class="container self-center">
      <!--==================================================================+
      | TAB BUTTONS                                                         |
      +===================================================================-->
      <juit-carousel :scroll-multiples="0" class="small-arrow gradient-arrow">
        <div
          v-for="(section, index) in sections"
          :key="`item_${index}`"
          class="tile"
        >
          <router-link class="flex-1 self-center" :to="section">
            <juit-badge
              class="small black"
              :class="{ revert: $route.params.section == section }"
            >
              {{ $t(`profile.${section}`) }}
            </juit-badge>
          </router-link>
        </div>
      </juit-carousel>
    </div>
  </nav>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import JuitBadge from '../../widgets/juit-badge.vue'
  import JuitCarousel from '../../widgets/juit-carousel.vue'

  export default defineComponent({
    components: {
      JuitBadge,
      JuitCarousel,
    },
    data: () => ({
      sections: [ 'dashboard', 'orders', 'ratings', 'referral', 'personal', 'help' ],
    }),
  })
</script>

<style scoped lang="pcss">
  /* ======================================================================== *
   * Header-nav: root nav element                                             *
   * ======================================================================== */

  .profile-nav {
    @apply bg-white shadow-md relative flex;
    @apply w-full z-0;
    @apply transition-all duration-500 h-11 md:h-14;
    @apply border-t border-gray-300;
    :deep() .tiled {
      @apply justify-between w-full;
      .tile {
        @apply flex-1 w-full;
        &:first-child {
          @apply pl-0;
        }
        &:last-child {
          @apply pr-0;
        }
      }
      .badge {
        @apply inline-block whitespace-nowrap w-full text-center cursor-pointer;
        @apply text-base font-bold py-0 md:py-1;
      }
    }
    :deep() .arrow-wrapper {
      &:not(.right) {
        @apply -left-1 sm:-left-2;
      }
      &.right {
        @apply -right-1 sm:-right-2;
      }
    }
  }
</style>
