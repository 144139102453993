<!--==================================================================+
| MODAL COMPONENT: LOGIN                                              |
+===================================================================-->

<template>
  <div class="validation">
    <h1 class="mb-0.5">
      JU IT
    </h1>
    <div class="subheading font-semibold">
      {{ $t( 'authentication.check-email-alert' ) }}
    </div>
    <p class="mt-2">
      {{ $t( 'authentication.email-code-alert', { email: email }) }}
    </p>
    <div class="inputs">
      <span
        v-for="(input, index) in input_counts"
        :key="index"
        class="input"
        :class="{ split: index === input_counts / 2 - 1 }"
      >
        <input
          :id="`code-input-${index}`"
          type="text"
          maxlength="1"
          inputmode="numeric"
          pattern="[0-9]"
          @input="onInput(index, $event as InputEvent)"
          @paste="onPaste(index, $event as ClipboardEvent)"
          @focus="($event.target as HTMLInputElement).select()"
        >
        <span class="input-border" />
      </span>
    </div>
    <p v-if="server_error" class="invalid-msg">
      {{ $t( 'authentication.server-error-try-again' ) }}
    </p>
    <div v-if="error">
      <p v-for="detail in error.details" :key="detail.reason" class="invalid-msg">
        {{ $t( `authentication.magic-link-error-${detail.reason}` ) }}
      </p>
      <div class="errors">
        <span class="juit-link" @click="$emit('screen', 'login')">{{ $t( 'authentication.another-email' ) }}</span>
        <span>&nbsp;{{ $t( 'authentication.or' ) }}&nbsp;</span>
        <span class="juit-link" @click="resend">{{ $t( 'authentication.re-request' ) }}</span>
      </div>
    </div>
    <p class="subheading font-semibold mt-3">
      {{ $t( 'authentication.magic-link-button-info' ) }}
    </p>
  </div>
</template>

<script lang="ts">
  import { Error } from '@juitnow/lib-schema/types'
  import { defineComponent } from 'vue'
  import { analyticsEvent } from '../../analytics'
  import { client } from '../../init/client'
  import { magicLinkToken } from '../../init/state'

  export default defineComponent({
    props: {
      routerback: {
        type: Boolean,
        reqired: false,
        default: true,
      },
      email: {
        type: String,
        required: true,
      },
    },

    emits: {
      loading: (confirm: boolean) => true,
      screen: (active_screen: string) => true,
      close: () => true,
    },

    data: () => ({
      input_counts: 6,
      input_values: [ '', '', '', '', '', '' ],
      regex: /[0-9]|\./,
      server_error: false,
      error: undefined as undefined | Error,
    }),

    computed: {
      /* ========================================================================== *
       * Create clones of the input values so we can watch and manipulate them      *
       * -------------------------------------------------------------------------- */
      input_clones() {
        return JSON.parse(JSON.stringify(this.input_values))
      },

      /* ========================================================================== *
       * Collect input values to form the validation number                         *
       * -------------------------------------------------------------------------- */
      validation_number() {
        return this.input_values.map((v, index) => (document.querySelector(`#code-input-${index}`) as HTMLInputElement)?.value).join('')
      },

    },

    watch: {
      /* ========================================================================== *
       * Replace non-numeric input value with old ones and refocus the input field  *
       * -------------------------------------------------------------------------- */
      input_clones: {
        handler(newValue, oldValue) {
          this.server_error = false
          this.error = undefined
          newValue.forEach((value: string) => {
            if (value && !this.regex.test(value)) {
              const index = newValue.indexOf(value)
              const input = document.querySelector(`#code-input-${index}`) as HTMLInputElement
              input.blur()
              this.$nextTick(() => {
                this.input_values[index] = oldValue[index]
                input.value = oldValue[index]
                input.select()
              })
            }
          })
        },
        deep: true,
      },

      /* ========================================================================== *
       * Once we have a complete 6-digits number, log in                            *
       * -------------------------------------------------------------------------- */
      validation_number(number) {
        if (number.length !== 6) return
        this.$emit('loading', true)
        client.login({ token: magicLinkToken.value, code: number }).then((user) => {
          if (user) {
            analyticsEvent('login', {
              method: 'magic_link_code',
              user_id: user.uuid,
            })
            if (this.routerback) window.history.go(-1)
            else this.$emit('close')
          } else this.$emit('loading', false)
        }).catch((error: Error) => {
          if (error.status === 401) this.error = error
          else this.server_error = true
          this.$emit('loading', false)
        })
      },
    },

    /* ========================================================================== *
     * Auto-focus the first input                                                 *
     * -------------------------------------------------------------------------- */
    mounted() {
      (document.querySelector('#code-input-0') as HTMLInputElement)?.focus()
    },

    methods: {
      /* ========================================================================== *
       * Ignore defaul insert from paste and accept number input only               *
       * -------------------------------------------------------------------------- */
      onInput(index: number, event: InputEvent) {
        this.input_values[index] = event.data || ''
        if (event.inputType === 'insertFromPaste') (event.target as HTMLInputElement).value = ''
        else if (event.data && this.regex.test(event.data)) this.autoFocus(index)
      },

      /* ========================================================================== *
       * Prune all non-numeric from clipboard and paste one digit per input         *
       * -------------------------------------------------------------------------- */
      onPaste(index: number, event: ClipboardEvent) {
        const clipboard_data = event.clipboardData?.getData('text').replace(/\D/g, '').split('').slice(0, (this.input_counts - index))
        clipboard_data?.forEach((value, i) => {
          const input = document.querySelector(`#code-input-${i + index}`) as HTMLInputElement | undefined
          if (!input) return
          input.value = value
          this.input_values[i + index] = value
          this.autoFocus(i + index)
        })
      },

      /* ========================================================================== *
       * Auto focus next input if exist                                             *
       * -------------------------------------------------------------------------- */
      autoFocus(index: number) {
        const next_input = document.querySelector(`#code-input-${index + 1}`) as HTMLInputElement | undefined
        if (next_input) next_input.select()
        else (document.querySelector(`#code-input-${index}`) as HTMLInputElement).blur()
      },

      /* ========================================================================== *
       * Reset the input values and rend the magic link                             *
       * -------------------------------------------------------------------------- */
      async resend() {
        this.$emit('loading', true)
        this.error = undefined
        this.server_error = false
        this.input_values.forEach((input, index) => (document.querySelector(`#code-input-${index}`) as HTMLInputElement).value = '')
        await client.sendMagicLink({ email: this.email, redirect_url: window.location.href }).then((res) => {
          if (res) magicLinkToken.value = res.token
          this.$emit('loading', false)
        }).catch(() => {
          this.server_error = true
          this.$emit('loading', false)
        })
      },

    },
  })
</script>

<style lang="pcss" scoped>
.validation {
  @apply text-center;
  .invalid-msg {
    @apply text-center mt-2 -mb-1;
  }
  .errors {
    span {
      @apply inline-block mt-2 text-sm;
    }
  }
  .inputs {
    @apply flex justify-center mt-4;
    .input {
      @apply relative;
      margin-right: -1px;
      .input-border {
        @apply pointer-events-none w-full h-full absolute top-0 left-0 border border-black;
      }
      input {
        @apply w-12 h-12 md:w-16 md:h-16;
        @apply text-3xl md:text-5xl text-center relative z-0;
        &:focus + .input-border {
          @apply border-blue z-10;
        }
      }
      &.split {
        @apply mr-4;
        &:after {
          content: '';
          height: 1px;
          @apply absolute w-2 -right-3 bg-black top-1/2;
          @apply transform -translate-y-1/2;
        }
      }
    }

    input::selection {
      @apply bg-gray-100;
    }
    input::-moz-selection {
      @apply bg-gray-100;
    }
  }
  .subline {
    @apply mt-3;
  }
}

</style>
