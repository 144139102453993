import { createClient } from 'contentful'

import { env } from '../init/env'

import type { ContentfulCollection } from 'contentful'
import type * as Types from './types'

/**
 * A string that (if set) indicates the _current_ Contentful environment used
 * to preview content from. This comes from the query string parameter
 * `contentful_preview_environment`.
 */
export const previewEnvironment = new URLSearchParams(window.location.search).get('contentful_preview_environment')

export const contentfulClient = createClient({
  host: previewEnvironment ? env.VITE_CONTENTFUL_PREVIEW_HOST : env.VITE_CONTENTFUL_HOST,
  accessToken: previewEnvironment ? env.VITE_CONTENTFUL_PREVIEW_TOKEN : env.VITE_CONTENTFUL_TOKEN,
  environment: previewEnvironment ? previewEnvironment : env.VITE_CONTENTFUL_ENVIRONMENT,
  space: env.VITE_CONTENTFUL_SPACE,
})

/* ========================================================================== */
export function getTypedEntries(type: 'box', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeBox<undefined>>>
export function getTypedEntries(type: 'boxList', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeBoxList<undefined>>>
export function getTypedEntries(type: 'dataContent', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeDataContent<undefined>>>
export function getTypedEntries(type: 'dish', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeDish<undefined>>>
export function getTypedEntries(type: 'dishCarousel', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeDishCarousel<undefined>>>
export function getTypedEntries(type: 'dishList', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeDishList<undefined>>>
export function getTypedEntries(type: 'dishShowcase', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeDishShowcase<undefined>>>
export function getTypedEntries(type: 'iconText', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeIconText<undefined>>>
export function getTypedEntries(type: 'iconTextList', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeIconTextList<undefined>>>
export function getTypedEntries(type: 'list', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeList<undefined>>>
export function getTypedEntries(type: 'page', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypePage<undefined>>>
export function getTypedEntries(type: 'panel', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypePanel<undefined>>>
export function getTypedEntries(type: 'prefilledCart', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypePrefilledCart<undefined>>>
export function getTypedEntries(type: 'qa', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeQa<undefined>>>
export function getTypedEntries(type: 'qaList', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeQaList<undefined>>>
export function getTypedEntries(type: 'testimonial', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeTestimonial<undefined>>>
export function getTypedEntries(type: 'testimonialList', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeTestimonialList<undefined>>>
export function getTypedEntries(type: 'translations', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeTranslations<undefined>>>
export function getTypedEntries(type: 'translationsRichText', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeTranslationsRichText<undefined>>>
export function getTypedEntries(type: 'userRatings', query?: Record<string, any>): Promise<ContentfulCollection<Types.TypeUserRatings<undefined>>>

// Overloaded "getEntries" method
export function getTypedEntries(type: string, query?: Record<string, any>): Promise<ContentfulCollection<any>> {
  return contentfulClient.getEntries(Object.assign({}, query, { content_type: type }))
}
