<!--==================================================================+
| WIDGET: FAVORITE DISH BUTTON                                        |
+===================================================================-->

<template>
  <div
    v-if="user"
    class="fav-button"
    :class="{ 'not-favd': !product.tags.includes(favTag), animating_in }"
    @click.stop.prevent="updateFav()"
  >
    <div class="circle" :class="{ animating_in }" />
    <div class="relative">
      <heart class="heart w-full" :class="{ animating_in, animating_out }" @animationend="() => animationEnd()" />
      <heart class="heart shadow w-full" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { favDishes } from '../init/state'
  import { heart } from '../assets/async'
  import { Dish } from '../content'
  import { favTag } from './juit-tag-icon'
  import { analyticsEvent } from '../analytics'
  import { client } from '../init/client'
  import { logError } from '../init/log'

  export default defineComponent({
    components: {
      heart,
    },
    props: {
      product: {
        type: Object as PropType<Dish>,
        required: true,
      },
    },
    data: () => ({
      favTag,
      favBusy: false,
      animating_in: false,
      animating_out: false,
      add: null as null | boolean,
    }),

    methods: {
      async updateFav() {
        if (this.favBusy) return
        this.favBusy = true
        this.add = !favDishes.value.includes(this.product.ean)
        if (this.add) this.animating_in = true
        else this.animating_out = true
        const add = this.add ? 'add_favourite' : 'remove_favourite'
        analyticsEvent(add, { item: { item_id: this.product.ean, item_name: this.product.title, quantity: 1 } })
      },

      async animationEnd() {
        this.animating_in = false
        this.animating_out = false
        if (this.add) {
          favDishes.value.push(this.product.ean)
          if (navigator.vibrate) navigator.vibrate(70)
        } else favDishes.value = favDishes.value.filter((ean) => ean !== this.product.ean)
        const res = await client.updateUserPreferences({ favourite_dishes: favDishes.value }).catch((e) => logError(e))
        if (res && res.favourite_dishes) favDishes.value = res.favourite_dishes
        this.$nextTick(() => this.favBusy = false)
      },
    },
  })
</script>

<style lang="pcss">
  .fav-button {
    @apply w-8 text-red absolute;
    @apply top-4 right-4 z-[39] cursor-pointer;
    &.not-favd:not(.favd):not(.animating_in) {
      @apply text-gray-300;
    }
    .circle {
      @apply rounded-full h-full bg-red w-full absolute;
      @apply left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-1 scale-0 opacity-100;
      &:after {
        content: '';
        @apply rounded-full h-full bg-white w-full absolute transform scale-0 opacity-100;
      }
      &.animating_in {
        animation: circle-outer 0.7s ease-in-out;
        &:after {
          animation: circle-inner 0.7s ease-out;
        }
      }
    }
    .heart.animating_in {
      animation: heartbeat 0.7s linear;
    }
    .heart.animating_out {
      animation: heartfade 0.3s ease-in-out;
    }
    .heart.shadow {
      @apply opacity-100 fill-[#d1d5db] shadow-none absolute top-0 -z-1;
    }
  }

  @keyframes heartbeat {
    0% { transform: scale(1) }
    5% { transform: scale(0.7) }
    10% { transform: scale(0.9) }
    30% { transform: scale(1.3) }
    50% { transform: scale(0.95) }
    70% { transform: scale(1.05) }
    80% { transform: scale(1) }
    100% { transform: scale(1) }
  }
  @keyframes heartfade {
    0% { opacity: 100; }
    100% { opacity: 0; }
  }
  @keyframes circle-outer {
    0% { transform: translate(-50%, -50%) scale(0); opacity: 100; }
    40% { transform: translate(-50%, -50%) scale(0.8); opacity: 100; }
    50% { transform: translate(-50%, -50%) scale(0.75); opacity: 0; }
    100% { transform: translate(-50%, -50%) scale(0.75); opacity: 0; }
  }
  @keyframes circle-inner {
    0% { transform: scale(0); opacity: 100; }
    5% { transform: scale(0); opacity: 100; }
    45% { transform: scale(1); opacity: 100; }
    55% { transform: scale(1.2); opacity: 0; }
    100% { transform: scale(1.2); opacity: 0; }
  }
</style>
