<template>
  <ul>
    <li>{{ nutrition(product.calories, '', product.weight / (product.portions || 1), 1) }}</li>
    <li>{{ nutrition(product.protein, 'g', product.weight / (product.portions || 1), 1) }}</li>
    <li>{{ nutrition(product.carb, 'g', product.weight / (product.portions || 1), 1) }}</li>
    <li>{{ nutrition(product.fat, 'g', product.weight / (product.portions || 1), 1) }}</li>

    <li>{{ $t('dish.calories') }}</li>
    <li>{{ $t('dish.protein') }}</li>
    <li>{{ $t('dish.carbohydrates') }}</li>
    <li>{{ $t('dish.fat') }}</li>
  </ul>
</template>


<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Dish } from '../content'
  import { nutrition } from './juit-nutrition-label.vue'

  export default defineComponent({
    props: {
      product: {
        type: Object as PropType<Dish>,
        required: true,
      },
    },
    methods: { nutrition },
  })
</script>

<style scoped lang="pcss">
  ul {
    @apply flex flex-grow-0 flex-wrap;
    @apply py-4 text-center;
    @apply justify-around;
  }

  li {
    @apply w-1/4 self-center;
    @apply text-lg md:text-xl font-bold;

    &:nth-child(n+5) {
      @apply text-sm md:text-base font-normal;
    }
  }
</style>
