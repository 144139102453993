<template>
  <!--==================================================================+
  | PHASE 1: SUBSCRIBE WINDOW                                           |
  +===================================================================-->
  <div v-if="!userIsSubscribed">
    <juit-submitting :visible="processing" class="md:ml-6" />
    <form
      id="subscribe-user"
      class="subscribe-form"
      @submit.stop.prevent="subscribeUserToNewsletter"
    >
      <!-- Language -->
      <input
        id="subs-LANGUAGE"
        type="hidden"
        name="LANGUAGE"
        :value="$i18n.locale"
      >
      <div class="subscribe-input">
        <!-- Email -->
        <input-email
          id="subs-EMAIL"
          v-model:email="email"
          v-model:valid="email_valid"
          class="input-field"
          name="EMAIL"
        />
        <!-- Button -->
        <juit-button
          class="button"
          type="submit"
          :disabled="!form_valid"
        >
          <span class="cta-btn-text">{{ $t('newsletter.signup-button-text') }}</span>
        </juit-button>
      </div>
      <!-- Errors -->
      <p v-if="server_error" class="invalid-msg mt-1 md:mt-2 -mb-4">
        {{ $t( 'authentication.server-error-try-again' ) }}
      </p>
    </form>
  </div>

  <!--==================================================================+
  | PHASE 2: THANK-YOU WINDOW                                           |
  +===================================================================-->
  <div v-else>
    <p class="thank-you-text title">
      {{ $t('newsletter.success-message-title') }}
    </p>
    <p class="thank-you-text">
      {{ $t('newsletter.success-message-subtitle') }}
    </p>
    <juit-button
      class="button mt-4 md:mt-6 sm:mb-8 md:mb-0"
      type="submit"
      form="subscribe-data"
      @click="linkToDishes"
    >
      <span class="cta-btn-text">{{ $t('newsletter.explore-button-text') }}</span>
    </juit-button>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { client } from '../../init/client'
  import { logError } from '../../init/log'

  export default defineComponent({
    emits: [ 'closeBanner', 'loading', 'userSubscribed' ],
    data: () => ({
      email: '',
      email_valid: false,
      userIsSubscribed: false,
      server_error: false,
      processing: false,
    }),
    computed: {
      form_valid() {
        return this.email_valid
      },
    },
    methods: {
      /* ==================================================================== *
       * Subscribe User                                                        *
       * -------------------------------------------------------------------- */
      async subscribeUserToNewsletter() {
        this.$emit('loading', true)
        this.processing = true

        try {
          await client.subscribeUser({ email: this.email, locale: this.$i18n.locale as 'en' | 'de' })
          // we emit an event to stop displaying title+description in data-content
          this.$emit('userSubscribed')
          // we update Local Storage > in case the user arrived to the subscription page through a direct link
          localStorage?.setItem('jn:subscriptionBannerDismissed', 'true')
          // we display the Thank You page
          this.userIsSubscribed=true
          this.$emit('loading', false)
        } catch (err) {
          this.server_error = true
          this.$emit('loading', false)
          logError(err)
        } finally {
          this.processing = false
        }
      },

      linkToDishes() {
        const url = `/${this.$i18n.locale}/dishes`
        this.$router.push(url)
      },
    },
  },
  )
</script>

<style scoped lang="pcss">
  form {
    @apply mb-10 md:mb-0;
  }
  .subscribe-input {
    @apply flex flex-row justify-start;
    @apply h-10 md:h-12 xl:h-14;
    @apply mt-5 md:mt-6;
  }
  :deep() .input-field {
    @apply h-10 md:h-12 xl:h-14;
    @apply w-52 lg:w-60 xl:w-72;
    @apply placeholder:text-sm md:placeholder:text-base text-sm md:text-base;
  }
  :deep() .button {
    /* @apply text-xs; */
  }
  .thank-you-text {
    @apply text-base sm:text-xl md:text-2xl mt-4 md:mt-6;
  }
  .title {
    @apply font-bold mb-4 md:mb-6;
  }
</style>
