<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="addresses" :class="{ form_disabled: !email_valid && !user }">
    <!-- EMAIL -->
    <div class="px-3 w-full">
      <div class="input-group flex flex-wrap justify-between">
        <span class="font-semibold">{{ $t( 'checkout.please-provide-email' ) }}</span>
        <div>
          {{ $t( 'checkout.already-user' ) }}
          <span class="juit-link" @click="$createModal(loginRegisterModal as any)">{{ $t( 'navbar.login' ) }}</span>
        </div>
        <div class="w-full my-2 border-b pb-3 border-gray-400">
          <input-email v-model:email="email" v-model:valid="email_valid" />
        </div>
      </div>
    </div>

    <!--==================================================================+
    | TERMS AND CONDITIONS FOR NEW USER                                   |
    +===================================================================-->
    <div class="address text-left">
      <div class="mt-2 text-xs md:text-sm flex mobile-sm:flex-wrap">
        <p class="mb-0.5" v-html="$t('authentication.accept_01')" />&nbsp;
        <p class="mb-0.5" v-html="$t('authentication.accept_02')" />
      </div>
      <div class="text-xs md:text-sm">
        {{ $t( 'authentication.newsletter-alert' ) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { reactiveCheckout } from '../../init/reactive-order'
  import loginRegisterModal from '../../modals/login-register-modal.vue'
  import { checkoutBusy } from '../../init/state'
  import { client } from '../../init/client'
  import { locale } from '../../init/i18n'
  import { analyticsEvent } from '../../analytics'

  export default defineComponent({
    emits: [ 'step', 'ready' ],

    data: () => ({
      email: '',
      email_valid: false,
      given_name: '',
      family_name: '',
      loginRegisterModal,
    }),

    watch: {
      /* ========================================================================== *
       * If email is valid, store it                                                *
       * -------------------------------------------------------------------------- */
      email() {
        this.$nextTick(() => {
          if (this.email_valid) reactiveCheckout.addresses.email = this.email
        })
      },
    },

    mounted() {
      if (!this.user && reactiveCheckout.addresses.email) this.email = reactiveCheckout.addresses.email
    },

    methods: {
      /* ========================================================================== *
       * Create new user or pop up the login modal                                  *
       * -------------------------------------------------------------------------- */
      async getUser() {
        checkoutBusy.value.push('')
        const new_user = await client.registerUser({
          given_name: '',
          family_name: '',
          email: this.email,
          locale: locale.value,
        }).finally(() => checkoutBusy.value.pop())
        if (new_user) {
          // Push register event to datalayer
          analyticsEvent('sign_up', {
            method: 'email',
            user_id: new_user.uuid,
          })
          return new_user
        } else return await this.$createModal(loginRegisterModal as any)
      },
    },

  })
</script>


<style scoped lang="pcss">
  .addresses {
    @apply -mx-3 flex flex-wrap;
    .address {
      @apply text-sm sm:text-base;
    }
    :deep() .address {
      @apply w-full px-3;
    }
  }
</style>
