<!--==================================================================+
| COMPONENT: CTA (APP DOWNLOAD)                                       |
+===================================================================-->

<template>
  <div class="download">
    <a
      href="https://apps.apple.com/app/juit-now/id1472190361"
      target="_blank"
      rel="noopener"
      aria-label="download on apple store"
    >
      <badge-app-store :class="`locale-${$i18n.locale}`" />
    </a>

    <a
      href="https://play.google.com/store/apps/details?id=com.juit.production.juitnowapp"
      target="_blank"
      rel="noopener"
      aria-label="download on google app store"
    >
      <badge-google-play :class="`locale-${$i18n.locale}`" />
    </a>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'JuitCtaApp',
    components: {
    },
  })
</script>

