<!--==================================================================+
| WIDGET: LOGIN / REGISTER                                            |
+===================================================================-->

<template>
  <login-screen
    v-if="screen === 'login'"
    :routerback="routerback"
    @loading="$emit('processing', $event)"
    @close="$emit('close')"
    @screen="screen = $event"
    @email="email = $event"
  />
  <passwordless-validation-screen
    v-if="screen === 'passwordless_validation'"
    :routerback="routerback"
    :email="email"
    @loading="$emit('processing', $event)"
    @screen="screen = $event"
    @close="$emit('close')"
  />
  <register-screen
    v-if="screen === 'register'"
    :routerback="routerback"
    @loading="$emit('processing', $event)"
    @screen="screen = 'login'"
  />
  <reset-password-screen
    v-if="screen === 'reset_pw'"
    @loading="$emit('processing', $event)"
    @screen="screen = 'login'"
  />
</template>

<script lang="ts">
  import LoginScreen from '../widgets/login-register/login-screen.vue'
  import RegisterScreen from '../widgets/login-register/register-screen.vue'
  import ResetPasswordScreen from '../widgets/login-register/reset-password-screen.vue'
  import PasswordlessValidationScreen from '../widgets/login-register/passwordless-validation-screen.vue'
  import { defineComponent } from 'vue'
  import JuitModal from './modal.vue'
  import { analyticsEvent } from '../analytics'

  export default defineComponent({
    components: {
      LoginScreen,
      RegisterScreen,
      ResetPasswordScreen,
      PasswordlessValidationScreen,
    },
    props: {
      routerback: {
        type: Boolean,
        reqired: false,
        default: false,
      },
    },
    emits: {
      processing: (lock: boolean) => true,
      close: () => true,
      guest: null,
    },
    data: () => ({
      screen: 'login',
      email: '',
    }),
    computed: {
      modal() {
        return this.$refs['modal'] as InstanceType<typeof JuitModal>
      },
    },

    mounted() {
      if (!this.user) {
        analyticsEvent('login_or_sign_up', {})
      }
    },
  })
</script>
