<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="[ profilePage ? '' : 'addresses', { b2b }]">
    <!-- SHIPPING ADDRESS FORM -->
    <juit-address-form
      v-if="!profilePage || ( profilePage && addressType === 'shipping' )"
      :errors="errors_shipping"
      :b2b="b2b"
      :profile-page="profilePage"
      @complete="add_shipping_complete = $event"
      @create="updateAdd"
      @create-with-google-id="updateAdd"
      @escape="$emit('address-form-closed')"
    />

    <!-- CHECKBOX FOR USING SAME ADDRESS FOR BILLING -->
    <label v-if="!profilePage" class="checkbox-label"><input v-model="add_same" type="checkbox">
      <div class="checkbox-checked" />
      <p>{{ $t( 'order.billing_address' ) }}* <span>{{ $t( 'order.same_as_shipping' ) }}</span></p>
    </label>

    <!-- BILLING ADDRESS FORM -->
    <juit-address-form
      v-if="!profilePage || ( profilePage && addressType === 'billing' )"
      :errors="errors_billing"
      :shipping="false"
      :profile-page="profilePage"
      class="billing"
      :class="{ active: !add_same }"
      :b2b="b2b"
      @validvat="valid_vat = $event"
      @complete="add_billing_complete = $event"
      @create="updateAdd"
      @create-with-google-id="updateAdd"
      @escape="$emit('address-form-closed')"
    />
  </div>

  <!-- BUTTONS ONLY FOR THE PROFILE PAGE -->
  <juit-button
    v-if="profilePage"
    class="small mt-4"
    :disabled="!create_addresses_complete"
    @click="createAddressInProfilePage"
  >
    {{ $t( 'authentication.manage-addresses-save-button' ) }}
  </juit-button>
  <juit-button
    v-if="profilePage"
    class="small mt-4 ml-2 outline"
    @click="$emit('address-saved')"
  >
    {{ $t( 'authentication.manage-addresses-cancel-button' ) }}
  </juit-button>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { client } from '../../init/client'
  import { Error } from '@juitnow/lib-schema/types'
  import type { Address, CreateAddress } from '@juitnow/api-addresses-v2'
  import juitAddressForm from './address-form-single.vue'
  import { reactiveCheckout } from '../../init/reactive-order'
  import { log, logError } from '../../init/log'
  import { checkoutQueue } from '../../init/checkout-queue'
  import { checkoutBusy } from '../../init/state'

  export default defineComponent({
    components: {
      juitAddressForm,
    },

    props: {
      b2b: {
        type: Boolean,
        default: false,
      },
      profilePage: {
        type: Boolean,
        default: false,
        required: false,
      },
      addressType: {
        type: String,
        default: 'shipping',
        required: false,
      },
    },
    emits: [ 'step', 'ready', 'address-saved', 'address-form-closed' ],

    data: () => ({
      add_same: true as undefined | boolean,
      add_shipping_complete: false,
      add_billing_complete: false,
      valid_vat: false,
      create_shipping: undefined as CreateAddress | undefined,
      create_billing: undefined as CreateAddress | undefined,
      errors_billing: [] as string[],
      errors_shipping: [] as string[],
    }),

    computed: {
      addresses_loaded() {
        return checkoutQueue.loading_address
      },

      create_addresses() {
        return [ this.create_billing, this.create_shipping ]
      },

      create_addresses_complete() {
        if (this.profilePage) return this.addressType === 'shipping' ? this.add_shipping_complete : this.add_billing_complete
        if (!this.add_same) return (this.add_shipping_complete && this.add_billing_complete)
        else return this.add_shipping_complete && (this.b2b ? this.valid_vat : true)
      },
    },

    watch: {
      /* ========================================================================== *
       * Same address for billing and shipping?                                     *
       * -------------------------------------------------------------------------- */
      addresses_loaded: {
        handler(value) {
          // After we load or create addresses, check the state and update our data
          if (value === 'done') this.add_same = 'same' in reactiveCheckout.addresses ? reactiveCheckout.addresses.same : this.add_same
        },
      },
      add_same(same) {
        reactiveCheckout.addresses.same = same
      },

      /* ========================================================================== *
       * Store both createAddress objects                                           *
       * -------------------------------------------------------------------------- */
      create_addresses: {
        handler() {
          // log('Address form updated, saving "createAddress" objects...')
          if (this.create_addresses_complete) {
            reactiveCheckout.addresses.create_billing = this.create_billing
            reactiveCheckout.addresses.create_shipping = this.create_shipping
          }
        },
        deep: true,
        immediate: true,
      },

      /* ========================================================================== *
       * Tell checkout.vue if the CTA button should be available                    *
       * -------------------------------------------------------------------------- */
      create_addresses_complete(value) {
        this.$emit('ready', value)
      },
    },

    methods: {
      /* ========================================================================== *
       * Update the form completion status and store the addresses                  *
       * -------------------------------------------------------------------------- */
      updateAdd(body: CreateAddress, isShipping: boolean, formComplete: boolean) {
        if (formComplete) log(`Updating ${isShipping ? 'shipping' : 'billing'} address from the form`)

        // If the user is adding only one type of address from their profile page
        if (this.profilePage && this.addressType === 'shipping') this.add_billing_complete = true
        if (this.profilePage && this.addressType === 'billing') this.add_shipping_complete = true

        // For billing address
        if (!isShipping) {
          this.errors_billing = []
          this.add_billing_complete = formComplete
          this.create_billing = body

        // For shipping address
        } else {
          this.errors_shipping = []
          this.add_shipping_complete = formComplete
          this.create_shipping = body

          // If billing is same as shipping
          if (this.add_same) {
            this.add_billing_complete = formComplete
            this.create_billing = Object.assign({}, body, { type: 'billing', vat: (this.create_billing as any)?.vat })
          }
        }
      },

      /* ========================================================================== *
       * Validating both addresses and continue to summary                          *
       * -------------------------------------------------------------------------- */
      async createAddress() {
        // Make sure we have the CreateAddress objects
        if (this.add_same || reactiveCheckout.addresses.same) reactiveCheckout.addresses.create_billing = Object.assign({}, reactiveCheckout.addresses.create_shipping, { type: 'billing', vat: (this.create_billing as any)?.vat })
        const create_shipping = reactiveCheckout.addresses.create_shipping
        const create_billing = reactiveCheckout.addresses.create_billing
        if (!create_shipping || !create_billing) return
        reactiveCheckout.addresses.create_shipping = create_shipping
        reactiveCheckout.addresses.create_billing = create_billing

        // Check for user
        log('%cPreparing for addresses creation, checking user...', 'color: green')
        const user = this.user
        if (!user) return

        // Update the user name
        checkoutBusy.value.push('')
        if (!user.family_name || !user.given_name) {
          log('%cUpdating the name of the user:', 'color: green', create_shipping.given_name, create_shipping.family_name)
          await client.updateUser({ 'given_name': create_shipping.given_name, 'family_name': create_shipping.family_name })
            .catch((e) => logError(e))
        }

        // Start
        log('%cUser found. Creating addresses:', 'color: green', create_shipping, create_billing)
        let shipping_res = null as null | void | Address
        let billing_res = null as null | void | Address
        try {
          shipping_res = await client.addresses.create(create_shipping)
          if (shipping_res) {
            billing_res = await client.addresses.create(create_billing).catch((err) => {
              this.errors_billing = this.resolveError(err)
            })
          }
        } catch (err) {
          this.errors_shipping = this.resolveError(err as Error)
        } finally {
          // Reset addresses
          reactiveCheckout.addresses.billing = undefined
          reactiveCheckout.addresses.shipping = undefined

          checkoutBusy.value.pop()
          reactiveCheckout.addresses.shipping = shipping_res || reactiveCheckout.addresses.shipping
          reactiveCheckout.addresses.billing = billing_res || reactiveCheckout.addresses.billing

          // Same address for billing and shipping?
          const [ billing, shipping ] = [ reactiveCheckout.addresses.billing, reactiveCheckout.addresses.shipping ]
          if (billing && shipping) {
            const keys = Object.keys(shipping).filter((key) => ![ 'vat', 'type', 'uuid', 'created', 'modified' ].includes(key)) as Array<keyof Address>
            reactiveCheckout.addresses.same = !(keys.find((key) => billing[key] !== shipping[key]))
          } else if (!(!billing && !shipping)) reactiveCheckout.addresses.same = false

          // To summary or addresses
          if (shipping_res?.uuid && billing_res?.uuid) this.$emit('step', 'summary')
          else this.$emit('step', 'addresses')
        }
      },

      /* ========================================================================== *
       * Create a new Address from the Profile Page                                 *
       * -------------------------------------------------------------------------- */
      async createAddressInProfilePage() {
        if (this.addressType === 'shipping' && this.create_shipping) {
          await client.addresses.create( this.create_shipping )
            .then((res) => {
              if (res) {
                this.$emit('address-saved')
                reactiveCheckout.addresses.shipping = res
              }
            })
            .catch((err) => {
              this.errors_shipping = this.resolveError(err)
            })
        } else if (this.create_billing) {
          await client.addresses.create( this.create_billing )
            .then((res) => {
              if (res) {
                this.$emit('address-saved')
                reactiveCheckout.addresses.billing = res
              }
            })
            .catch((err) => {
              this.errors_billing = this.resolveError(err)
            })
        }
      },

      /* ========================================================================== *
       * Resolve errors from address creation                                       *
       * -------------------------------------------------------------------------- */
      resolveError(err: Error): string[] {
        if (err.status === 400) {
          if (!err.details) return [ this.$t('checkout.add-invalid') ]
          if (!err.details.length) return err.details
          const details_arr = err.details[0].reasons || err.details
          return details_arr.map((reason: string) => {
            switch (reason) {
              case 'street_corrected':
                return this.$t('checkout.add-invalid-street', { street: err.details[0].corrected?.street_name })
              case 'city_corrected':
                return this.$t('checkout.add-invalid-city', { city: err.details[0].corrected?.city })
              case 'postal_code_corrected':
                return this.$t('checkout.add-invalid-postal', { postal: err.details[0].corrected?.postal_code })
            }
          })
        } else return [ this.$t('checkout.add-invalid') ]
      },
    },
  })
</script>


<style scoped lang="pcss">
  .addresses {
    @apply -mx-3 flex flex-wrap;
    .checkbox-label {
      @apply mt-5 px-3 font-semibold;
      p {
        @apply pl-6 -mt-0.5 self-center;
        span {
          @apply opacity-50 line-through font-normal;
        }
      }
      input:checked + .checkbox-checked + p span {
        @apply opacity-100 no-underline;
      }
    }
    .address {
      @apply text-sm sm:text-base;
      &.billing {
        @apply max-h-0;
        @apply opacity-0;
        @apply overflow-hidden transition-all duration-500 ease-in-out;
        &.active {
          @apply max-h-max;
          @apply opacity-100;
          @apply mt-2;
        }
      }
    }
    :deep() .address {
      @apply w-full px-3;
    }

    &.b2b {
      @apply pt-20 relative;
      .billing {
        @apply opacity-100;
      }
      &.invalid_vat, &.vat_alert {
        @apply pt-24;
      }
    }
  }
</style>
