import { APIClient, Environment, createClient } from '@juitnow/lib-fetch-client'
import { App, readonly, ref, WatchStopHandle, DeepReadonly, watch } from '@vue/runtime-core'
import { User } from '@juitnow/lib-schema/types'
import { env } from './env'

// Plugins for "lib-fetch-client"
import '@juitnow/api-addresses-v2' // client.addresses (Adresses API)
import '@juitnow/api-comments' // client.comment (Comments API)
import '@juitnow/api-deliveries' // client.deliveries (Deliveries API)
import '@juitnow/api-forms' // client.forms (Forms API)
import '@juitnow/api-graph-v2' // client.graphV2 (GraphQL API)
import '@juitnow/api-inventories' // client.inventories (Inventories API)
import '@juitnow/api-orders-v2' // client.ordersV2 (Orders API)
import '@juitnow/api-payments' // client.payments (Payments API)
import '@juitnow/api-ratings' // client.ratings (Ratings API)

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $currency: (num: number) => string,
    $centToEur: (num: number) => number,
    $currencyOld: (num: number) => string,
    $number: (num: number) => string,
    $getDay: (date: string | undefined) => string,
    $getDate: (date: string | undefined) => string,
    $getDD: (date: string | undefined) => string,
    $getMMYY: (date: string | undefined) => string,
    $updateAlternateSlug: (slug: string, locale: string) => void,
    $client: APIClient,
    user: DeepReadonly<User> | undefined | false,
  }
}

// Token keys for local storage
export const refreshTokenKey = 'jn:refresh_token'
export const accessTokenKey = 'jn:acess_token'

// Our internal `ref` for the user
export const userRef = ref(undefined as User | undefined)
// The _read only_ ref to export
export const user = readonly(userRef)

// Our internal `ref` for the refresh token
export const refreshTokenRef = ref(undefined as string | undefined)
// The _read only_ ref to export
export const refreshToken = readonly(refreshTokenRef)
// Our internal `ref` for the access token
export const accessTokenRef = ref(undefined as string | undefined)
// The _read only_ ref to export
export const accessToken = readonly(accessTokenRef)

// Our shared client instance
export const client = createClient({ environment: env.MODE as Environment })

// Update our `userRef` and `refreshTokenRef` on changes
client.on('refresh_token', (refreshToken) => {
  if (refreshToken) localStorage.setItem(refreshTokenKey, refreshToken)
  refreshTokenRef.value = refreshToken
})
client.on('access_token', (accessToken) => {
  if (accessToken) localStorage.setItem(accessTokenKey, accessToken)
  accessTokenRef.value = accessToken
})
client.on('user', (user) => userRef.value = user)
client.on('error', (error) => {
  if (error.status === 401) {
    if (error.error === 'invalid_token') {
      accessTokenRef.value = undefined
      localStorage.removeItem(accessTokenKey)
    }
    if (error.error === 'unauthorized') {
      refreshTokenRef.value = undefined
      localStorage.removeItem(refreshTokenKey)
    }
    return
  }
})

// Plugin installation function
export function plugin(app: App): void {
  app.config.globalProperties.$client = client

  // Global Mixins
  app.mixin({
    data: () => ({
      user: false as DeepReadonly<User> | undefined | false,
      __unWatchUser: undefined as WatchStopHandle | undefined,
    }),
    mounted() {
      this.__unWatchUser = watch(user, (user) => this.user = user, { immediate: true })
    },
    unmounted() {
      this.__unWatchUser?.()
    },

    methods: {
      // Update alternate slug in head
      $updateAlternateSlug(slug: string, locale: string) {
        const query = 'link[rel="alternate"]'
        const elements = document.head.querySelectorAll(query)
        elements.forEach((element) => element.parentElement?.removeChild(element))
        const dom = (new DOMParser()).parseFromString(`<link rel="alternate" href="${slug}" hreflang="${locale === 'de' ? 'en' : 'de'}" type="text/html">`, 'text/html').documentElement.querySelector('link')
        if (dom) document.head.appendChild(dom)
      },

      // Formatting the Price
      $currencyOld(num: number) {
        return new Intl.NumberFormat(this.$i18n.locale, {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
        }).format(num)
      },
      $currency(num: number) {
        return new Intl.NumberFormat(this.$i18n.locale, {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
        }).format(this.$centToEur(num))
      },

      // Cent to Euro convertion
      $centToEur(num: number) {
        const stringInCents = num ? num.toString() : '000'
        const stringInEuro = stringInCents.substring(0, stringInCents.length - 2) + '.' + stringInCents.substring(stringInCents.length - 2)
        return Number(stringInEuro)
      },

      // Formatting a Number
      $number(num: number) {
        return new Intl.NumberFormat(this.$i18n.locale).format(num)
      },

      // Formatting the Date
      $getDay(date: string | undefined) {
        return date ? new Intl.DateTimeFormat(this.$i18n.locale, { timeZone: 'Europe/Berlin', weekday: 'long' }).format(new Date(date)) : '0'
      },
      $getDate(date: string | undefined) {
        return date ? new Intl.DateTimeFormat(this.$i18n.locale, { timeZone: 'Europe/Berlin', day: '2-digit', year: 'numeric', month: 'short' }).format(new Date(date)) : '0'
      },
      $getDD(date: string | undefined) {
        return date ? new Intl.DateTimeFormat(this.$i18n.locale, { timeZone: 'Europe/Berlin', day: '2-digit' }).format(new Date(date)) : '0'
      },
      $getMMYY(date: string | undefined) {
        return date ? new Intl.DateTimeFormat(this.$i18n.locale, { timeZone: 'Europe/Berlin', year: 'numeric', month: 'short' }).format(new Date(date)) : '0'
      },

      // For carousel card
      $getCardWidth() {
        const cards = this.$refs.card as HTMLElement[]
        return cards ? cards[0].getBoundingClientRect().width : 0
      },
    },
  })
}
