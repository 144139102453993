<!--==================================================================+
| MODAL COMPONENT: LOGIN                                              |
+===================================================================-->

<template>
  <div class="login">
    <!-- LOGIN FORM -->
    <form id="login-data" class="login-form" @submit.stop.prevent="login">
      <h2 class="mb-2 md:mb-4">
        {{ $t( 'authentication.login' ) }}
      </h2>
      <input-email v-model:email="email" v-model:valid="email_valid" />
      <input-password v-if="!passwordless" v-model:password="password" v-model:valid="password_valid" />
      <p v-if="server_error" class="invalid-msg">
        {{ $t( 'authentication.server-error-try-again' ) }}
      </p>
      <p v-if="user_not_found" class="invalid-msg">
        {{ $t( 'authentication.user-not-found' ) }}
      </p>
      <p v-if="wrong_credentials" class="invalid-msg">
        {{ $t( 'authentication.error' ) }}
      </p>
      <juit-button
        class="mt-2 small"
        :class="{ small: routerback }"
        type="submit"
        form="login-data"
        :disabled="!form_valid"
      >
        {{ $t( 'authentication.submit' ) }}
      </juit-button>
      <div v-if="passwordless" class="normal-login">
        <magic-link class="w-7 mr-4" />
        <span>{{ $t( 'authentication.pwless-login-info' ) }}
          <span class="juit-link" @click="passwordless = false">{{ $t( 'authentication.normal-login-info' ) }}</span>
        </span>
      </div>

      <!-- CHANGE SCREEN TO RESET PASSWORD -->
      <div v-if="!passwordless" class="juit-link text-left w-full mt-3" @click="$emit('screen', 'reset_pw')">
        {{ $t( 'authentication.reset-password-link' ) }}
      </div>
    </form>

    <!-- TO REGISTER -->
    <div v-if="$route.name !== 'checkout'" class="modal-footer mt-4 pt-6">
      <h2 class="mb-2 md:mb-4">
        {{ $t( 'authentication.noaccount' ) }}
      </h2>
      <juit-button class="mb-2 w-full outline small" :class="{ small: routerback }" @click="toRegister()">
        {{ $t( 'authentication.in-berlin' ) }}
      </juit-button>
    </div>
  </div>
</template>

<script lang="ts">
  import juitButton from '../../widgets/juit-button.vue'
  import { analyticsEvent } from '../../analytics'
  import { client } from '../../init/client'
  import { defineComponent } from 'vue'
  import { log } from '../../init/log'
  import { magicLinkToken } from '../../init/state'
  import { profile_magic_link as magicLink } from '../../assets/async'
  import { reactiveCart, reactiveCheckout } from '../../init/reactive-order'

  export default defineComponent({
    name: 'JuitLoginScreen',
    components: { juitButton, magicLink },
    props: {
      routerback: {
        type: Boolean,
        reqired: false,
        default: true,
      },
    },
    emits: {
      loading: (confirm: boolean) => true,
      screen: (active_screen: string) => true,
      email: (email: string) => true,
      close: () => true,
      guest: null,
    },
    data: () => ({
      email: reactiveCheckout.addresses.email || '',
      email_valid: Boolean(reactiveCheckout.addresses.email) || false,
      password: '',
      password_valid: false,
      passwordless: true,
      wrong_credentials: false,
      server_error: false,
      user_not_found: false,
    }),

    computed: {
      form_valid(): boolean {
        return this.email_valid && (this.password_valid || this.passwordless)
      },

      credentials() {
        return [ this.email, this.password ]
      },
    },

    watch: {
      credentials: {
        handler() {
          this.user_not_found = false
          this.server_error = false
          this.wrong_credentials = false
        },
        deep: true,
      },
    },

    methods: {
      /* ==================================================================== *
       * Login                                                                *
       * -------------------------------------------------------------------- */
      login() {
        this.$emit('loading', true)
        this.wrong_credentials = false
        this.user_not_found = false
        this.server_error = false
        // Passwordless login
        if (this.passwordless) {
          client.sendMagicLink({ email: this.email, redirect_url: window.location.href, session: { cart: reactiveCart } }).then((res) => {
            if (res) magicLinkToken.value = res.token
            this.$emit('screen', 'passwordless_validation')
            this.$emit('loading', false)
            return this.$emit('email', this.email)
          }).catch((e) => {
            if (e.status === 400) this.user_not_found = true
            else this.server_error = true
            this.$emit('loading', false)
            log(e)
          })

        // Normal login
        } else {
          client.login({ email: this.email, password: this.password }).then((user) => {
            this.$emit('loading', false)
            if (user) {
              analyticsEvent('login', {
                method: 'email',
                user_id: user.uuid,
              })
              if (this.routerback) window.history.go(-1)
              setTimeout(() => this.$emit('close'), 550)
            }
          }).catch((e) => {
            if (e.status === 401) this.wrong_credentials = true
            else this.server_error = true
            this.$emit('loading', false)
            log(e)
          })
        }
      },

      /* ==================================================================== *
       * To Register                                                          *
       * -------------------------------------------------------------------- */
      toRegister() {
        this.$emit('screen', 'register')
      },
    },
  })
</script>

<style lang="pcss" scoped>
  .login {
    @apply text-center relative;

    .login-form {
      @apply flex flex-col;
      .normal-login {
        @apply border border-black mt-4 pt-2.5 pb-3 px-3 flex cursor-default text-left;
      }
    }
  }

</style>
