<template>
  <div v-intersect="onIntersection" class="video-container">
    <video
      ref="video"
      v-bind="{ ...$attrs, ...sources }"
      class="video"
      autoplay
      playsinline
      webkit-playsinline
      muted
      :class="[ { ready }, fit ]"
    />
    <juit-spinner v-if="!ready" class="large" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'

  export default defineComponent({
    inheritAttrs: false,
    props: {
      src: {
        type: String as PropType<string | null | undefined>,
        required: false,
        default: undefined,
      },
      fit: {
        type: String as PropType<'cover' | 'contain'>,
        required: false,
        default: 'cover',
      },
    },
    data() {
      return {
        sources: undefined as Record<string, string> | undefined,
        video: undefined as HTMLMediaElement | undefined,
        visible: false,
        ready: false,
      }
    },

    watch: {
      src() {
        this.sources = undefined
        this.onIntersection(this.visible)
      },
    },

    mounted() {
      const self = this
      const video = this.$refs.video as HTMLMediaElement
      video.addEventListener('loadeddata', function() {
        if (video.readyState >= 2) {
          self.ready = true
          video.play()
        }
      })
    },

    methods: {
      onIntersection(visible: boolean) {
        if (visible && this.src && (! this.sources)) {
          const { src, fit } = this

          const sources = {} as Record<string, string>
          const source = new URL(src, window.location.href)

          if (fit === 'cover') source.searchParams.set('fit', 'fill')
          sources.src = source.href
          this.sources = sources
        }
        this.visible = visible
      },
    },
  })
</script>

<style scoped lang="pcss">
  div.video-container {
    @apply opacity-100 w-full h-full relative;
    overflow: hidden;
  }

  .video {
    @apply w-full lg:absolute mt-0;
    @apply transition duration-300;
    @apply opacity-0;

    &.cover {
      @apply w-full h-full top-0 left-0;
      @apply object-cover;
    }

    &.contain {
      @apply w-full h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
      @apply object-contain;
    }

    &.ready {
      @apply opacity-100;
    }
  }
</style>
