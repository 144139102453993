<template>
  <div class="badge" v-bind="$attrs">
    <slot />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    inheritAttrs: false,
  })
</script>

<style scoped lang="pcss">
  div {
    @apply flex flex-nowrap;
    @apply px-1.5;
    @apply text-xl;
    @apply border border-black;

    @apply bg-ash text-black;
    @apply uppercase;

    @apply cursor-default;

    @apply transition-all duration-75;
    @apply fill-current;

    &.small {
      @apply mobile:text-base tablet:text-lg;
    }
    &.smaller {
      @apply text-base tracking-wide;
    }
    &.tiny {
      @apply text-sm px-0.5;
      &.planted {
        @apply px-1 py-0.5;
        :deep() svg {
          @apply h-3;
        }
      }
    }
    &.round {
      @apply rounded-full;
    }
    &.revert {
      @apply bg-black text-ash;
    }
    &.planted {
      @apply border-none bg-planted;
      @apply order-10;
      :deep() svg {
        @apply mx-0;
      }
      :deep() span {
        @apply !hidden;
      }
    }
    &.Sweets {
      @apply bg-sweetBg;
      @apply border-sweetBg;
      @apply text-sweetRed;
      :deep() svg {
        @apply h-5 ml-0 mr-1;
      }
      &.small-icon {
        :deep() svg {
          @apply h-3.5 mx-0;
        }
      }
    }
    &.XL {
      @apply bg-teal;
      @apply border-teal;
    }
    &.Extra {
      @apply bg-black;
      @apply text-white;
    }
    &.Special, &.Wagyu {
      @apply bg-specialOrange;
      @apply border-specialOrange;
      /* the "crown" icon should be smaller than the rest of icons, for proportions */
      :deep() svg {
        @apply h-4 ml-0;
      }
      &.small-icon {
        :deep() svg {
          @apply h-3;
        }
      }
    }

    &.Spargel, &.Asparagus {
      @apply bg-greenTag;
      @apply border-greenTag;
      :deep() svg {
        @apply h-6 mr-1 ml-0;
      }
      &.small-icon {
        :deep() svg {
          @apply h-4 mr-0.5;
        }
      }
    }

    &.Fish, &.Fisch {
      @apply bg-pink;
      @apply border-pink;
      :deep() svg {
        @apply h-4 transform scale-x-80 origin-left -mr-0.5 ml-0;
      }
      &.small-icon {
        :deep() svg {
          @apply h-3;
        }
      }
    }

    /* SVG and span text styles */
    :deep() span {
      @apply text-current whitespace-nowrap;
    }
    :deep() svg {
      @apply h-4 md:h-5 ml-1 self-center;
      @apply min-w-3;
    }
  }
</style>
