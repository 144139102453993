import { StripeExpressCheckoutElementOptions } from '@stripe/stripe-js'
import { log } from './log'

export async function applePayCapabilitiesCheck(): Promise<boolean | undefined> {
  if (!window.ApplePaySession) return undefined
  const merchantIdentifier = `merchant.${window.location.host}.stripe`
  const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier)
  return promise.then(function(canMakePayments: boolean) {
    return canMakePayments
  }, (reason: any) => log(reason))
}

export const stripe_appearance = {
  variables: {
    borderRadius: '0px',
  },
}

export const stripe_options: StripeExpressCheckoutElementOptions = {
  paymentMethods: {
    applePay: 'always',
    link: 'never',
  },
  buttonType: {
    applePay: 'buy',
    googlePay: 'buy',
  },
}
