<!--==================================================================+
| VIEW: DISH SELECTION                                                |
+===================================================================-->
<template>
  <main class="dish-selection">
    <section v-if="available_dishes" class="relative">
      <div class="top-bar">
        <div class="container">
          <div class="self-center flex flex-grow relative">
            <progress-bar
              :thresholds="box_options"
              :current="cart.total"
              :goal="cart.box"
              :nomax="true"
              :shownumber="true"
            />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="relative mobile:pb-12 md:mt-4 md:pr-[300px] lg:pr-[320px] xl-order:pr-[360px] xxl:pr-[440px]">
          <!--==================================================================+
          | DISH FILTERS                                                        |
          +===================================================================-->
          <juit-tags-filter
            v-if="available_dishes"
            :orderflow="true"
            :list="preferred_products"
            :all-dishes="available_dishes"
            :advanced-filters="true"
            @filtered="filtered_products = $event"
          />

          <!--==================================================================+
          | DISHES                                                              |
          +===================================================================-->

          <!-- DISHES -->

          <div v-if="filtered_products" class="tiled">
            <juit-submitting :visible="!filtered_products" class="mt-12" />
            <div
              v-for="product in filtered_products"
              v-show="(product.specialTag !== 'Extra') && (product.specialTag !== 'Sweets')"
              :key="product.ean"
              class="tile group"
            >
              <juit-dish-card :product="product" :b2b="true" />
            </div>
          </div>

          <!-- EXTRAS (includes Sweets) -->

          <h2 v-if="available_extras" class="pt-8 md:pt-12 pb-0 text-4xl md:text-5xl">
            {{ $t('dishes.extras_title') }}
          </h2>
          <p v-if="available_extras" class="pt-0 pb-5">
            {{ $t('dishes.extras_subtitle') }}
          </p>

          <div v-if="filtered_products" class="tiled">
            <div
              v-for="product in filtered_products"
              v-show="(product.specialTag === 'Extra') || (product.specialTag === 'Sweets')"
              :key="product.ean"
              class="tile group"
            >
              <juit-dish-card :product="product" :b2b="true" />
            </div>
          </div>
        </div>
      </div>
      <!--==================================================================+
      | CART                                                                |
      +===================================================================-->
      <juit-cart v-if="available_dishes" :products="available_dishes" :b2b="true" />
    </section>
  </main>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import juitTagsFilter from '../widgets/juit-tags-filter.vue'
  import juitDishCard from '../components/order/dish-card.vue'
  import juitCart from '../components/order/cart.vue'
  import alertModal from '../modals/alert-modal.vue'
  import { availableDishes, visibilityCheckAll, visibilityCheck } from '../init/inventories'
  import { Dish, fetchProducts } from '../content'
  import { locale } from '../init/i18n'
  import { checkoutQueue } from '../init/checkout-queue'
  import { reactiveCart, boxOptionsB2B, ReactiveCart } from '../init/reactive-order'
  import progressBar from '../widgets/juit-progress-bar.vue'
  import { log, logError } from '../init/log'

  export default defineComponent({
    components: {
      juitTagsFilter,
      juitDishCard,
      juitCart,
      progressBar,
    },

    data: () => ({
      filtered_products: undefined as undefined | Dish[],
      preferred_products: [] as Dish[],
      box_options: boxOptionsB2B,
    }),

    computed: {
      available_dishes() {
        return availableDishes.value as Dish[]
      },

      watch_for() {
        return [ checkoutQueue.init_inventory, locale.value ]
      },

      cart(): ReactiveCart {
        return reactiveCart
      },

      // we check if there are any extras, to show/hide the title
      available_extras() {
        if (!this.filtered_products) return false
        return this.filtered_products.some((dish) => ((dish.specialTag === 'Extra') || (dish.specialTag === 'Sweets')))
      },
    },

    watch: {
      /* ========================================================================== *
       * Fetch products on locale change and after inventory loaded                 *
       * -------------------------------------------------------------------------- */
      watch_for: {
        async handler() {
          if (checkoutQueue.init_inventory === 'done') {
            await visibilityCheckAll()
            fetchProducts(locale.value, 'Order Page Most Popular').then((dishes) => {
              if (dishes) this.preferred_products = visibilityCheck(dishes)
              log('%cPreferred available dishes:', 'color: firebrick', this.preferred_products)
            }).catch((error) => logError(`Error fetching products (locale="${locale}")`, error))

            // If some dishes from the reactive cart are unavailable, show a modal
            if (reactiveCart.outOfStockDishes.length) {
              await this.$createModal(alertModal as any, {
                title: this.$t('checkout.not-available'),
                description: this.$t('checkout.dish-not-available'),
                url: '/order',
                locked: true,
              })
              // we remove the dishes that are out of stock
              reactiveCart.removeOutOfStock()
            }
          }
        },
        deep: true,
        immediate: true,
      },
    },

    created() {
      this.cart.b2b = true
      this.$nextTick(() => this.cart.updateServerCart())
    },
  })
</script>

<style scoped lang="pcss">
.dish-selection {
  @apply w-screen overflow-hidden pb-36 md:pb-16;
  .tiled {
    @apply -mx-1 -my-0 flex-wrap md:-mt-1;
    /* Single card */
    .tile {
      @apply mx-0 p-1;
      @apply w-full lg:w-1/2;
    }
  }
  .top-bar {
    @apply mobile:hidden bg-white w-full fixed top-12 lg:top-16 z-40 border-t border-gray-300;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.08);
    > .container {
      @apply py-3 flex-row;
    }
  }
}
</style>
