import { Locale } from '../init/i18n'
import { log, logGroupCollapsed, logGroupEnd } from '../init/log'
import { contentfulClient, getTypedEntries } from './client'
import { convert, List, Page, Dish } from './convert'
import { Document } from '@contentful/rich-text-types'
import { convertJobs } from './jobs'
import { client } from '../init/client'
import { Statistics } from '@juitnow/api-comments'

const pageCache = new Map<Locale, Map<string, Promise<Page | undefined>>>()
export function fetchPage(locale: Locale, slug: string): Promise<Page | undefined> {
  let cache = pageCache.get(locale)
  if (! cache) pageCache.set(locale, cache = new Map())

  let page = cache.get(slug)
  if (page) return page

  const query = { locale, 'include': 4 } as Record<string, any>
  log(`%cCaching page "${locale}/${slug}"`, 'color: gray')

  cache.set(slug, page = (async () => {
    const entries = await getTypedEntries('page', { 'fields.slug': slug, ...query })
    if (entries.items[0]) return convert(entries.items[0])
    else {
      const other_locale = locale === 'en' ? 'de' : 'en'
      query[`fields.slug.${other_locale}`] = slug
      log(`%cCan't find "${locale}/${slug}". Fetching ${locale} page from "${other_locale}/${slug}"`, 'color: gray')
      const entries_other_locale = await getTypedEntries('page', query)
      if (!entries_other_locale.items[0]) return undefined
      log(`%cCaching page "${locale}/${entries_other_locale.items[0].fields.slug}"`, 'color: gray')
      return convert(entries_other_locale.items[0])
    }
  })())

  return page
}

/* ========================================================================== */

const listCache = new Map<Locale, Map<string, Promise<List | undefined>>>()

export function fetchList(locale: Locale, slug: string): Promise<List | undefined> {
  let cache = listCache.get(locale)
  if (! cache) listCache.set(locale, cache = new Map())

  let list = cache.get(slug)
  if (list) return list

  log(`%cCaching list "${locale}/${slug}"`, 'color: gray')
  cache.set(slug, list = (async () => {
    const entries = await getTypedEntries('list', { locale, 'fields.slug': slug, 'include': 5 })
    const list = convert(entries.items[0])
    if (! list) return

    let cache = pageCache.get(locale)
    if (! cache) pageCache.set(locale, cache = new Map())

    logGroupCollapsed('Fetching pages from list "%s"...', list.title)
    for (const page of list.links) {
      log(`%cCaching page "${locale}/${page.slug}" (from list)`, 'color: gray')
      cache.set(page.slug, Promise.resolve(page))
    }
    logGroupEnd()

    return list
  })())

  return list
}

/* ========================================================================== */

const translationsCache = new Map<Locale, Record<string, Record<string, any>>>()

export async function fetchTranslations(locale: Locale): Promise<Record<string, Record<string, any>>> {
  let translations = translationsCache.get(locale)
  if (translations) return translations

  log('Downloading translations for "%s"', locale)
  const entries = await getTypedEntries('translations', { locale })

  translations = entries.items.reduce((q, { fields }) => {
    return Object.assign(q, { [fields.slug]: fields.strings || {} })
  }, {} as Record<string, Record<string, any>>)

  translationsCache.set(locale, translations)
  return translations
}

/* ========================================================================== */

const translationsRichTextCache = new Map<Locale, Record<string, Document>>()

export async function fetchTranslationsRich(locale: Locale): Promise<Record<string, Document> | undefined> {
  let translationsRichText = translationsRichTextCache.get(locale)
  if (translationsRichText) return translationsRichText

  log('Downloading translations (rich text) for "%s"', locale)
  const entries = await getTypedEntries('translationsRichText', { locale })

  translationsRichText = entries.items.reduce((q, { fields }) => {
    return Object.assign(q, { [fields.title]: fields.content || {} })
  }, {} as Record<string, Document>)

  translationsRichTextCache.set(locale, translationsRichText)
  return translationsRichText
}

/* ========================================================================== */

const productCache = new Map<Locale, Map<string, Promise<Dish | undefined>>>()

export function fetchProduct(locale: Locale, slugOrEan: string): Promise<Dish | undefined> {
  let cache = productCache.get(locale)
  if (! cache) productCache.set(locale, cache = new Map())

  let dish = cache.get(slugOrEan)
  if (dish) return dish

  log(`%cCaching dish "${locale}/${slugOrEan}"`, 'color: gray')

  dish = Promise.resolve().then(async () => {
    let options: Record<string, any> = { locale, 'include': 3 }

    if (! /^\d{13}$/.test(slugOrEan)) options['fields.slug'] = slugOrEan
    else options['fields.ean'] = slugOrEan

    const entries = await getTypedEntries('dish', options)
    let converted_dish = undefined as Dish | undefined
    if (!entries.items[0] && 'fields.slug' in options) {
      const other_locale = locale === 'en' ? 'de' : 'en'
      options = Object.assign({}, { locale, 'include': 3 })
      options[`fields.slug.${other_locale}`] = slugOrEan
      log(`%cCan't find "${locale}/${slugOrEan}". Fetching ${locale} dish from "${other_locale}/${slugOrEan}"`, 'color: gray')
      const entries_other_locale = await getTypedEntries('dish', options)
      if (!entries_other_locale.items[0]) return undefined
      log(`%cCaching dish "${locale}/${entries_other_locale.items[0].fields.slug}"`, 'color: gray')
      converted_dish = convert(entries_other_locale.items[0])
    } else converted_dish = convert(entries.items[0])

    if (converted_dish) {
      cache?.set(converted_dish.ean, Promise.resolve(dish))
      cache?.set(converted_dish.slug, Promise.resolve(dish))
    }

    return converted_dish
  }).catch((error) => {
    cache?.delete(slugOrEan)
    throw error
  })

  cache.set(slugOrEan, dish)
  return dish
}

/* ========================================================================== */

const allProductsCache = new Map<Locale, Map<string, Promise<Dish[] | undefined>>>()

export function fetchAllProducts(locale: Locale): Promise<Dish[] | undefined> {
  let cache = allProductsCache.get(locale)
  if (!cache) allProductsCache.set(locale, cache = new Map())

  let list = cache.get('all')
  if (list) return list

  cache.set('all', list = (async () => {
    const entries = (await getTypedEntries('dish', { locale })).items
    const dishes = entries.map((entry) => convert(entry))

    let cache = productCache.get(locale)
    if (! cache) productCache.set(locale, cache = new Map())

    const newProductsCache: Dish[] = []
    logGroupCollapsed('Fetching all dishes...')
    for (const dish of dishes) {
      if (! dish) continue
      log(`%cCaching dish "${locale}/${dish.slug}"`, 'color: gray')

      cache?.set(dish.slug, Promise.resolve(dish))
      cache?.set(dish.ean, Promise.resolve(dish))
      newProductsCache.push(dish)
    }
    logGroupEnd()
    return newProductsCache
  })())

  return list
}

/* ========================================================================== */

const productsListCache = new Map<Locale, Map<string, Promise<Dish[] | undefined>>>()

export function fetchProducts(locale: Locale, title: string): Promise<Dish[] | undefined> {
  let cache = productsListCache.get(locale)
  if (! cache) productsListCache.set(locale, cache = new Map())

  let list = cache.get(title)
  if (list) return list

  cache.set(title, list = (async () => {
    const entries = await getTypedEntries('dishList', { locale, 'fields.title': title, 'include': 4 })
    const dishlist = convert(entries.items[0])
    if (! dishlist) return

    let cache = productCache.get(locale)
    if (! cache) productCache.set(locale, cache = new Map())

    logGroupCollapsed('Fetching dishes from list "%s"...', title)
    for (const dish of dishlist.list) {
      log(`%cCaching dish "${locale}/${dish.slug}" from list "${title}"`, 'color: gray')
      cache.set(dish.slug, Promise.resolve(dish))
    }
    logGroupEnd()

    return dishlist.list
  })())
  return list
}

/* ========================================================================== */

const globalRatingsCache = new Map<'stats', Map<string, Promise<Statistics | undefined>>>()

export async function fetchGlobalRatingStats(): Promise<Statistics | undefined> {
  let cache = globalRatingsCache.get('stats')
  if (!cache) globalRatingsCache.set('stats', cache = new Map())
  let stats = cache.get('stats')
  if (stats) return stats

  cache.set('stats', stats = (async () => {
    const response = await client.comments.stats()
    log('%cCaching global user rating statistics', 'color: gray')
    return response
  })())
  return stats
}

/* ========================================================================== */

const JOBS_URL = 'https://oetkerdigital1.recruitee.com/api/offers?tag=Juit'
const jobsCache = new Map<Locale, Map<string, Promise<any[]>>>()

export async function fetchJobs(locale: Locale): Promise<any[]> {
  let cache = jobsCache.get(locale)
  if (!cache) jobsCache.set(locale, cache = new Map())
  let jobs = cache.get(locale)
  if (jobs) return jobs

  cache.set(locale, jobs = (async () => {
    const response = await fetch(JOBS_URL) as any
    log('%cCaching jobs', 'color: gray')
    return convertJobs(await response.json())
  })())
  return jobs
}

/* ========================================================================== */

export async function fetchAlternateSlug(locale: Locale, slug: string, type: 'page' | 'dish', isRating: boolean): Promise<string> {
  const other_locale = locale === 'en' ? 'de' : 'en'
  const options = {
    'content_type': type,
    'locale': other_locale,
    'select': 'fields.slug',
  } as Record<string, string>
  options[`fields.slug.${locale}`] = slug
  const entries = await contentfulClient.getEntries(options)
  const dishURL = type === 'dish' ? isRating ? '/rating' : other_locale === 'en' ? '/dishes' : '/gerichte' : ''
  return `/${other_locale}${dishURL}/${entries.items?.length ? (entries.items[0].fields as any).slug : slug}`
}
