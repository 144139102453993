/* ========================================================================== *
 * "META" DATA FROM OUR APP                                                   *
 * -------------------------------------------------------------------------- *
 * Abstract a way for our app to expose metadata, such as language, title,    *
 * page description, ...                                                      *
 * The client renderer (index.ts) and server side renderer (render.ts) will   *
 * handle the changes from this and accordingly set the values in either the  *
 * DOM or in the pre-rendered HTML                                            *
 * ========================================================================== */

import { ref } from '@vue/runtime-core'
import { watch } from 'vue'
import { locale } from './i18n'

/**
 * The _title_ of the page.
 *
 * Should be used in:
 * * `<title>...</title>`
 * * `<meta property="og:title" content="..."/>`
 */
export const title = ref('Juit')

/**
 * The _language_ of the page.
 *
 * Should be used in:
 * * `<html lang="...">`
 * * `<meta http-equiv="content-language" content="...">`
 */
export const language = locale

/**
 * The _description_ of the page.
 *
 * Should be used in:
 * * `<meta name="description" content="...">`
 * * `<meta property="og:description" content="...">`
 */
export const description = ref(null as string | null | undefined)

/**
 * The _image_ of the page.
 *
 * Should be used in:
 * * `<meta property="og:image" content="...">`
 */
export const image = ref(null as string | null | undefined)

/**
 * The _keywords_ of the page.
 *
 * Should be used in:
 * * `<meta name="keywords" content="...">`
 */
export const keywords = ref(null as string[] | null | undefined)

/* ========================================================================== *
 * Update <meta ...> (and other) tags outside of the app                      *
 * ========================================================================== */

function updateMeta(
    attributeName: string,
    attributeValue: string,
    content: string | null | undefined,
): void {
// Find all `meta` elements in <head> with the given attribute
  const query = `meta[${attributeName}="${attributeValue}"]`
  const elements = document.head.querySelectorAll(query)

  // Remove all elements found
  elements.forEach((element) => {
    element.parentElement?.removeChild(element)
  })

  // Create a new element with the correct attributes
  if (content) {
    const meta = document.createElement('meta')
    meta.setAttribute(attributeName, attributeValue)
    meta.setAttribute('content', content)
    document.head.appendChild(meta)
  }
}

/*
* Watch the _language_ and update
*
* <html lang="...">
* <meta http-equiv="content-language" content="...">
*/
watch(language, (language) => {
  document.documentElement.setAttribute('lang', language)
  updateMeta('http-equiv', 'content-language', language)
})

/*
* Watch the _title_ and update
*
* <title>...</title>
* <meta property="og:title" content="..."/>
*/
watch(title, (title) => {
  document.title = title
  updateMeta('property', 'og:title', title)
})

/**
* Watch the _description_ and update
*
* <meta name="description" content="...">
* <meta property="og:description" content="...">
*/
watch(description, (description) => {
  updateMeta('name', 'description', description)
  updateMeta('property', 'og:description', description)
})

/**
* Watch the _image_ and update
*
* <meta property="og:image" content="...">
*/
watch(image, (image) => {
  updateMeta('property', 'og:image', image)
})

/**
* Watch the _keywords_ and update
*
* <meta name="keywords" content="...">
*/
watch(keywords, (keywords) => {
  const content = keywords ? keywords.join(',') : ''
  updateMeta('name', 'keywords', content)
})
