<template>
  <div>
    <spinner-svg
      v-if="present"
      class="spinner"
      :class="{ visible }"
      @transitionend="onTransitionEnd"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { spinner as spinnerSvg } from '../assets/async'

  export default defineComponent({
    components: { spinnerSvg },
    props: {
      enabled: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        present: this.enabled,
        visible: this.enabled,
      }
    },
    watch: {
      enabled(enabled: boolean) {
        if (enabled) {
          this.present = true
          this.visible = true
        } else if (this.visible) {
          this.present = true
          this.visible = false
        } else {
          this.present = false
          this.visible = false
        }
      },
    },
    methods: {
      onTransitionEnd() {
        if (! this.visible) this.present = false
      },
    },
  })
</script>

<style scoped lang="pcss">
  div {
    @apply top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2;
    @apply w-12 h-12;

    &.small {
      @apply w-5 h-5;
    }

    &.large {
      @apply w-24 h-24;
    }

  }
  .spinner {
    animation:
      rotate 2.5s linear infinite,
      dash 2.5s ease-in-out infinite,
      color 2.5s ease-in-out infinite;

    @apply w-full h-full;
    @apply origin-center;
    @apply relative;
    @apply inset-0;
    @apply m-auto;
    @apply opacity-0;
    @apply transition-all duration-300;
    @apply block;

    &.visible {
      @apply opacity-100;
    }

    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-width: 3;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: rgb(28, 67, 248);
    }
    50% {
      stroke: rgb(0, 33, 185);
    }
  }

</style>
