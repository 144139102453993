import { log } from '../init/log'

/**
 * Campaign data from URL query parameters.
 *
 * The query string parameters are normally prefixed with `utm_...`, and those
 * are described in https://support.google.com/analytics/answer/10917952?hl=en
 *
 * GTAG, on the other hand, has a somewhat different structure, documented in
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/config
 *
 * The mapping between UTM parameters and GTAG parameters therefore is:
 *
 * | UTM                  | GTAG             | NOTES                     |
 * |----------------------|------------------|---------------------------|
 * | utm_id               | campaign_id      |                           |
 * | utm_campaign         | campaign_name    |                           |
 * | utm_source           | campaign_source  |                           |
 * | utm_medium           | campaign_medium  |                           |
 * | utm_term             | campaign_term    |                           |
 * | utm_content          | campaign_content |                           |
 * | utm_campaign_id      |                  | Only for keyword analisys |
 * | utm_source_platform  |                  |                           |
 * | utm_creative_format  |                  | Not reported in GA4       |
 * | utm_marketing_tactic |                  | Not reported in GA4       |
 */
interface CampaignData {
  /** Campaign ID. */
  campaign_id?: string,
  /** Campaign name (e.g. `spring_sale`, ...). */
  campaign_name?: string,
  /** Referrer (e.g. `google`, `newsletter4`, `billboard`, ...). */
  campaign_source?: string,
  /** Marketing medium (e.g. `cpc`, `banner`, `email`, ...). */
  campaign_medium?: string,
  /** Paid keyword. */
  campaign_term?: string,
  /**
   * Use to differentiate creatives. For example, if you have two call-to-action
   * links within the same email message, you can use `utm_content` and set
   * different values for each so you can tell which version is more effective.
   */
   campaign_content?: string,
}

/** Key for the _"Google Click ID"_ (from AdWords) in _session_ storage */
const googleClickIdStorageKey = 'jn:gclid'

/** Key for `CampaignData` in _session_ storage */
const campaignDataStorageKey = 'jn:campaign'

/** Mapping of our keys to UTM query parameter names */
const utmMappings: { [ k in keyof CampaignData ]-?: string } = {
  campaign_id: 'utm_id',
  campaign_name: 'utm_campaign',
  campaign_source: 'utm_source',
  campaign_medium: 'utm_medium',
  campaign_term: 'utm_term',
  campaign_content: 'utm_content',
}

/** Process the query string and return a `CampaignData` instance */
function getCampaignData(): Readonly<CampaignData> | undefined {
  const campaignData: CampaignData = {}

  // Process the campaign data from the url query parameters
  const queryParameters = new URLSearchParams(globalThis.window?.location.search)
  for (const [ key, utm ] of Object.entries(utmMappings)) {
    const value = queryParameters.get(utm)
    if (value) campaignData[key as keyof CampaignData] = value
  }

  // If the campaign data has no keys, then try to read up any previous value
  // stored in _session_ storage... We want to get back the data on page reload
  if (! Object.keys(campaignData).length) {
    try {
      const json = globalThis.sessionStorage?.getItem(campaignDataStorageKey) || '{}'
      Object.assign(campaignData, JSON.parse(json))
    } catch {
      globalThis.sessionStorage?.removeItem(campaignDataStorageKey)
    }
  }

  // If we have anything in campaign data, then we store it and return it frozen
  // otherwise we return `undefined` to mark the fact that we have nothing...
  if (Object.keys(campaignData).length) {
    log('Found campaign data', campaignData)
    globalThis.sessionStorage?.setItem(campaignDataStorageKey, JSON.stringify(campaignData))
    return Object.freeze(campaignData)
  } else {
    log('Found no campaign data associated with the current session')
    return undefined
  }
}

/** Process the query string and return the _Google Click ID_ if any */
function getGoogleClickId(): Readonly<string> | undefined {
  const queryParameters = new URLSearchParams(globalThis.window?.location.search)

  // Priority always goes to the query parameter, then session storage
  const googleClickId = queryParameters.get('gclid') ||
    globalThis.sessionStorage?.getItem(googleClickIdStorageKey) ||
    undefined

  // If we have the click ID, then we store it and return it frozen
  // otherwise we return `undefined` to mark the fact that we have nothing...
  if (googleClickId) {
    log('Found google click id', googleClickId)
    globalThis.sessionStorage?.setItem(googleClickIdStorageKey, googleClickId)
  } else {
    log('Found no google click id associated with the current session')
    return undefined
  }
}

/**
 * Export a _read only_ unmodifiable `CampaignData` instance.
 *
 * This is exported _as is_ (unmodifiable, no `ref(...)`) because campaign
 * data comes from the `utm_...` parameters of the _original_ URL, and gets
 * persisted across one session, but we should _never_ modify it.
 */
export const campaignData: Readonly<CampaignData> | undefined = getCampaignData()


/** Export the _"Google Click ID"_ coming from Google Adwords. */
export const googleClickId: Readonly<string> | undefined = getGoogleClickId()
