<!-- eslint-disable vue/no-v-html -->
<!--==================================================================+
| COMPONENT: HERO                                                     |
+===================================================================-->

<template>
  <section v-if="hero" class="hero container">
    <div class="tiled my-0">
      <div class="tile panel-text">
        <h1 v-html="hero.title" />
        <rich-text
          v-if="hero.description"
          :document="hero.description"
          class="subheading"
        />

        <juit-social-media-links v-if="hero.socialMediaLinks" class="reverse md:mb-2 md:mt-7" />
        <juit-button
          v-if="hero.cta?.slug"
          :to="`/${$i18n.locale}/${hero.cta.slug}`"
          class="mt-4"
        >
          <span class="cta-btn-text">{{ hero.cta.text }}</span>
        </juit-button>
        <juit-button
          v-else-if="hero.ctaExternal?.slug"
          :href="`${hero.ctaExternal.slug}`"
          target="_blank"
          class="mt-4"
        >
          <span class="cta-btn-text">{{ hero.ctaExternal.text }}</span>
        </juit-button>
      </div>
      <juit-global-rating v-if="hero.globalRating" class="tile global-rating" />

      <div class="tile panel-media">
        <juit-image
          v-if="hero.image.contentType.includes('image')"
          v-bind="hero.image"
          :class="{ clipped: !hero.shrink }"
          :fit="hero.shrink ? 'contain' : 'cover'"
        />
        <juit-video
          v-if="hero.image.contentType.includes('video')"
          v-bind="hero.image"
          loop
          muted
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Panel } from '../content'
  import juitGlobalRating from '../widgets/juit-global-rating.vue'
  import juitSocialMediaLinks from '../widgets/juit-social-media-links.vue'

  export default defineComponent({
    name: 'JuitHero',
    components: {
      juitGlobalRating,
      juitSocialMediaLinks,
    },
    props: {
      hero: {
        type: Object as PropType<Panel>,
        required: true,
      },
    },
  })
</script>

<style scoped lang="pcss">
  .hero {
    @apply mt-12 lg:mt-16 lg:py-0;
    .tiled {
      @apply mx-0;
      .tile {
        @apply w-full;

        &.panel-text {
          @apply mobile:px-0 md:w-1/3 text-right mobile:order-1 self-center pb-0 pt-6 md:pr-0 md:pl-8 lg:pl-10 md:py-8 lg:py-16 order-1;

          .subheading {
            @apply leading-normal -mb-1.5;
            :deep() {
              li {
                @apply mobile:m-0 mobile:block;
              }
            }
          }
        }
        &.global-rating {
          @apply self-center order-1 pb-0 pr-0 pt-7 md:pt-0 md:-translate-y-full;
          @apply lg:w-1/3 lg:self-end lg:-translate-x-full lg:-translate-y-0;
        }
        &.panel-media {
          @apply relative md:w-2/3;
          @apply min-h-1/3-screen-h md:min-h-3/4-screen-h;
          @apply p-0 mobile:w-full;
          .image-container {
            @apply md:absolute w-full left-0 top-0;
          }
        }
      }
    }
  }
</style>

