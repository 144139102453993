import { log } from '../init/log'
import { GAEvent, GAEvents, isAnalyticsEvent } from './google'

/* ========================================================================== *
 * LOADING TABOOLA TAG                                                        *
 * ========================================================================== */

const loadedTaboolaTags = new Set<string>()

export function loadTaboolaTag(pixelId: string): void {
  if (!pixelId) return log('No Taboola Tag on dev') // Track prod only
  if (loadedTaboolaTags.has(pixelId)) return log(`Taboola Tag ${pixelId} already loaded`)

  log('Loading Taboola Tag', pixelId)
  const script = document.createElement('script')
  script.text = `
    window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: 'page_view', id: ${pixelId}});
    !function (t, f, a, x) {
           if (!document.getElementById(x)) {
              t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
           }
    }(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/${pixelId}/tfa.js',
    'tb_tfa_script')
  `

  // Append our script to the end of the head
  document.head.appendChild(script)
  loadedTaboolaTags.add(pixelId)
}

/* ========================================================================== *
 * TABOOLA TYPES                                                              *
 * ========================================================================== */

interface TaboolaEvent {
  'notify': string,
  'name': string,
  'id': string,
  'item-url'?: string,
  'value'?: number,
  'orderid'?: string,
  'currency'?: string,
  'quantity'?: number | string,
}

declare global {
  interface Window {
    _tfa?: TaboolaEvent[]
  }
}

/* ========================================================================== *
 * TABOOLA IMPLEMENTATION                                                     *
 * ========================================================================== */

export function taboolaEvent<K extends GAEvent>(event: K, params: GAEvents[K], id: string): number | undefined {
  // Taboola `page_view` events
  if (isAnalyticsEvent('page_view', event, params)) {
    return globalThis.window?._tfa?.push({ 'notify': 'event', 'name': 'page_view', id, 'item-url': params.page_location })
  }

  // All other event types (simply mapping from GA to Taboola events)
  //
  // * add_to_cart      => add_to_cart
  // * begin_checkout   => start_checkout
  // * purchase         => make_purchase

  if (isAnalyticsEvent('add_to_cart', event, params)) {
    return globalThis.window?._tfa?.push({ 'notify': 'event', 'name': 'add_to_cart', id, 'quantity': 1 })
  }

  if (isAnalyticsEvent('begin_checkout', event, params)) {
    return globalThis.window?._tfa?.push({ 'notify': 'event', 'name': 'start_checkout', id })
  }

  if (isAnalyticsEvent('purchase', event, params)) {
    return globalThis.window?._tfa?.push({ 'notify': 'event', 'name': 'make_purchase', id, 'value': params.value, 'orderid': params.order_id, 'currency': params.currency, 'quantity': params.box_size })
  }
}
