<template>
  <div ref="banner" class="banner">
    <p v-if="bannerType === 'warning'">
      {{ $t('notification.warning-banner') }}
    </p>
    <p v-else-if="bannerType === 'discount'">
      {{ discountBannerText }}
    </p>
    <p v-else class="clickable-link" @click="openSubscriptionPage">
      {{ $t('newsletter.banner') }}
    </p>
    <div class="close-button" @click="$emit('closeBanner')">
      <span /><span />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      bannerType: {
        type: String,
        required: true,
      },
      discountBannerText: {
        type: String,
        required: true,
      },
    },
    emits: [ 'closeBanner' ],
    methods: {
      openSubscriptionPage() {
        const url = `/${this.$i18n.locale}/subscribe`
        this.$router.push(url)
        this.$emit('closeBanner')
      },
    },
  },
  )
</script>


<style scoped lang="pcss">
    .banner {
      @apply bg-jade;
      @apply w-screen min-h-9 md:min-h-11 py-1.5;
      @apply flex justify-center relative opacity-100 transition-all;
    }

    p {
      @apply text-white text-center self-center font-bold text-sm sm:text-base md:text-lg xl:text-xl;
      @apply cursor-default;
    }
    .clickable-link {
      @apply cursor-pointer;
    }

    .close-button {
      @apply cursor-pointer;
      @apply h-full w-6 top-0;
      @apply absolute right-2.5 sm:right-6 md:right-12 lg:right-20;
      @apply flex flex-col justify-center items-center;
      span {
        @apply absolute;
        @apply bg-white;
        @apply h-px w-5 sm:w-6 md:w-7;
        @apply first:rotate-45 last:-rotate-45;
      }
    }
    .warning .banner {
      @apply bg-red;
      p {
        @apply text-xs md:text-sm px-9 sm:px-12 md:px-20;
      }
    }

    nav:not(.with-banner) .banner {
      @apply min-h-0 h-0 opacity-0 p-0;
    }

</style>
