export interface Job {
  $id: string,
  title: string,
  location: string,
  descriptionHref: string,
  applicationHref: string,
  description?: string,
}

export function convertJobs(data: any): any[] {
  return data.offers.map((opening: Record<string, any>): Job => ({
    $id: opening.id,
    title: opening.title,
    location: opening.location,
    descriptionHref: opening.careers_url,
    applicationHref: opening.careers_apply_url,
    description: opening.description,
  }))
}
