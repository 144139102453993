<!--==================================================================+
| COMPONENT: NAVIGATION                                               |
+===================================================================-->

<template>
  <nav
    v-if="$route"
    id="header"
    :class="[{ open, 'with-banner': !userScrolling && show_banner }, banner_type]"
    @click="onClose()"
  >
    <juit-banner
      v-if="show_banner"
      :banner-type="banner_type"
      :discount-banner-text="discountBannerText"
      @close-banner="closeBanner"
    />
    <div class="main-nav">
      <div class="container">
        <div class="header-nav-close" />
        <!-- LOGO -->
        <div translate="no" class="logo-wrapper">
          <router-link class="logo" :to="`/${$i18n.locale}`">
            JU IT
          </router-link>
        </div>

        <!-- ROUTER LINKS TO PAGES -->
        <div class="links-wrapper">
          <ul class="router-links">
            <li v-for="(item, index) in business ? business_header : header" :key="`item_${index}`">
              <router-link
                :to="`/${$i18n.locale}/${item.slug}`"
                :class="`/${$i18n.locale}/${item.slug}` === $route.fullPath ? 'underline' : ''"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ul>

          <ul v-if="business" class="router-links call-us">
            <li class="whitespace-nowrap">
              <a class="text">Call us:</a><a :href="'tel:+493031177111'" class="juit-link">030 31177111</a>
            </li>
          </ul>

          <!-- LOGIN ACCOUNT -->
          <div v-if="user !== false" class="max-h-screen dropdown-links-wrapper" :class="{ disabled: !user }">
            <ul class="flex-col">
              <li v-if="user">
                <router-link :class="['dropdown-link', { underline: $route.name === 'profile'}]" :to="`/${$i18n.locale}/profile/dashboard`">
                  {{ $t('navbar.profile') }}
                </router-link>
              </li>
              <li v-if="user" class="logout in-dropdown" @click="logout()">
                <div class="dropdown-link">
                  {{ $t('navbar.logout') }}
                </div>
              </li>
              <li v-else @click="login()">
                <div class="dropdown-link">
                  {{ $t('navbar.login') }}
                </div>
              </li>
            </ul>
          </div>

          <!-- LANGUAGE SELECTION -->
          <div class="dropdown-links-wrapper lg:-mr-3">
            <ul>
              <li
                v-for="(language) in availableLocales"
                :key="language"
                :class="[{ 'in-dropdown' : locale !== language }, 'lang']"
                @click.stop.prevent="setLocale(language)"
              >
                <div :class="['px-3 dropdown-link', { 'active pl-0' : locale === language }]">
                  {{ language.toUpperCase() }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- NAV TOGGLE, MOBILE -->
        <div class="toggle-button" @click.stop="onToggle()">
          <span /><span /><span />
        </div>
      </div>
    </div>

    <!-- EXTRA NAV ON PROFILE PAGE -->
    <profile-nav v-if="$route?.name === 'profile'" />
  </nav>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { header, business } from '../init/router'
  import { locale, availableLocales, Locale, setLocale } from '../init/i18n'
  import { checkoutStep, onScroll } from '../init/state'
  import { accessTokenKey, client, refreshTokenKey, user } from '../init/client'
  import profileNav from '../components/profile/nav.vue'
  import loginRegisterModal from '../modals/login-register-modal.vue'
  import juitBanner from '../widgets/juit-banner.vue'

  export default defineComponent({
    components: {
      profileNav,
      juitBanner,
    },
    props: {
      nohero: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      header,
      business_header: business,
      locale,
      availableLocales,
      open: false,
      showWarningBanner: true,
      showBanner: true,
      discountBannerText: '' as string,
      userScrolling: false,
      warning_until: (new Date('2024-01-11')).getTime(),
    }),

    computed: {
      business() {
        return this.$route.params.page === 'business' || this.$route.path.includes('/business/')
      },

      banner_type() {
        if (this.$route.name?.toString().includes('checkout') && checkoutStep.value === 'summary' && ((new Date().getTime()) < this.warning_until)) return 'warning'
        else if (!this.$t(`banner-discounts.${localStorage.getItem('referral_code')?.toUpperCase()}`).startsWith('[')) return 'discount'
        else if (!this.user) return 'newsletter'
        else return ''
      },

      show_banner() {
        switch (this.banner_type) {
          case 'newsletter':
            return !JSON.parse(localStorage.getItem('jn:subscriptionBannerDismissed') || 'false') && !this.$route.name?.toString().includes('checkout') && this.showBanner
          case 'discount':
            return !this.$route.name?.toString().includes('checkout') && this.showBanner
          case 'warning':
            return !JSON.parse(localStorage.getItem('jn:warningBannerDismissed') || 'false') && this.showWarningBanner
          default: return false
        }
      },
    },

    mounted() {
      window.addEventListener('scroll', this.onScroll)
      this.discountBannerText = this.$t(`banner-discounts.${localStorage.getItem('referral_code')?.toUpperCase()}`)
    },

    methods: {
      /* ==================================================================== *
       * Scrolling & collapsing/closing menus                                 *
       * -------------------------------------------------------------------- */
      onScroll() {
        onScroll.value = window.scrollY > 50
        this.userScrolling=true
        if (window.scrollY === 0) {
          this.userScrolling=false
        }
      },
      onClose() {
        this.open = false
      },
      onToggle() {
        this.open = ! this.open
      },

      /* ==================================================================== *
       * Login / Logout                                                       *
       * -------------------------------------------------------------------- */
      login() {
        this.$createModal(loginRegisterModal as any)
      },
      logout() {
        client.logout()

        localStorage.removeItem(accessTokenKey)
        localStorage.removeItem(refreshTokenKey)
        localStorage.removeItem('jn:compado')
      },

      /* ==================================================================== *
       * Set locale                                                           *
       * -------------------------------------------------------------------- */
      async setLocale(language: Locale) {
        this.onClose()
        setLocale(language)
        // Update the user locale
        if (user) await client.updateUser({ locale: language })
      },

      /* ==================================================================== *
       * Close Banner and Update Local Storage                                *
       * -------------------------------------------------------------------- */
      closeBanner() {
        const key = this.banner_type === 'warning' ? 'jn:warningBannerDismissed' : 'jn:subscriptionBannerDismissed'
        localStorage?.setItem(key, 'true')
        if (this.banner_type === 'warning') this.showWarningBanner = false
        else this.showBanner = false
      },
    },
  })
</script>

<style scoped lang="pcss">
  /* ======================================================================== *
   * Header-nav: root nav element                                             *
   * ======================================================================== */

  /* Nav bar, default look */
  nav {
    @apply fixed top-0 z-50 w-full;

    .main-nav {
      @apply relative mx-auto top-0 z-50;
      @apply bg-white w-screen;
      @apply overflow-hidden lg:overflow-visible;
      @apply transition-all duration-200;
      @apply shadow-md;
      .container {
        @apply flex-row flex-wrap lg:flex-nowrap justify-between lg:justify-start;
      }
    }

    /* With newsletter banner */
    &.with-banner:not(.checkout .warning):not(.profile):not(.order-b2b):not(.checkout-b2b .warning) {
    }

    /* ====================================================================== *
     * Juit Logo                                                              *
     * ====================================================================== */

    /* Logo in nav only visible on mobile */
    .logo-wrapper {
      @apply flex;
      .logo {
        @apply self-center text-3xl lg:text-4xl font-headline whitespace-nowrap;
        @apply pr-6 -mr-6;
      }
    }

    /* ====================================================================== *
     * Nav links                                                              *
     * ====================================================================== */

    /* Nav links wrapper, default look: hidden (mobile) */
    .links-wrapper {
      @apply relative;
      @apply duration-500 transition-all font-bold;
      @apply lg:mt-0 lg:pb-0;
      @apply flex flex-none flex-col order-1 lg:flex-1 lg:flex-row lg:order-none;
      @apply w-full lg:w-auto max-h-0 lg:max-h-full;
      @apply opacity-0 lg:opacity-100 lg:bg-transparent;
    }

    /* Nav links wrapper, open state: visible (mobile) */
    &.open .links-wrapper {
      @apply opacity-100;
      @apply max-h-screen;
      @apply tablet:border-t tablet:border-gray-300;
    }

    /* Nav links ul, default look */
    .links-wrapper .router-links {
      @apply py-2 lg:py-0 lg:px-6 flex flex-col lg:flex-row lg:flex-grow uppercase tablet:bg-white tablet:text-black;

      li {
        @apply h-full;
        @apply flex;
        @apply text-sm lg:text-base;
      }
    }

    /* Single nav link, default look */
    .links-wrapper .router-links a {
      @apply w-full lg:w-auto;
      @apply px-0 py-2 lg:py-5 lg:px-3;
      @apply pointer-events-none lg:pointer-events-auto;
      @apply hover:opacity-100 hover:text-current;
      @apply transition-opacity;
      @apply self-center;
    }

    .links-wrapper .router-links.call-us {
      @apply py-1 lg:py-0 flex-grow-0 pr-2 order-last lg:order-none;
      @apply border-t border-gray-300 lg:border-none;
      a {
        @apply w-auto;
        &.text {
          @apply pr-0 mr-2 lg:-mr-1.5;
        }
        &.juit-link {
          @apply w-auto relative;
          &:after {
            @apply w-1.5 h-1.5 bg-black absolute -right-2 top-1/2 transform -translate-y-1/2 hidden lg:block;
            content: '';
          }
        }
      }
    }

    /* Single nav link, open state (mobile) */
    &.open .links-wrapper .router-links a {
      @apply pointer-events-auto;
    }

    /* Hover-dropdown links (login & language) wrapper, default look */
    .links-wrapper .dropdown-links-wrapper {
      @apply w-full lg:max-h-12 lg:w-auto uppercase;
      @apply self-center;
      @apply duration-500;
      @apply flex-row flex lg:block;
      @apply border-t border-gray-300 lg:border-0;
      @apply tablet:bg-white tablet:text-black;
    }

    /* Hover-dropdown links list, default look */
    .links-wrapper .dropdown-links-wrapper ul {
      @apply hover:max-h-36 lg:max-h-12 lg:hover:pb-4;
      @apply py-1 lg:py-0;
      @apply flex lg:flex-row lg:grid;
      @apply w-full lg:w-auto;
      @apply transition-all duration-500;
      @apply overflow-hidden;
      @apply cursor-pointer;

      li {
        @apply py-2 lg:py-3;
        @apply justify-self-end;
        @apply bg-white;
        @apply flex;
        &.in-dropdown {
          box-shadow: 0px 7px 4px -5px rgb(0 0 0 / 15%);
          @apply lg:pt-2 order-1 tablet:shadow-none;
        }
      }
    }

    /* Single hover-dropdown link, default look */
    .links-wrapper .dropdown-links-wrapper .dropdown-link {
      @apply w-full lg:w-auto;
      @apply py-0 lg:px-3.5;
      @apply text-sm lg:text-base;
      @apply self-center;
      &.active {
        @apply relative lg:pl-5 tablet:underline;
        &:before {
          @apply w-1.5 h-1.5 bg-black absolute -right-1 lg:left-0 top-2;
          content: '';
        }
      }
    }


    /* ====================================================================== *
     * (Mobile) Toggler: on click, toggle the nav bar collapse state          *
     * ====================================================================== */

    /* Wrapper, default look */
    .toggle-button {
      @apply ml-4 -mr-2;
      @apply origin-right transform scale-80 lg:scale-100;
      @apply cursor-pointer;
      @apply flex flex-col self-center justify-around;
      @apply h-12 w-12 py-1.5;
      @apply lg:hidden;
      @apply text-white;

      /* Icon, default look: 3 lines */
      span {
        @apply block transform self-center w-8 h-0.5 transition-all duration-300 rounded bg-black;
      }
    }

    /* Icon, open state: animate into a 'X' shape */
    &.open {
      .toggle-button {
        span:nth-child(1) {
          @apply -rotate-45 translate-x-2 translate-y-0.5 scale-x-45;
        }
        span:nth-child(2) {
          @apply rotate-45 scale-x-120 rounded;
        }
        span:nth-child(3) {
          @apply -rotate-45 -translate-x-2 -translate-y-1.5 scale-x-55;
        }
      }
    }

    /* ====================================================================== *
     * (Mobile) Header-nav-close: on click, closes the collapsed nav bar      *
     * ====================================================================== */

    /* Default look: hidden */
    .header-nav-close {
      @apply fixed w-full h-screen z-0;
      @apply hidden;
    }

    /* Open state: not hidden, displayed as block */
    &.open .header-nav-close {
      @apply block;
    }
  }

  /* ====================================================================== *
   * When on checkout page                                                   *
   * ====================================================================== */
   nav.checkout .links-wrapper .dropdown-links-wrapper.disabled {
    @apply hidden lg:block relative pointer-events-none;
    &:before {
      content: '';
      @apply absolute;
      @apply w-full h-full bg-white;
      @apply z-10 -right-2;
    }
  }

  /* ====================================================================== *
   * When on profile / order-b2b page                                       *
   * ====================================================================== */
  nav.profile .main-nav {
    @apply shadow-none;
  }
  nav.order-b2b .main-nav {
    @apply md:shadow-none;
  }
</style>
