<template>
  <div v-if="show_cart_error" class="fixed w-screen h-screen top-0 left-0 z-60" @click="show_cart_error = false" />
  <div class="cart md:w-[336px] lg:w-[388px] xl-order:w-[428px] xxl:w-[510px]" :class="[{ active: cart_open }, { b2b }, { show_cart_error }]">
    <div class="cart-overlay" @click="cart_open = false" />
    <div class="bar">
      <div class="container">
        <div class="box-info" @click="cart_open = !cart_open">
          <!-- <juit-submitting :visible="cart_busy.length" :large="false" /> -->
          <div class="flex flex-col">
            <span class="box-name">
              <box-icon class="w-5 mr-1 self-center" />
            </span>
            <span v-if="cart.serverCart?.box?.price_per_dish" class="text-xs font-normal mt-1">{{ $t('boxes.per-dish', { price: $currency(b2b ? cart.serverCart.box.price_per_dish_net || 0 : cart.serverCart.box.price_per_dish || 0)}) }}</span>
          </div>
          <div class="cart-count">
            <span>{{ cart.total }}/{{ cart.total > box_selected ? cart.total : box_selected }}</span>
          </div>
          <div class="flex flex-col text-right">
            <span v-if="b2b || total_displayed" class="text-base">{{ $currency(total_displayed) }}</span>
            <a :href="`/${$i18n.locale}/faq?target=delivery-0`" target="_blank" class="text-xs font-normal text-black hover:underline">
              {{ $t('order.shipping_cost_info') }}
            </a>
          </div>
          <progress-bar :thresholds="box_options" :current="cart.total" :goal="box_selected" />
        </div>
        <div class="w-full">
          <p v-if="box_selected > cart.total" class="add-more">
            {{ $t('boxes.more-togo', { remaining: box_selected - cart.total }) }}
          </p>
          <juit-button class="cart-action" :disabled="!mobile && cart.total < box_selected" @click="cartAction()">
            {{ proceed_text }}
          </juit-button>
        </div>
      </div>
    </div>
    <div class="cart-content w-full">
      <div class="container">
        <div class="cart-top">
          <span class="close" @click="cart_open = false"><arrow /></span>
          <span v-if="box_options && !b2b" class="choose">{{ $t( 'boxes.choose-box-size' ) }}</span>
          <div v-if="box_options && !b2b" class="box-options">
            <span
              v-for="(box_option, index) in box_options"
              :key="index"
              :class="[box_option === cart.box ? 'selected' : '', cart.total > box_option ? 'disabled' : '']"
              @click="changeBox(box_option)"
            >
              {{ box_option }}
            </span>
          </div>
        </div>
        <div v-if="!cart.total" class="flex flex-col">
          <span class="text-center mx-auto text-xl pt-3">{{ $t( 'boxes.empty-cart' ) }}</span>
          <juit-button class="mx-auto mt-2 mb-4 md:!hidden" @click="cart_open = false">
            {{ $t( 'boxes.got-it' ) }}
          </juit-button>
        </div>
        <div class="wrapper" :class="scroll_state">
          <div id="side-cart" class="cards no-scrollbar">
            <div v-for="product in cart.dishes" :key="product.ean" class="card">
              <div class="image-wrapper relative">
                <juit-image
                  ratio="1x1"
                  :alt="product.title"
                  :src="product.image"
                />
                <div v-if="product.specialTag" class="absolute top-0 left-0">
                  <juit-badge :class="[ product.specialTag, 'tiny small-icon min-h-5']">
                    <juit-tag-icon :cat="product.specialTag" class="mx-1" />
                    <span v-if="product.specialTag === 'XL Portion'">XL</span>
                    <span v-if="product.specialDish">{{ $t('tags.special-only-price', { value: b2b ? (product.specialDish / 1.07).toFixed(2) : product.specialDish }) }}</span>
                  </juit-badge>
                </div>
              </div>
              <div class="dish-title flex flex-col">
                <span class="whitespace-nowrap text-ellipsis overflow-hidden">{{ product.title }}</span>
              </div>
              <p class="dish-quantity">
                {{ product.quantity }}
              </p>
              <div class="add-remove font-mono">
                <span :class="{ disabled: cart.total === max_box }" @click="cart.addDish(product.ean, 1, b2b)">+</span>
                <span @click="cart.removeDish(product.ean, b2b)">–</span>
              </div>
            </div>
          </div>
          <div class="cart-err-msg">
            <span>
              <p class="mb-1.5">{{ $t('notification.cart-stock-error') }}</p>
              <p v-for="(error, index) in stock_errors" :key="index">
                {{ $t('notification.cart-stock-error-single-detail', { title: getTitle((error as any).ean), number: (error as any).available}) }}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { analyticsEvent, analyticsOrderItems } from '../../analytics'
  import { defineComponent, PropType } from 'vue'
  import { arrow } from '../../assets/async'
  import { Dish } from '../../content'
  import { reactiveCart, boxOptions, boxOptionsB2B, ReactiveCart, boxPriceData, boxPriceDataB2B, b2bMax, boxIds } from '../../init/reactive-order'
  import { box as boxIcon } from '../../assets/async'
  import progressBar from '../../widgets/juit-progress-bar.vue'
  import juitBadge from '../../widgets/juit-badge.vue'
  import juitTagIcon from '../../widgets/juit-tag-icon'
  import { cartBusy } from '../../init/state'

  export interface CartProduct {
    title: string,
    image: string,
    quantity: number,
    ean: string,
    slug: string,
  }

  export default defineComponent({
    components: {
      arrow,
      boxIcon,
      progressBar,
      juitBadge,
      juitTagIcon,
    },
    props: {
      products: {
        type: Array as PropType<Dish[]>,
        required: true,
      },
      b2b: {
        type: Boolean,
        default: false,
      },
    },
    data: (instance) => ({
      cart_open: false,
      scroll_state: 'no-scroll',
      mobile: Boolean(window.innerWidth < 1024),
      box_price_data: instance.b2b ? boxPriceDataB2B : boxPriceData,
      show_cart_error: false,
    }),
    computed: {
      box_options() {
        return this.b2b ? boxOptionsB2B : boxOptions.filter((size) => size !== 8 && size !== 12)
      },
      cart_busy() {
        return cartBusy.value
      },
      stock_errors() {
        return this.cart.serverCart?.errors?.filter((err) => err.reason === 'out_of_stock')
      },
      max_box() {
        return this.b2b ? b2bMax : this.box_options ? this.box_options[this.box_options.length - 1] : 0
      },
      cart(): ReactiveCart {
        return reactiveCart
      },
      box_selected() {
        return reactiveCart.box
      },
      total_quantity() {
        return reactiveCart.total
      },
      total_displayed() {
        const serverCart = this.cart.serverCart
        if (!serverCart || !serverCart.box || !serverCart.summary) return 0
        if (this.b2b) return serverCart?.box?.size >= serverCart?.summary.quantity ? serverCart?.box?.total_net : serverCart?.summary.total_net
        else return serverCart?.box?.size > serverCart?.summary.quantity ? serverCart?.box?.total : serverCart?.summary.total
      },
      dish_count_for_total_price() {
        return (this.b2b && (this.total_quantity > this.box_selected)) ? this.total_quantity : this.box_selected
      },

      /* ========================================================================== *
       * Text to display on CTA button                                              *
       * -------------------------------------------------------------------------- */
      proceed_text: function() {
        if (!this.box_selected) return
        if (!this.mobile || (this.cart_open && this.cart.total === this.box_selected)) return this.$t('order.checkout')
        else if (this.cart_open) return this.$t('boxes.more-togo', { remaining: this.box_selected - this.cart.total })
        else return this.$t('boxes.cart')
      },
    },

    watch: {
      cart_open(open) {
        if (open) {
          globalThis.document.getElementsByTagName('html')[0].classList.add('cart-open')
          analyticsEvent('view_cart', { items: analyticsOrderItems(this.cart.dishes) })
        } else {
          globalThis.document.getElementsByTagName('html')[0].classList.remove('cart-open')
          // TODO: maybe fire an extra GA event when someone closes the cart?
          // In Universal Analytics we had `select_more_items`
        }
      },

      stock_errors: {
        handler(errors) {
          this.show_cart_error = errors?.length
        },
        deep: true,
      },

      total: {
        handler() {
          this.$nextTick(() => this.onScrollY())
        },
        immediate: true,
      },
    },

    mounted() {
      document.querySelector('#side-cart')?.addEventListener('scroll', this.onScrollY)
      window.addEventListener('resize', () => {
        this.mobile = Boolean(window.innerWidth < 1024)
        this.onScrollY()
      })
    },
    unmounted() {
      document.querySelector('#side-cart')?.removeEventListener('scroll', this.onScrollY)
      window.removeEventListener('resize', () => {
        this.mobile = Boolean(window.innerWidth < 1024)
        this.onScrollY()
      })
    },


    methods: {
      /* ========================================================================== *
       * Add and remove product from cart                                           *
       * -------------------------------------------------------------------------- */
      cartAction() {
        if (!this.cart_open && this.mobile) return this.cart_open = true
        if (this.cart.total < this.box_selected || (!this.b2b && this.cart.total !== this.box_selected)) this.cart_open = false
        else {
          globalThis.document.getElementsByTagName('html')[0].classList.remove('cart-open')
          this.$router.push( this.b2b ? '/business/checkout' : 'checkout')

          // GA Event: checkout step 2
          analyticsEvent('begin_checkout', { items: analyticsOrderItems(this.cart.dishes) })
        }
      },

      changeBox(size: number) {
        this.cart.box = size
        this.cart.boxId = boxIds[boxOptions.indexOf(size)]
        this.cart.updateServerCart()
      },

      /* ========================================================================== *
       * Analytics for add/remove                                                   *
       * -------------------------------------------------------------------------- */
      analyticsAddRemove(product: Dish, add: boolean) {
        analyticsEvent(add ? 'add_to_cart' : 'remove_from_cart', {
          items: [ {
            item_name: product.title,
            item_id: product.ean,
            quantity: 1,
          } ],
        })
      },

      /* ========================================================================== *
       * Scrolling within the cart                                                  *
       * -------------------------------------------------------------------------- */
      onScrollY() {
        const roller = document.querySelector('#side-cart') as HTMLElement
        if (!roller) return
        if (roller.scrollHeight === roller.offsetHeight) this.scroll_state = 'no-scroll'
        else if (roller.scrollTop < 5) this.scroll_state = 'at-top'
        else if (roller.scrollHeight - roller.offsetHeight - roller.scrollTop < 5) this.scroll_state = 'at-bottom'
        else this.scroll_state = 'scrolling'
      },

      getTitle(ean: string) {
        return (this.cart.dishes.find((dish) => dish.ean === ean))?.title
      },
    },
  })
</script>

<style scoped lang="pcss">
  .cart {
    @apply md:fixed md:pt-28 lg:pt-32 right-0;
    @apply top-2 lg:top-5 xl:top-8 xxl:pt-36 xxl:top-10;
    left: calc(50vw + 770px);
    @apply desktop-nm:left-auto;
    @apply md:h-full md:overflow-hidden;
    &.show_cart_error {
      @apply pointer-events-none;
    }
    .cart-overlay {
      @apply z-50 pointer-events-none opacity-0 transition duration-300;
      @apply fixed bg-black w-full h-screen top-0 left-0 md:hidden;
    }
    .add-more {
      @apply mobile:hidden text-base font-normal text-center mb-3;
    }
    .cart-action {
      padding-top: 5px;
      padding-bottom: 2px;
      @apply sm:pt-1 sm:pb-0.5;
      @apply md:!w-full;
    }
    .bar {
      @apply w-full left-0 bottom-0;
      @apply fixed h-32 md:absolute md:h-full md:z-0;
      @apply bg-white z-60 md:z-0 transition-all;
      box-shadow: 0px -2px 6px rgba(0,0,0,0.08);
      @apply md:shadow-none;
      > .container {
        @apply font-bold text-2xl flex-col h-full py-0 flex justify-end md:justify-between;
        @apply md:pl-2 md:pb-7 lg:pb-10 xl:pb-14 xxl:pb-16;
        > * {
          @apply self-center;
        }
        button {
          @apply relative z-20 mb-4 w-full md:w-auto md:mb-0;
        }
        .box-info {
          @apply flex flex-row pb-2.5 md:pb-0 md:mt-24 pt-0.5 lg:pt-3 xxl:pt-5 justify-between w-full relative flex-wrap;
          .box-name {
            @apply flex flex-row;
          }
          .progress-bar {
            @apply relative;
            @apply mt-1 md:top-3 md:mt-12 lg:top-5 xxl:top-7;
          }
        }
        .cart-count {
          @apply cursor-pointer absolute transform -translate-x-1/2 left-1/2 mobile:top-1.5;
          @apply md:flex-1;
        }
      }
    }
    .cart-content {
      margin-top: -1px;
      @apply fixed md:relative z-50 mobile:-bottom-24 left-0 transition-all;
      @apply md:left-auto md:right-0 md:z-10 md:top-24 xxl:-mt-0.5 mobile:mt-0 md:pt-6;
      .container {
        @apply bg-ash mb-2 md:pl-2;
        .cart-top {
          @apply md:relative;
          .close {
            @apply cursor-pointer absolute h-7 sm:h-9 transform -top-5 sm:-top-6 w-20 sm:w-36 left-1/2 -translate-x-1/2 bg-ash;
            @apply md:hidden;
            svg {
              @apply absolute w-3.5 transform text-lg top-1.5 left-1/2 -translate-x-1/2 rotate-90;
            }
          }
          .choose {
            @apply block text-center pt-2 md:hidden;
          }
          .box-options {
            @apply mt-2 flex w-full mx-auto max-w-96 md:max-w-none relative;
            @apply border border-black md:-mt-20;
            &.cart_busy {
              @apply opacity-50 pointer-events-none;
            }
            > * {
              @apply text-black w-1/4 text-center py-1 md:py-0.5;
              @apply border-black border-l;
              @apply cursor-pointer;
              &:first-child {
                @apply border-none;
              }
              &.selected {
                @apply bg-black text-white;
              }
              &.disabled {
                @apply text-gray-400;
                @apply pointer-events-none;
              }
            }
          }
        }
        .wrapper {
          @apply relative pt-2 md:pt-0;
          &:before {
            content: '';
            @apply hidden absolute md:-top-1 w-full bg-gradient-to-b from-white to-transparent h-8 z-10;
          }
          &:after {
            content: '';
            @apply hidden absolute bottom-0 w-full bg-gradient-to-b from-transparent to-white h-8 z-10;
          }
          &.at-top {
            &:after {
              @apply block;
            }
          }
          &.at-bottom {
            &:before {
              @apply block;
            }
          }
          &.scrolling {
            &:before, &:after {
              @apply block;
            }
          }
        }
        .cart-err-msg {
          @apply w-full absolute bg-white z-60 opacity-0 transition-opacity pointer-events-none;
          @apply -mb-6 bottom-60 md:mb-0 md:-bottom-6;
          span {
            @apply p-3.5 block bg-red/20 text-sm shadow-md;
          }
        }
        .cards {
          @apply overflow-scroll pb-0 opacity-0 -mt-32 -mx-4 px-4 relative;
          @apply transition-all duration-300;
          @apply md:-mt-1 md:opacity-100 md:pb-0 md:pl-0 md:ml-0;
          @apply mobile:max-h-14 md:h-md-cart lg:h-lg-cart xl:h-xl-cart xxl:h-xxl-cart;
        }
        .card {
          @apply flex flex-nowrap bg-white;
          @apply h-20 shadow-juit mt-2 md:h-14 xl-order:h-20 md:mt-0 mb-2;
          .image-wrapper {
            @apply w-20 h-20 flex-shrink-0 md:w-14 md:h-14 xl-order:w-20 xl-order:h-20;
            @apply mr-3;
          }
          > * {
            @apply self-center;
          }
          .dish-title {
            @apply flex-grow overflow-hidden sm:max-w-fit md:text-sm xxl:text-base;
          }
          .dish-quantity {
            @apply flex-grow text-right px-3 sm:pr-6 font-bold text-lg sm:text-2xl md:text-lg md:pr-3 xl-order:pr-4;
          }
          .add-remove {
            @apply flex flex-col;
            @apply border border-black mr-2 h-16 md:h-14 xl-order:h-16 min-w-8 md:min-w-7 xl-order:min-w-8 md:mr-0 xl-order:mr-2;
            span {
              @apply text-black h-1/2 block text-center text-xl leading-normal md:leading-tight xl-order:leading-normal cursor-pointer;
              &.disabled {
                @apply text-gray-400 pointer-events-none;
              }
              &:last-child {
                @apply border-t border-black;
              }
            }
          }
        }
      }
    }
    &.show_cart_error .cart-content .cart-err-msg {
      @apply opacity-100;
    }
    &.b2b {
      .bar > .container {

        .box-info {
          @apply md:mt-24 md:pt-8 lg:pt-9 xl:pt-6 xxl:pt-4;
          .progress-bar {
            @apply md:hidden;
          }
        }
      }
      .cart-content {
        @apply md:pt-9 lg:pt-6 md:top-12 xl:top-9 xxl:top-4;
        .cards {
          @apply md:h-md-cart-b2b lg:h-lg-cart-b2b xl:h-xl-cart-b2b xxl:h-xxl-cart-b2b;
        }
      }
    }
    &.active {
      .cart-overlay {
        @apply pointer-events-auto opacity-60;
      }
      .cart-content .container .cart-err-msg {
        @apply bottom-36;
      }
      .cart-content {
        @apply mobile:bottom-0;
        .cards {
          @apply mobile:max-h-cart pb-32 md:pb-2 mobile:mt-0 opacity-100;
        }
      }
      .bar {
        > .container .card-icon:before {
          @apply shadow-none;
        }
      }
    }
  }
</style>
