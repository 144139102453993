<!--==================================================================+
| MODULE-COMPONENT: DISH CAROUSEL                                     |
+===================================================================-->

<template>
  <div v-if="module" class="container">
    <h1>{{ module.title }}</h1>
    <rich-text
      v-if="module.description"
      :document="module.description"
      class="subheading"
    />

    <!--==================================================================+
    | DISH FILTERS                                                        |
    +===================================================================-->
    <juit-tags-filter
      v-if="module.list"
      :list="module.list"
      :list-only="module.listOnly"
      :available-only="module.availableOnly"
      :show-filter="module.showFilter"
      @filtered="products = $event"
    />

    <!--==================================================================+
    | CAROUSEL                                                            |
    +===================================================================-->
    <div v-if="module" class="carousel">
      <juit-carousel
        class="dish-carousel solid-arrow mobile-only-small"
        :card-width="cardwidth"
        :dynamic-arrow-height="true"
      >
        <juit-dish-card
          v-for="(product, index) in products"
          :key="index"
          :index="index"
          :product="product"
          @cardwidth="cardwidth = $event"
        />
      </juit-carousel>
    </div>
    <div v-if="module.cta" class="cta">
      <juit-button :to="`/${$i18n.locale}/${module.cta?.slug}`">
        <span class="cta-btn-text">{{ module.cta?.text }}</span>
      </juit-button>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Dish, DishCarousel } from '../content'
  import { nutrition } from '../widgets/juit-nutrition-label.vue'
  import juitTagsFilter from '../widgets/juit-tags-filter.vue'
  import juitCarousel from '../widgets/juit-carousel.vue'
  import dishModal from '../modals/dish.vue'

  export default defineComponent({
    name: 'JuitDishCarousel',
    components: { juitTagsFilter, juitCarousel },
    props: {
      module: {
        type: Object as PropType<DishCarousel>,
        required: true,
      },
    },

    data: () => ({
      products: [] as Dish[],
      nutrition: nutrition,
      cardwidth: 0,
    }),

    methods: {
      // Trigger product modal instead of following product link
      productClicked(product: Dish) {
        const url = `/${this.$i18n.locale}/dishes/${product.slug}`
        this.$createModal(dishModal as any, { product, url }).then((url) => {
          if (url) this.$router.push(url)
        })
      },
    },
  })
</script>

<style scoped lang="pcss">
.carousel {
  @apply relative;
  .dish-carousel {
    :deep() .roller-wrapper {
      @apply -mx-1;
    }
    :deep() .arrow-wrapper {
      @apply -left-4 sm:-left-6 md:-left-8 lg:-left-16;
      &.right {
        @apply left-auto -right-4 sm:-right-6 md:-right-8 lg:-right-16;
      }
    }
    :deep() .tile {
      @apply mobile:w-1/2 mobile-sm:w-full;
    }
  }
}
.dishcarousel {
  .cta {
    @apply mx-auto mobile-sm:w-full;
    a {
      @apply w-full text-center;
    }
  }
}

.dashboard {
  .cta {
    @apply md:absolute -top-0.5 lg:top-0 right-0 text-center;
    :deep() .cta-btn-text {
      @apply lg:text-4xl;
    }
  }
}

</style>
