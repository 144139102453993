<!--==================================================================+
| COMPONENT: DISH PREFERENCES                                         |
+===================================================================-->

<template>
  <div class="preferences" :class="{ active }">
    <div class="text-top">
      <h1>{{ $t( 'preferences.headline') }}</h1>
      <p class="subheading font-semibold uppercase">
        {{ $t( 'preferences.subheading') }}
      </p>
      <p class="text-xs mt-1 sm:text-sm md:text-base">
        {{ $t( 'preferences.description') }}
      </p>
    </div>

    <!--==================================================================+
    | CHOOSE PREFERENCE                                                   |
    +===================================================================-->
    <div
      class="boxes-list-wrapper"
      :class="{ mobile_open: !list_focused }"
    >
      <div class="tiled">
        <div
          v-for="(pref, $index) in preferences"
          :key="$index"
          class="tile"
          :class=" { selected: pref === pref_selected }"
          @click="choosePref(pref)"
        >
          <div class="box-text">
            <div class="icon-wrapper">
              <component :is="`pref_${pref}`" />
            </div>
            <span class="box-title">{{ $t( `preferences.${pref}`) }}</span>
          </div>
        </div>
      </div>
    </div>

    <!--==================================================================+
    | CONTINUE TO BOX SELECTION                                           |
    +===================================================================-->
    <div
      class="to-boxes"
      :class="{ active: list_item_selected }"
    >
      <juit-button
        :disabled="!list_item_selected"
        class="to-boxes-button"
        @click="$emit('to_boxes')"
      >
        <span class="cta-btn-text">{{ $t( 'preferences.continue' ) }}</span>
      </juit-button>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { nutriPref } from '../init/state'
  import { DishSortingKey } from '../widgets/juit-tags-filter.vue'
  import {
    pref_calories,
    pref_default,
    pref_protein,
  } from '../assets/async'
  import { analyticsEvent } from '../analytics'

  export const preferences = [ 'calories', 'protein', 'default' ] as Array<DishSortingKey | '' | 'default'>

  export default defineComponent({
    components: {
      pref_calories,
      pref_default,
      pref_protein,
    },
    props: {
      active: {
        type: Boolean,
        default: true,
      },
    },
    emits: [ 'to_boxes' ],
    data: () => ({
      preferences,
      pref_selected: '' as DishSortingKey | '' | 'default',
      list_focused: false,
      list_item_selected: false,
    }),

    watch: {
      pref_selected(newPref, oldPref) {
        if (newPref !== oldPref) {
          nutriPref.value = this.pref_selected
          localStorage.setItem('jn:nutri_pref', this.pref_selected)
        }
      },
    },

    mounted() {
      const stored_pref = localStorage.getItem('jn:nutri_pref') as DishSortingKey | '' | 'default'
      if (stored_pref && this.preferences.includes(stored_pref)) this.choosePref(stored_pref)
    },

    methods: {
      /* ========================================================================== *
       * Select a box                                                               *
       * -------------------------------------------------------------------------- */
      choosePref(pref: DishSortingKey | '' | 'default') {
        analyticsEvent('choose_preference', { preference: pref && pref !== 'default' ? pref : 'popular' })
        this.list_focused = !this.list_focused
        this.pref_selected = pref
        this.list_item_selected = true
      },
    },
  })
</script>

<style scoped lang="pcss">
.preferences {
  @apply max-h-0 opacity-0 transition-all overflow-hidden duration-100;
  &.active {
    @apply max-h-[60rem] opacity-100 relative;
  }
}
.boxes-list-wrapper {
  &.mobile_open .tiled .tile {
    @apply mb-2 lg:mb-0 mobile:flex-row;
    .box-text {
      @apply opacity-100 max-h-24 sm:max-h-56 md:max-h-80 mobile-xs:py-4;
    }
    &.popular, &.bestvalue {
      @apply border-2;
    }
  }
  /* Desktop */
  .tiled {
    @apply mt-4 mb-0 mx-0 sm:-mx-1 justify-center;
    /* Single Box */
    .tile {
      @apply relative bg-gray-200 transition-all duration-100;
      @apply overflow-hidden;
      @apply w-full md:w-auto;
      @apply flex flex-row md:flex-col md:flex-margin-1/2 lg:flex-1 md:max-w-[50%];
      @apply p-0 sm:mb-2 lg:mb-0 mx-auto sm:mx-1;
      @apply cursor-pointer;
      /* update or remove this style after we have 6+ box options on prod */
      &:nth-child(5):last-child {
        @apply mobile:flex-grow-0 sm:flex-card-1/2 lg:flex-1;
      }

      /* Text content */
      .box-text {
        @apply flex;
        @apply opacity-0 sm:opacity-100 max-h-0 sm:max-h-56 md:max-h-80 transition-all duration-200;
        @apply text-center;
        @apply justify-center self-center;
        @apply pl-6 md:pl-0 sm:py-6 md:py-8;
        .icon-wrapper {
          @apply w-10 h-8 md:h-10 md:w-auto mr-4 self-center;
          svg {
            @apply h-full;
          }
        }
        .box-title {
          @apply px-0 sm:mt-2 xl:mt-3 text-left sm:text-center;
          @apply whitespace-nowrap font-bold text-3xl xl:text-5xl xl:mb-1 font-headline uppercase;
        }
      }

      /* Single Box, selected */
      &.selected {
        @apply bg-black text-white;
        .box-text {
          @apply opacity-100 max-h-24 sm:max-h-56 md:max-h-80 mobile-xs:py-4;
        }

        &.popular, &.bestvalue {
          @apply border-2;
        }
      }
    }
  }
}

/* CTA to box selection */
.to-boxes {
  @apply md:max-h-0 mx-auto;
  @apply flex flex-col;
  @apply overflow-hidden transition-all delay-100 duration-300;
  @apply md:max-h-0 md:opacity-0;
  @apply md:w-1/2 max-w-md;
  &.active {
    @apply opacity-100 mt-3 sm:-mt-1 lg:mt-3 max-h-60 border-black;
    @apply mx-auto mobile-sm:w-full;
  }
  .to-boxes-button {
    @apply m-0 sm:mt-3 sm:mx-auto;
  }
}
.accordion:not(.mobile_open) .accordion-header {
  @apply max-h-max;
}
</style>
