<template>
  <div class="payments w-full no-scrollbar">
    <div class="subheading">
      {{ $t('checkout.choose-payment') }}
    </div>
    <div class="options" :class="{ b2b }">
      <!--==================================================================+
      | PAYPAL                                                              |
      +===================================================================-->
      <div class="option-wrapper" @click="selectPayment('paypal')">
        <div class="option" :class="{ active: payment === 'paypal'}">
          <label class="radio-label">
            <input v-model="payment" type="radio" value="paypal">
            <div class="radio-checked" />
          </label>
          <div class="flex md:h-12">
            <paypal class="ml-1 md:mx-auto" />
          </div>
          <div class="text">
            {{ $t('checkout.paypal') }}
          </div>
        </div>
        <div v-if="mandates?.paypal" class="mandates">
          <span class="saved-mandate-text">{{ $t('checkout.saved-mandate-paypal') }}</span>
          <div v-for="(mandate, index) in mandates.paypal" :key="index" class="mandate">
            <label class="radio-label" @click="new_mandate = undefined">
              <input
                v-model="selected_mandate"
                type="radio"
                :value="mandate"
                :checked="mandate.uuid === defaultMandate?.uuid"
              >
              <div class="radio-checked" />
              <span>{{ mandateInfo(mandate) }}</span>
              <span class="delete-button" @click.stop.prevent="deleteConfirm(mandate)">{{ $t('checkout.delete') }}</span>
            </label>
          </div>
          <div v-if="mandates?.paypal?.length" class="mandate">
            <label class="radio-label" @click="selected_mandate = undefined">
              <input
                v-model="new_mandate"
                type="radio"
                value="paypal"
                :checked="payment === 'paypal' && new_mandate === 'paypal'"
              >
              <div class="radio-checked" />
              <span>{{ $t('checkout.new-mandate-paypal') }}</span>
            </label>
          </div>
        </div>
      </div>

      <!--==================================================================+
      | CREDIT CARD                                                         |
      +===================================================================-->
      <div class="option-wrapper" @click="selectPayment('creditcard')">
        <div class="option" :class="{ active: payment === 'creditcard'}">
          <label class="radio-label">
            <input v-model="payment" type="radio" value="creditcard">
            <div class="radio-checked" />
          </label>
          <div class="flex md:h-12">
            <creditcard class="ml-7 md:mx-auto" />
          </div>
          <div class="text">
            {{ $t('checkout.creditcard') }}&nbsp;/ Google Pay
          </div>
        </div>
        <div v-if="mandates?.creditcard" class="mandates">
          <span class="saved-mandate-text">{{ $t('checkout.saved-mandate-creditcard') }}</span>
          <div v-for="(mandate, index) in mandates.creditcard" :key="index" class="mandate">
            <label class="radio-label" @click="new_mandate = undefined">
              <input
                v-model="selected_mandate"
                type="radio"
                :value="mandate"
                :checked="mandate.uuid === defaultMandate?.uuid"
              >
              <div class="radio-checked" />
              <span>{{ mandateInfo(mandate) }}</span>
              <span class="delete-button" @click.stop.prevent="deleteConfirm(mandate)">{{ $t('checkout.delete') }}</span>
            </label>
          </div>
          <div v-if="mandates?.creditcard?.length" class="mandate">
            <label class="radio-label" @click="selected_mandate = undefined">
              <input
                v-model="new_mandate"
                type="radio"
                value="creditcard"
                :checked="payment === 'creditcard' && new_mandate === 'creditcard'"
              >
              <div class="radio-checked" />
              <span>{{ $t('checkout.new-mandate-creditcard') }}</span>
            </label>
          </div>
        </div>
      </div>

      <!--==================================================================+
      | SEPA                                                                |
      +===================================================================-->
      <div v-if="b2b" class="option-wrapper" @click="selectPayment('sepa_debit')">
        <div class="option" :class="{ active: payment === 'sepa_debit'}">
          <label class="radio-label">
            <input v-model="payment" type="radio" value="sepa_debit">
            <div class="radio-checked" />
          </label>
          <div class="flex md:h-12">
            <sepa class="w-16 pl-2 ml-6 md:mx-auto md:p-0 md:w-24" />
          </div>
          <div class="text">
            {{ $t('checkout.sepa_debit') }}
          </div>
        </div>
        <div v-if="mandates?.sepa_debit" class="mandates">
          <span class="saved-mandate-text">{{ $t('checkout.saved-mandate-sepa') }}</span>
          <div v-for="(mandate, index) in mandates.sepa_debit" :key="index" class="mandate">
            <label class="radio-label" @click="new_mandate = undefined">
              <input
                v-model="selected_mandate"
                type="radio"
                :value="mandate"
                :checked="mandate.uuid === defaultMandate?.uuid"
              >
              <div class="radio-checked" />
              <span>{{ mandateInfo(mandate) }}</span>
              <span class="delete-button" @click.stop.prevent="deleteConfirm(mandate)">{{ $t('checkout.delete') }}</span>
            </label>
          </div>
          <div v-if="mandates?.creditcard?.length" class="mandate">
            <label class="radio-label" @click="selected_mandate = undefined">
              <input
                v-model="new_mandate"
                type="radio"
                value="sepa_debit"
                :checked="payment === 'sepa_debit' && new_mandate === 'sepa_debit'"
              >
              <div class="radio-checked" />
              <span>{{ $t('checkout.new-mandate-sepa') }}</span>
            </label>
          </div>
        </div>
      </div>


      <!--==================================================================+
      | STRIPE APPLE PAY                                                    |
      +===================================================================-->
      <div v-if="applepay" class="option-wrapper" @click="selectPayment('applepay')">
        <div class="option" :class="{ active: payment === 'applepay'}">
          <label class="radio-label">
            <input v-model="payment" type="radio" value="applepay">
            <div class="radio-checked" />
          </label>
          <div class="flex md:h-12">
            <applepay class="applepay ml-7 md:mx-auto -mt-1.5 md:-mt-1" />
          </div>
          <div class="text">
            {{ $t('checkout.applepay') }}
          </div>
        </div>
      </div>

      <!--==================================================================+
      | KLARNA                                                              |
      +===================================================================-->
      <div class="option-wrapper" @click="selectPayment('klarna')">
        <div class="option" :class="{ active: payment === 'klarna'}">
          <label class="radio-label">
            <input v-model="payment" type="radio" value="klarna">
            <div class="radio-checked" />
          </label>
          <div class="flex md:h-12">
            <klarna class="w-24 ml-2 pl-2 md:mx-auto" />
          </div>
          <div class="text">
            {{ $t('checkout.klarna') }}
          </div>
        </div>
      </div>

      <!--==================================================================+
      | INVOICE                                                             |
      +===================================================================-->
      <div v-if="b2b && available_methods.includes('invoice')" class="option-wrapper" @click="selectPayment('invoice')">
        <div class="option" :class="{ active: payment === 'invoice'}">
          <label class="radio-label">
            <input v-model="payment" type="radio" value="invoice">
            <div class="radio-checked" />
          </label>
          <div class="flex md:h-12">
            <invoice class="w-16 md:w-24 ml-[26px] pl-[6px] md:mx-auto md:px-0.5 md:pb-0.5" />
          </div>
          <div class="text">
            {{ $t('checkout.nordfrost-payment-info') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent } from 'vue'
  import { reactiveCheckout, reactiveCart } from '../../init/reactive-order'
  import { analyticsEvent, analyticsOrderItems } from '../../analytics'
  import {
    payment_sepa as sepa,
    payment_paypal as paypal,
    payment_klarna as klarna,
    payment_applepay as applepay,
    payment_invoice as invoice,
    payment_creditcard_gpay as creditcard,
  } from '../../assets/async'
  import { CreditcardMandate, Mandate, Method, PaypalMandate, SepaMandate } from '@juitnow/api-payments'
  import confirmModal from '../../modals/confirm-modal.vue'
  import { client } from '../../init/client'
  import { logError } from '../../init/log'
  import { checkoutBusy, userMandates, userPaymentMethods } from '../../init/state'
  import { applepay_support } from '../../main'

  export default defineComponent({
    components: {
      paypal,
      creditcard,
      sepa,
      klarna,
      invoice,
      applepay,
    },
    props: {
      mandates: {
        type: Object as PropType<Record<string, Mandate[]>> | undefined,
        default: undefined,
      },
      defaultMandate: {
        type: Object as PropType<Mandate> | undefined,
        default: undefined,
      },
    },
    emits: [ 'active', 'selected' ],

    data: (instance) => ({
      payment: undefined as Method | undefined,
      selected_mandate: undefined as undefined | CreditcardMandate | SepaMandate | PaypalMandate | undefined,
      new_mandate: undefined as Method | undefined,
      b2b: instance.$route.name?.toString().includes('b2b'),
      applepay_support,
    }),

    computed: {
      available_methods() {
        return userPaymentMethods.value
      },
      applepay() {
        return applepay_support
      },
    },

    watch: {
      /* ========================================================================== *
       * In case default mandate changes                                            *
       * -------------------------------------------------------------------------- */
      defaultMandate: {
        handler(value) {
          this.handleMandate(value)
        },
        deep: true,
      },
    },

    async mounted() {
      await this.checkAvailableMethods()
      this.handleMandate(this.defaultMandate)
    },

    methods: {
      /* ========================================================================== *
       * Check all available payment methods of the user                            *
       * -------------------------------------------------------------------------- */
      async checkAvailableMethods() {
        if (userPaymentMethods.value.length) return
        userPaymentMethods.value = await client.payments.methods()
      },

      /* ========================================================================== *
       * Process the mandate and the corresponding payment method                   *
       * -------------------------------------------------------------------------- */
      handleMandate(mandate: Mandate | undefined) {
        this.selected_mandate = mandate ? Object.assign({}, mandate) : undefined
        reactiveCheckout.payment = mandate?.method || undefined
        if (mandate) this.selectPayment(reactiveCheckout.payment)
      },

      /* ========================================================================== *
       * Emit the selected payment method and mandate                               *
       * -------------------------------------------------------------------------- */
      selectPayment(payment: Method | undefined) {
        setTimeout(() => {
          if (!payment) return
          // Clear 'new mandate' and 'selected mandate' when switching method
          if (payment !== this.new_mandate) this.new_mandate = undefined
          if (payment !== this.selected_mandate?.method) this.selected_mandate = undefined
          // Auto select the default/recent mandate when no specific mandate is selected
          const recent_mandate = this.defaultMandate?.method === payment ? this.defaultMandate : !this.mandates ? undefined : this.mandates[`${payment}`]?.length ? this.mandates[`${payment}`][0] : undefined
          if (!this.selected_mandate && !this.new_mandate && payment !== this.payment) this.selected_mandate = recent_mandate
          // Emit payment/mandate info
          this.$emit('selected', payment, this.selected_mandate, this.mandateInfo(this.selected_mandate))
          this.$emit('active')
          this.payment = payment
          if (reactiveCheckout.payment !== payment) {
            reactiveCheckout.payment = payment
            analyticsEvent('add_payment_info', {
              payment_type: this.payment,
              items: analyticsOrderItems(reactiveCart.dishes),
            })
          }
        }, 50)
      },

      async deleteConfirm(mandate: Mandate) {
        const res = await this.$createModal(confirmModal as any, {
          modalContent: {
            title: this.$t('checkout.delete-mandate-confirm'),
            description: this.$t('checkout.delete-mandate-warning'),
            yes: this.$t('checkout.delete-mandate-yes'),
            no: this.$t('checkout.delete-mandate-no'),
          },
        })
        if (res) {
          checkoutBusy.value.push('')
          client.mandates.delete(mandate.uuid).then(async () => {
            if (mandate.uuid === this.selected_mandate?.uuid) this.selected_mandate = undefined
            try {
              userMandates.value = await client.mandates.list({})
            } catch (e) {
              logError(e)
            } finally {
              if (mandate.method === 'creditcard' || mandate.method === 'paypal') this.selectPayment(mandate.method)
              checkoutBusy.value.pop()
            }
          }).catch((e) => {
            logError(e)
            checkoutBusy.value.pop()
          })
        }
      },

      mandateInfo(mandate: Mandate | undefined) {
        if (!mandate) return
        switch (mandate.method) {
          case 'paypal': {
            return mandate.description.email
          }
          case 'sepa_debit': {
            return `**** **** **** ${mandate.description.last4}`
          }
          case 'creditcard': {
            return `**** **** **** ${mandate.description.last4}`
          }
        }
      },
    },

  })
</script>


<style scoped lang="pcss">
  .subheading {
    @apply text-center w-full mb-1.5 md:mb-3 font-semibold;
  }
  .options {
    @apply mx-auto;
    @apply flex flex-col md:flex-row md:flex-wrap justify-center;
    @apply w-full md:w-4/5 lg:w-full;
    &.b2b {
      @apply md:w-full lg:w-full lg:max-w-[60rem] xl-payment:max-w-full;
      .option-wrapper {
        @apply md:w-1/3 lg:w-1/3 xxl:w-1/6 xl-payment:!flex-grow-0;
      }
    }
    .option-wrapper {
      @apply flex-col w-full md:w-1/2 lg:flex-card-2/9 !flex-grow;
      @apply md:max-w-80 md:text-center;
      @apply md:mx-1;
      @apply mb-1 md:mb-2 lg:mb-0;
      .option {
        @apply flex md:flex-col md:mb-2 md:py-2 md:ml-0 md:px-5 md:border-2 md:border-transparent;
        @apply md:bg-ash md:shadow-juit;
        @apply cursor-pointer;
        svg {
          @apply order-2 h-8 md:h-auto;
          &.applepay {
            @apply h-10 md:h-auto;
          }
        }
        &.active, &:hover {
          @apply border-black;
        }
        .text {
          @apply self-center order-1 md:order-3;
          @apply w-full pl-8 md:pt-2 md:pl-0 md:mt-1 md:mb-1 text-sm;
          @apply md:border-t border-gray-300;
          @apply min-w-32;
          @apply hidden md:block;
        }
        .radio-label {
          @apply md:hidden mt-1.5;
          span {
            @apply text-sm md:text-base;
          }
          .radio-checked {
            @apply -ml-4 left-6 top-0;
          }
        }
        &:not(.active) + .mandates .mandate {
          input:checked + .radio-checked:before{
            @apply opacity-0;
          }
        }
      }
      .mandates {
        @apply mt-1 md:mt-2 pb-1 flex-col text-left;
        .saved-mandate-text {
          @apply -mt-1.5 mb-2 ml-8 md:hidden text-sm font-semibold block;
        }
        .radio-label {
          @apply ml-12 pl-2 md:ml-6 md:pl-0 flex justify-between;
          .radio-checked {
            @apply -ml-6 top-1;
          }
          .delete-button {
            @apply text-right underline text-blue;
          }
        }
      }
    }
  }
</style>
