<template>
  <main v-if="page">
    <juit-hero v-if="page.hero" :hero="page.hero" />

    <slot name="before" />

    <section v-for="(module, index) in page.modules" :key="`module_${index}`" :class="module.$type?.toLowerCase()">
      <juit-module :module="module" />
    </section>

    <slot name="default" />

    <section v-if="page.content" class="content">
      <rich-text class="container" :document="page.content" />
    </section>

    <slot name="after" />
  </main>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Page } from '../content'
  import juitHero from '../components/hero.vue'
  import juitModule from '../modules'
  import * as meta from '../init/meta'
  import { locale } from '../init/i18n'

  export default defineComponent({
    name: 'JuitPage',
    components: {
      juitHero,
      juitModule,
    },
    props: {
      page: {
        type: Object as PropType<Page>,
        required: true,
      },
      alternateSlug: {
        type: String,
        required: true,
      },
    },
    watch: {
      page: {
        handler() {
          meta.title.value = this.page.metaTitle || this.page.title
          meta.description.value = this.page.metaDescription
          meta.image.value = this.page.hero ? this.page.hero.image.src : undefined
          this.$updateAlternateSlug(this.alternateSlug, locale.value)
        },
        immediate: true,
        deep: true,
      },
    },
  })
</script>

<style scoped lang="pcss">
  :deep() .content {
    @apply text-left;
    h1 {
      @apply text-2xl md:text-2xl mb-2 font-text font-bold normal-case;
    }
    h2, h3 {
      @apply text-lg md:text-xl mb-2 mt-2 font-text font-bold normal-case;
    }
    * + h1 {
      @apply mt-4;
    }
    h1 + h2 {
      @apply -mt-1;
    }
  }
</style>
