<!--==================================================================+
| WIDGET: INPUT PASSWORD                                              |
+===================================================================-->

<template>
  <div>
    <div class="relative mt-2 flex">
      <input
        :value="password"
        :type="reveal ? 'text' : 'password'"
        class="password"
        autocomplete="password"
        :placeholder="placeholder ? placeholder : $t( 'authentication.password-placeholder' )"
        :class="{ 'valid-false': !valid && password }"
        @input="$emit('update:password', ($event.target as HTMLTextAreaElement)?.value)"
      >
      <div class="reveal-icon" @click="reveal = !reveal">
        <icon-reveal-pw v-if="reveal" class="fill-current self-center centerer" />
        <icon-hide-pw v-else class="fill-current self-center centerer" />
      </div>
    </div>
    <p v-if="valid === false" class="invalid-msg">
      {{ $t( 'authentication.password-invalid' ) }}
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    open_eye as iconRevealPw,
    close_eye as iconHidePw,
  } from '../assets/async'

  export default defineComponent({
    name: 'JuitInputPassword',
    components: {
      iconRevealPw,
      iconHidePw,
    },
    props: {
      password: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      requirement: {
        type: Boolean,
        default: false,
      },
    },
    emits: [ 'update:password', 'update:valid' ],
    data: () => ({
      reveal: false,
    }),
    computed: {
      valid() {
        if (!this.password || this.password.length < 1) return null
        if (this.password.match(/(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}/) != null) return true
        return false
      },
    },
    watch: {
      valid(v) {
        this.$emit('update:valid', v)
      },
    },
  })
</script>
