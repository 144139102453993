<!--==================================================================+
| COMPONENT: RATING-ALERT                                             |
+===================================================================-->

<template>
  <div
    v-if="unrated.length && !hide_rating"
    class="rating-alert-wrapper"
    :class="{ closing }"
  >
    <div class="rating-alert">
      <h2>{{ $t( 'rating.notification-headline' ) }}</h2>
      <span class="subheading">{{ $t( 'rating.notification-subline' ) }}</span>
      <div class="flex">
        <juit-button
          class="button mr-3 xs:mr-4"
          @click="rateNow()"
        >
          {{ $t( 'rating.rate-now' ) }}
        </juit-button>
        <juit-button
          class="button later"
          @click="close()"
        >
          {{ $t( 'rating.later' ) }}
        </juit-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, watch } from 'vue'
  import { client, user } from '../../init/client'
  import { logError } from '../../init/log'
  import { orderDataBusy } from '../../init/state'

  export default defineComponent({
    data: () => ({
      unrated: [] as any[],
      closing: false,
      last_visit_rating: 0,
    }),
    computed: {
      // Hide Rating popup on order flow
      hide_rating() {
        return [ 'checkout', 'order', 'thankyou', 'thankyou-b2b' ].includes(this.$route.name?.toString() || '') || this.$route.path.includes('profile/ratings')
      },
    },

    mounted() {
      watch(user, async (newUser, oldUser) => {
        if (!oldUser && newUser?.uuid) await this.checkUnratedOrder()
      }, { immediate: true })
    },

    methods: {
      async checkUnratedOrder() {
        orderDataBusy.value.push('')
        // Get timestamp of the last visit/ignore of the user
        this.last_visit_rating = parseInt(localStorage.getItem('jn:last_visit_rating') || '0')

        try {
          // Check for unrated
          this.unrated = await client.graphV2.unrated_orders({ last_ignored: this.last_visit_rating }, [ 'slot.date', 'uuid', 'created' ])
        } catch (err) {
          logError(err)
        } finally {
          orderDataBusy.value.pop()
        }
      },

      rateNow() {
        this.close()
        this.$router.push('../profile/ratings')
      },

      // Close the box and update the last-ingored timestamp
      close() {
        this.closing = true
        localStorage?.setItem('jn:last_visit_rating', JSON.stringify(new Date().getTime()))
      },
    },
  })
</script>

<style scoped lang="pcss">
  .rating-alert-wrapper {
    @apply fixed z-40;
    @apply right-0 xs:right-4 top-20 sm:right-6 lg:right-10 md:top-28 lg:top-36;
    @apply lg:min-w-96;
    @apply px-4 xs:px-0 animate-slideinright;
    &.closing {
      @apply pointer-events-none;
      @apply animate-slideoutright;
    }
    &.profile {
      @apply hidden;
    }
    .rating-alert {
      @apply bg-white;
      @apply relative border-2 border-black;
      @apply shadow-2xl;
      @apply px-4 xs:px-5 md:px-6 pt-3 pb-3 xs:py-4 md:py-6;
      .subheading {
        @apply block mobile:-mt-1 md:mb-2;
      }
      .button {
        @apply mobile-sm:text-xl mobile-sm:py-1 mt-2 whitespace-nowrap;
        &.later {
          @apply bg-white border-none text-gray-400 pl-0;
        }
      }
    }
  }
</style>
