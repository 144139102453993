<!--==================================================================+
| MODAL: LOGIN / REGISTER                                             |
+===================================================================-->

<template>
  <juit-modal
    ref="modal"
    :event_lock="processing_lock"
    :locked="locked || processing_lock"
    class="small"
    @dismiss-modal="dismiss"
  >
    <!-- WHEN PROCESSING -->
    <juit-submitting :visible="processing_lock" />

    <!-- LOGIN / REGISTER / RESET PW SCREENS -->
    <slot>
      <juit-login-register
        :routerback="!locked"
        @close="() => modal ? modal.closeModal() : undefined"
        @processing="processing_lock = $event"
      />
    </slot>
  </juit-modal>
</template>

<script lang="ts">
  import { DeepReadonly, defineComponent } from 'vue'
  import juitSubmitting from '../widgets/juit-submitting.vue'
  import JuitModal from './modal.vue'
  import JuitLoginRegister from '../widgets/juit-login-register.vue'
  import { user } from '../init/client'
  import { User } from '@juitnow/lib-schema/types'

  export default defineComponent({
    components: {
      juitSubmitting,
      JuitLoginRegister,
      JuitModal,
    },
    props: {
      locked: {
        type: Boolean,
        reqired: false,
        default: false,
      },
    },
    emits: {
      dismissModal: (_user?: DeepReadonly<User>) => true,
    },

    data: () => ({
      processing_lock: false,
      screen: 'login',
    }),
    computed: {
      modal() {
        return this.$refs['modal'] as InstanceType<typeof JuitModal>
      },
    },
    methods: {
      dismiss() {
        this.$emit('dismissModal', user.value)
      },
    },
  })
</script>
