<!--==================================================================+
| MODULE-COMPONENT: LOGO WALL                                         |
+===================================================================-->

<template>
  <div class="logo-wall">
    <div class="container">
      <div
        v-for="logo in module.list"
        :key="logo.title"
        class="logo"
      >
        <juit-image
          v-bind="logo.logo"
          fit="contain"
        />
        <a
          v-if="logo.url"
          :href="logo.url"
          target="_blank"
          class="absolute w-full h-full z-1 top-0"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { PressLogoList } from '../content'


  export default defineComponent({
    props: {
      module: {
        type: Object as PropType<PressLogoList>,
        required: true,
      },
    },
  })
</script>

<style scoped lang="pcss">
.logo-wall {
  @apply w-full bg-specialOrange py-5 xs:py-6 md:py-9 xl:py-12;
  .container {
    @apply flex flex-row justify-between py-0 flex-wrap xs:flex-nowrap;
    .logo {
      @apply max-h-6 md:max-h-8 relative;
      @apply w-4/5 xs:w-full xs:flex-1 mx-auto xs:mx-2 my-2 xs:my-0;
    }
  }
}
</style>
