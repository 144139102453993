<!--==================================================================+
| COMPONENT: FOOTER                                                   |
+===================================================================-->

<template>
  <footer v-if="$route" class="juit-footer">
    <div class="container">
      <div translate="no" class="logo">
        <span class="self-center">JU IT</span>
      </div>

      <ul class="internal-links">
        <li v-for="(item, i) in footer" :key="i">
          <router-link :to="`/${$i18n.locale}/${item.slug}`">
            {{ item.title }}
          </router-link>
        </li>
        <li>
          <a href="#cookies" @click.stop.prevent="setCookies()">
            {{ $t('footer.cookies') }}
          </a>
        </li>
        <li>
          <a
            href="https://juit.jobs.personio.com/"
            target="_blank"
            rel="noopener"
            aria-label="Careers"
          >
            {{ $t('footer.careers') }}
          </a>
        </li>
      </ul>

      <juit-global-rating class="global-rating" />
      <juit-social-media-links />
    </div>
  </footer>
</template>

<script lang="ts">
  import cookiesModal from '../modals/cookies/cookies-modal.vue'
  import juitGlobalRating from '../widgets/juit-global-rating.vue'
  import juitSocialMediaLinks from '../widgets/juit-social-media-links.vue'
  import { defineComponent } from 'vue'
  import { footer } from '../init/router'

  export default defineComponent({
    components: { juitGlobalRating, juitSocialMediaLinks },
    data: () => ({
      footer,
    }),
    methods: {
      setCookies() {
        this.$createModal(cookiesModal as any)
      },
    },
  })
</script>

<style scoped lang="pcss">
  /* ======================================================================== *
   * Footer element                                                           *
   * ======================================================================== */

  .juit-footer {
    @apply w-full z-30 bg-black text-white mt-auto;
    > .container {
      @apply mt-auto mb-0;
      @apply flex uppercase flex-row flex-wrap md:flex-nowrap xl:justify-between;
    }
    .logo {
      @apply pt-4 md:pt-2 pb-0 md:mr-6 md:flex;
      @apply font-headline text-3xl sm:text-4xl md:text-5xl;
    }
    .internal-links {
      @apply pt-2 pb-3 md:py-6 lg:py-8 flex-grow xl:mx-10;
      @apply flex md:flex-col md:max-h-48 lg:max-h-40 flex-wrap;
      @apply mobile:order-1 mobile:flex mobile:flex-wrap;
      @apply mobile:w-full sm:w-1/2 xl:w-1/3 xl:pr-0;
      li {
        @apply text-sm md:text-base px-3 md:px-4 lg:pl-5 lg:pr-0 py-1 relative;
        @apply mobile:pl-0 h-auto md:h-1/5 lg:h-1/3 xl:w-1/4;
      }
    }


    .global-rating {
      @apply w-auto self-center place-self-center;
      @apply lg:pl-5 lg:ml-5 xl:w-1/4;
      @apply mobile:pl-5 mobile:pt-3 md:pl-0 md:pt-0;
    }
  }
  :deep() .rating-container {
    @apply flex-wrap max-w-40 justify-start;
    @apply lg:pb-1 xl:pb-0;
    .padding {
      @apply hidden;
    }
    .bold {
      @apply pt-0.5;
    }
    .global-rating-text {
      @apply normal-case;
      @apply mobile:text-sm mobile:mt-0 lg:text-lg;
    }
    .global-rating-stars {
      @apply mobile:w-20 w-28 lg:w-32;
    }
    .tooltip {
      width: calc(100vw - 2rem);
      @apply text-black normal-case bottom-12 md:bottom-16 -left-11 sm:left-5 md:left-0;
      @apply sm:w-80;
    }
  }

  .dish-selection + .juit-footer,
  .checkout + .juit-footer {
    @apply hidden;
  }
</style>
