<template>
  <div>
    <span class="title">{{ $t( 'dish.header' ) }}</span>
    <ul>
      <li>&nbsp;</li>
      <li>{{ $t('dish.per-100-grams') }}</li>
      <li>{{ $t('dish.per-portion') }} ({{ nutrition(product.weight, 'g', 100 / (product.portions || 1), 1) }})</li>
    </ul>

    <ul>
      <li>{{ $t('dish.energy') }}</li>
      <li>{{ nutrition((product.calories || 0) * 4.184, 'kJ', 100, 0) }}</li>
      <li>{{ nutrition((product.calories || 0) * 4.184, 'kJ', product.weight / (product.portions || 1), 1) }}</li>
    </ul>

    <ul class="continued">
      <li>&nbsp;</li>
      <li>{{ nutrition(product.calories, 'kcal', 100, 0) }}</li>
      <li>{{ nutrition(product.calories, 'kcal', product.weight / (product.portions || 1), 1) }}</li>
    </ul>

    <ul>
      <li>{{ $t('dish.fat') }}</li>
      <li>{{ nutrition(product.fat, 'g') }}</li>
      <li>{{ nutrition(product.fat, 'g', product.weight / (product.portions || 1), 1) }}</li>
    </ul>

    <ul class="small">
      <li>{{ $t('dish.saturated-fat') }}</li>
      <li>{{ nutrition(product.saturatedFat, 'g') }}</li>
      <li>{{ nutrition(product.saturatedFat, 'g', product.weight / (product.portions || 1), 1) }}</li>
    </ul>

    <ul>
      <li>{{ $t('dish.carbohydrate') }}</li>
      <li>{{ nutrition(product.carb, 'g') }}</li>
      <li>{{ nutrition(product.carb, 'g', product.weight / (product.portions || 1), 1) }}</li>
    </ul>

    <ul class="small">
      <li>{{ $t('dish.sugar') }}</li>
      <li>{{ nutrition(product.sugar, 'g') }}</li>
      <li>{{ nutrition(product.sugar, 'g', product.weight / (product.portions || 1), 1) }}</li>
    </ul>

    <ul>
      <li>{{ $t('dish.fiber') }}</li>
      <li>{{ nutrition(product.fiber, 'g') }}</li>
      <li>{{ nutrition(product.fiber, 'g', product.weight / (product.portions || 1), 1) }}</li>
    </ul>

    <ul>
      <li>{{ $t('dish.protein') }}</li>
      <li>{{ nutrition(product.protein, 'g') }}</li>
      <li>{{ nutrition(product.protein, 'g', product.weight / (product.portions || 1), 1) }}</li>
    </ul>

    <ul>
      <li>{{ $t('dish.salt') }}</li>
      <li>{{ nutrition(product.salt, 'g', 100, 2) }}</li>
      <li>{{ nutrition(product.salt, 'g', product.weight / (product.portions || 1), 2) }}</li>
    </ul>

    <ul v-if="product.vitaminB12">
      <li>{{ $t('dish.vb12') }}</li>
      <li>{{ nutrition(product.vitaminB12, 'μg') }}</li>
      <li>{{ nutrition(product.vitaminB12, 'μg', product.weight / (product.portions || 1)) }}</li>
    </ul>
    <ul v-if="product.vitaminB12" class="small">
      <li>{{ $t('dish.rda') }}</li>
      <li>{{ nutrition(product.vitaminB12 / 0.25, '%') }}</li>
      <li>{{ nutrition(product.vitaminB12 / 0.25, '%', product.weight / (product.portions || 1)) }}</li>
    </ul>
  </div>
</template>


<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Dish } from '../content'
  import { locale } from '../init/i18n'

  export function nutrition(
    value: number | undefined,
    unit: string,
    weight = 100,
    decimals = 1,
  ): string {
    const v = value ? value * weight / 100 : 0
    const format: Intl.NumberFormatOptions = { style: 'decimal', useGrouping: true }
    let digits = -1
    switch (decimals) {
      case 0:
        digits = 0
        break
      case 1:
        digits = v >= 10 ? 0 : 1
        break
      case 2:
        digits = v >= 1 ? 1 : 2
        break
    }
    if (digits >= 0) {
      format.minimumFractionDigits = digits
      format.maximumFractionDigits = digits
    }
    const unit_with_space = unit ? `\u2009${unit}` : ''
    return `${new Intl.NumberFormat(locale.value, format).format(v)}${unit_with_space}`
  }

  export default defineComponent({
    props: {
      product: {
        type: Object as PropType<Dish>,
        required: true,
      },
    },
    methods: { nutrition },
  })
</script>

<style scoped lang="pcss">
  ul {
    @apply flex text-sm md:text-base;

    /* First <ul> is the header, no border */

    /* Second <ul> has a thick border, to separate from the header */
    &:nth-child(2) {
      @apply pt-1.5 mt-1.5 border-t border-gray-200;
    }

    /* All other <ul> have thin border, unless continuing the previous one */
    &:nth-child(n+3) {
      @apply pt-1 mt-1 border-t border-gray-200;

      &.continued {
        @apply pt-1 mt-0 border-none;
      }
    }

    /* First list item is always 1/2 wide, aligned to the left */
    & li:first-child {
      @apply w-1/2 text-left;
    }

    /* First list item can be smaller (e.g. "of which sugars") */
    &.small li:first-child {
      @apply text-xs md:text-sm pl-3 pt-0.5;
    }

    /* Second and third list items are 1/4 wide, right aligned, for data */
    & li:nth-child(n+2) {
      @apply w-1/4 text-right min-w-24;
    }
  }
</style>
