<!-- eslint-disable vue/no-v-html -->
<template>
  <juit-modal
    ref="modal"
    :url="url"
    class="no-padding"
    @dismiss-modal="$emit('dismissModal')"
  >
    <slot>
      <div class="dismiss bg-[#ffffff4f]" @click="() => modal ? modal.closeModal() : undefined" />

      <div class="dish-image">
        <div v-if="product.extraImages?.length" class="extra-images">
          <juit-carousel class="solid-arrow small-arrow" :card-width="cardwidth">
            <div ref="card" class="tile snap-start">
              <juit-image :src="product.image.src" />
            </div>
            <div
              v-for="(img, index) in product.extraImages"
              :key="index"
              :src="img.src"
              class="tile snap-start"
            >
              <juit-video v-if="img.contentType.includes('video')" :src="img.src" />
              <juit-image v-else :src="img.src" />
            </div>
          </juit-carousel>
        </div>
        <juit-image v-else :src="product.image.src" />
      </div>

      <div class="py-6 px-5 sm:px-7 md:px-8 w-full">
        <div class="flex relative">
          <div class="flex-grow pr-6">
            <span class="title">{{ product.title }}
              <juit-favorite class="relative inline-block ml-0.5 md:ml-1.5 top-1 -right-1 md:top-1 md:right-0 w-6 md:w-7" :product="product" />
            </span>
            <p v-if="product.secondTitle" class="second-title">
              {{ product.secondTitle }}
            </p>
            <div v-if="product.tags" class="tags">
              <div v-if="product.specialTag">
                <juit-badge :class="[ product.specialTag, 'small mr-2 mb-2']">
                  <juit-tag-icon :cat="product.specialTag" class="mr-2" />
                  <span>{{ product.specialTag }}</span>
                  <span v-if="product.specialDish">{{ `&nbsp;+${ b2b ? ( product.specialDish / 1.07).toFixed(2) : product.specialDish }€` }}</span>
                </juit-badge>
              </div>
              <juit-badge
                v-for="(detail, index) in tag_splitted"
                :key="index"
                class="revert small mr-2 mb-2"
                :class="[detail[0], detail[1], detail[2]]"
              >
                <span>{{ $t(`tags.${detail[2]}`) }}</span>
                <juit-tag-icon :cat="detail[0]" :name="detail[2]" />
              </juit-badge>
            </div>
          </div>
          <div class="hidden md:block whitespace-nowrap">
            <juit-button
              v-if="cta"
              @click="order"
            >
              {{ $t( 'dish.to-order' ) }}
            </juit-button>
          </div>
        </div>

        <rich-text class="description" :document="product.description" />
        <p v-if="product.specialDishDescription" class="description font-bold -mt-5">
          {{ product.specialDishDescription }}
        </p>
        <div class="md:hidden -mt-2 mb-6">
          <juit-button
            v-if="cta"
            @click="order"
          >
            <span class="cta-btn-text">{{ $t( 'dish.to-order' ) }}</span>
          </juit-button>
        </div>

        <juit-nutrition-strip :product="product" class="nutrition-stripe" />

        <div v-if="product.panels?.length" class="panels">
          <juit-panel
            v-for="(panel, index) in product.panels"
            :key="index"
            :module="panel"
          />
        </div>

        <span class="sub-title">{{ $t('dish.ingredients') }}</span>
        <rich-text
          class="text-sm sm:text-base leading-normal"
          :document="product.ingredients"
        />

        <juit-nutrition-label :product="product" class="nutrition-label" />

        <span class="sub-title">{{ $t('dish.preparation') }}</span>
        <rich-text v-if="product.preparation || preparations" class="detail-item" :document="product.preparation || preparations" />
      </div>

      <ul class="more-info">
        <li>{{ product.moreInfo || product.title + $t('dish.more-info-text') }}</li>
        <li v-html="product.storage || $t('dish.storage')" />
        <li>
          {{ $t('dish.net-weight') }}
          {{ nutrition(product.weight, 'g', 100, 0) }}
          <span v-if="product.portions && product.portions !== 1">({{ `${product.portions} ${$t('dish.portions')}` }})</span>
        </li>
      </ul>
    </slot>
  </juit-modal>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { locale } from '../init/i18n'

  import juitBadge from '../widgets/juit-badge.vue'
  import juitPanel from '../modules/panel.vue'
  import juitNutritionLabel, { nutrition } from '../widgets/juit-nutrition-label.vue'
  import juitNutritionStrip from '../widgets/juit-nutrition-strip.vue'
  import juitCarousel from '../widgets/juit-carousel.vue'
  import juitTagIcon, { splitTags } from '../widgets/juit-tag-icon'
  import JuitModal from './modal.vue'
  import { Dish } from '../content'
  import { analyticsEvent } from '../analytics'
  import juitFavorite from '../widgets/juit-favorite.vue'

  export default defineComponent({
    name: 'JuitDishModal',
    components: { juitFavorite, juitTagIcon, juitBadge, juitNutritionLabel, juitNutritionStrip, juitPanel, juitCarousel },
    props: {
      product: {
        type: Object as PropType<Dish>,
        required: true,
      },
      url: {
        type: String as PropType<string | undefined>,
        default: undefined,
      },
      cta: {
        type: Boolean,
        default: true,
      },
      b2b: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      dismissModal: (url?: string) => true,
    },
    data: () => ({
      preparations: undefined as undefined | Document,
      cardwidth: 0,
    }),

    computed: {
      modal() {
        return this.$refs['modal'] as InstanceType<typeof JuitModal>
      },
      tag_splitted() {
        return Object.values(splitTags(this.product.tags))
      },
    },
    created() {
      analyticsEvent('view_item', { items: [ {
        item_name: this.product.title,
        item_id: this.product.ean,
        quantity: 1,
      } ] })
    },
    mounted() {
      const card = this.$refs.card as HTMLElement
      if (card) this.cardwidth = card.getBoundingClientRect().width
    },
    methods: {
      nutrition: nutrition,
      order() {
        // GA Event: Start order
        this.$emit('dismissModal', `/${locale.value}/box`)
      },
    },
  })
</script>

<style scoped lang="pcss">
  .tags {
    @apply origin-left flex flex-wrap transform scale-90;
  }
  .vegan + .vegetarian, .secondary {
    @apply hidden;
  }
  .dismiss {
    @apply fixed z-50 md:absolute top-0 right-0 flex;
    @apply cursor-pointer justify-center flex-col w-14 h-14;

    &:after {
      content: '+';
      font-family: monospace;
      @apply text-center text-4xl transform rotate-45;
    }
  }
  .dish-image {
    :deep() .carousel-and-arrows .roller-wrapper > .tiled .tile {
      @apply w-full p-0;
    }
  }
  .title {
    @apply block text-2xl md:text-3xl font-bold tracking-tight uppercase;
    :deep() .fav-button .circle {
      @apply -mt-0.5 w-14 h-14 md:w-16 md:h-16;
    }
  }
  .second-title {
    @apply text-lg lg:text-xl;
  }
  .description {
    @apply mb-7 text-sm sm:text-base;
  }
  .tags {
    @apply mt-2;
  }
  .sub-title {
    @apply block mt-6 my-3 text-xl md:text-2xl font-bold tracking-tight uppercase;
  }
  .panels {
    :deep() .container {
      @apply px-0 pb-6 mb-6 sm:pb-8 sm:mb-8 border-b-2 border-black;
      &:first-child {
        @apply pt-6 sm:pt-8;
      }
      &:nth-child(2n+1) {
        .panel-text {
          @apply order-1 pr-0 md:pl-6;
        }
      }
      .panel-media {
        @apply min-h-0;
      }
      .panel-text {
        @apply py-0 mt-5 -mb-1 md:my-0 md:pr-6;
        h1 {
          @apply lg:text-5xl;
        }
        .subheading {
          @apply text-sm sm:text-base;
        }
      }
    }
  }

  .nutrition-stripe {
    @apply bg-black text-ash -mx-5 sm:-mx-7 md:-mx-8;
  }
  .nutrition-label {
    :deep() {
      .title {
        @apply block mt-6 my-3 text-xl md:text-2xl font-bold tracking-tight uppercase;
      }
    }
   }

  ul.more-info {
    @apply bg-black py-6 px-5 sm:px-7 text-ash md:px-8 w-full;

    & li {
      @apply text-sm sm:text-base leading-normal;
    }

    & li:nth-child(n+2) {
      @apply border-t border-white pt-1.5 mt-1.5;
    }
  }
</style>
