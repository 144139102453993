/* ========================================================================== *
 * CREATE AND EXPORT APP                                                      *
 * -------------------------------------------------------------------------- *
 * This creates the main app, it'll be used both by the client and by the     *
 * server-side renderer.                                                      *
 * ========================================================================== */
import { App } from '@vue/runtime-core'
import { createApp } from '@vue/runtime-dom'
import { modals } from '@juit/vue-modal'
import { i18n } from './init/i18n'
import { plugin as client } from './init/client'
import { router } from './init/router'
import { intersectionObserver } from './init/intersection'

import root from './root.vue'
import juitImage from './widgets/juit-image.vue'
import juitVideo from './widgets/juit-video.vue'
import juitButton from './widgets/juit-button.vue'
import juitDishCard from '../src/widgets/juit-dish-card.vue'
import juitSubmitting from './widgets/juit-submitting.vue'
import juitInputEmail from './widgets/input-email.vue'
import juitInputPassword from './widgets/input-password.vue'
import juitSpinner from './widgets/juit-spinner.vue'
import juitModal from './modals/modal.vue'
import richText from './widgets/rich-text.vue'

import './style.css'

export function createJuitApp(): App<Element> {
  return createApp(root)
      .use(client)
      .use(i18n)
      .use(modals)
      .use(router)
      .component('input-email', juitInputEmail)
      .component('input-password', juitInputPassword)
      .component('juit-modal', juitModal as any)
      .component('juit-button', juitButton)
      .component('juit-dish-card', juitDishCard)
      .component('juit-submitting', juitSubmitting)
      .component('juit-spinner', juitSpinner)
      .component('juit-image', juitImage)
      .component('juit-video', juitVideo)
      .component('rich-text', richText)
      .directive('intersect', intersectionObserver)
}
