<!--==================================================================+
| WIDGET: INPUT EMAIL                                                 |
+===================================================================-->

<template>
  <div>
    <input
      :value="email"
      type="email"
      v-bind="$attrs"
      autocomplete="email"
      :placeholder="$t( 'authentication.email-placeholder' )"
      :class="{ 'valid-false': !valid && email }"
      @input="$emit('update:email', ($event.target as HTMLTextAreaElement)?.value)"
    >
    <p v-if="valid === false" class="invalid-msg">
      {{ $t( 'authentication.email-invalid' ) }}
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'JuitInputEmail',
    inheritAttrs: false,
    props: {
      email: {
        type: String,
        default: '',
      },
    },
    emits: [ 'update:email', 'update:valid' ],
    computed: {
      valid() {
        if (!this.email || this.email.length < 1) return null
        if (this.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/) != null) return true
        return false
      },
    },
    watch: {
      valid(v) {
        this.$emit('update:valid', v)
      },
    },
  })
</script>
