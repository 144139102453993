<template>
  <div v-if="previewEnvironment" class="preview-environment">
    previewing<br><b>{{ previewEnvironment }}</b>
  </div>

  <div v-if="dev && $route.name !== 'maintenance-page'" class="select-cohort">
    <div class="w-16 md:w-20 bg-white pb-2">
      Cohort: {{ selected_cohort }}
    </div>
    <div
      v-for="cohort in cohort_range"
      :key="cohort"
      class="option"
      @click="setCohort(cohort)"
    >
      {{ cohort }}
    </div>
  </div>

  <page-header v-if="$route.name !== 'maintenance-page'" :class="[{ disabled: $route?.name === 'profile' }, $route?.name ]" />
  <router-view />
  <page-footer v-if="$route.name !== 'maintenance-page'" />

  <rating-alert :class="$route?.name" />
  <profile-alert :class="$route?.name" />
  <modal-stack @modals="onModals" />
</template>

<script lang="ts">
  import { previewEnvironment } from './content/client'
  import { defineComponent } from 'vue'
  import pageHeader from './layout/header.vue'
  import pageFooter from './layout/footer.vue'
  import ratingAlert from './components/profile/rating-alert.vue'
  import profileAlert from './components/profile/profile-alert.vue'
  import { alertNotification, userCohort } from './init/state'
  import { boxIds, boxOptions, reactiveCart } from './init/reactive-order'

  export default defineComponent({
    name: 'JuitRoot',
    components: {
      pageHeader,
      pageFooter,
      ratingAlert,
      profileAlert,
    },
    data: () => ({
      previewEnvironment,
      notification: (new URLSearchParams(globalThis.window.location.search)).get('notification') as string,
      pre_selected_box: Number((new URLSearchParams(globalThis.window.location.search)).get('boxsize')),
      dev: location.hostname !== 'www.juit.com',
      selected_cohort: userCohort.value,
      cohort_range: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ],
    }),

    mounted() {
      if (this.pre_selected_box && boxOptions.includes(this.pre_selected_box)) {
        reactiveCart.box = this.pre_selected_box
        reactiveCart.boxId = boxIds[boxOptions.indexOf(this.pre_selected_box)]
      }
      if (this.notification) alertNotification.value = this.notification
    },

    methods: {
      onModals(hasModals: boolean) {
        if (hasModals) globalThis.document.getElementsByTagName('html')[0].classList.add('has-modals')
        else globalThis.document.getElementsByTagName('html')[0].classList.remove('has-modals')
      },

      setCohort(cohort: number) {
        document.querySelector('.select-cohort')?.classList.add('close')
        this.selected_cohort = cohort
        userCohort.value = cohort
        localStorage.setItem('jn:cohort', cohort.toString())
        globalThis.window.gtag!('set', 'user_properties', { cohort: cohort })
        setTimeout(() => document.querySelector('.select-cohort')?.classList.remove('close'), 500)
      },
    },

  })

</script>

<style scoped lang="pcss">
  .preview-environment {
    user-select: none;
    font-size: 10px;
    position: fixed;
    inset: 0 auto auto 0;
    background: rgba(76, 136, 58, 0.90);
    transform-origin: 100% 0;
    transform: translate(-29.3%) rotate(-45deg);
    box-shadow: 0 0 0 999px rgba(76, 136, 58, 0.90);
    clip-path: inset(0 -100%);
    z-index: 99999999;
    text-align: center;
    color: #ffffff;
    b {
      font-size: 14px;
    }
  }

  .select-cohort {
    @apply fixed z-60 cursor-pointer text-blue;
    @apply top-3.5 right-12 sm:right-14 md:right-20 md:top-3 lg:top-5 lg:right-52 xxl:right-72;
    @apply font-bold text-sm md:text-base;
    .option {
      @apply w-16 bg-white pb-1 md:pb-2 text-center h-0 opacity-0 overflow-hidden;
      @apply transition-all text-black;
    }
    &:not(.close):hover {
      .option {
        @apply h-6 md:h-8 opacity-100;
      }
    }
  }
</style>
