<template>
  <div class="constrained">
    <rich-text v-if="content" :document="content.content" />
  </div>
  <div class="modal-footer">
    <div class="juit-link" @click="$emit('back')">
      {{ $t( 'cookies.back' ) }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { locale } from '../../init/i18n'
  import { fetchPage } from '../../content/fetch'
  import { Page } from '../../content/convert'

  export default defineComponent({
    name: 'JuitCookiesPrivacy',
    emits: [ 'back' ],

    data: () => ({
      content: undefined as Page | undefined,
    }),

    async mounted() {
      this.content = await fetchPage(locale.value, 'privacy-policy')
    },
  })
</script>

<style lang="pcss" scoped>
  .constrained:deep() {
    max-height: calc(60vh - 5rem);
    @apply overflow-y-scroll overflow-x-hidden pb-4;

    h1 {
      @apply text-2xl md:text-2xl mb-2 font-text font-bold normal-case;
    }
    h2, h3 {
      @apply text-lg md:text-xl mb-2 mt-2 font-text font-bold normal-case;
    }
    * + h1 {
      @apply mt-4;
    }
    h1 + h2 {
      @apply -mt-1;
    }
    p {
      @apply text-sm mb-0;
      word-break: break-word;
    }
  }

  .modal-footer {
    @apply mt-6 -mb-4;
    .juit-link {
      @apply py-3 pr-3 self-start;
    }
  }
</style>
