<template>
  <juitDocument :document="document" />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Document } from '@contentful/rich-text-types'
  import juitDocument from '../content/document'

  export default defineComponent({
    components: { juitDocument },
    props: {
      document: {
        type: Object as PropType<Document>,
        required: true,
      },
    },
    mounted() {
      this.document.nodeType
    },
  })
</script>

<style scoped lang="pcss">
  :deep() {
    @apply whitespace-pre-line;
  }

  :deep() h2 {
    @apply tracking-tight mt-3 mb-2 lg:mt-6 lg:mb-4 lg:mt-8;
  }

  :deep() h3 {
    @apply tracking-tight mt-0 mb-2 lg:mt-3;
  }

  :deep() ul {
    @apply mb-2;
  }

  :deep() li {
    @apply list-disc ml-5;
  }

  :deep() p {
    @apply mb-2;
  }

  :deep() a[href] {
    @apply text-blue;
  }
</style>
