<!--==================================================================+
| MODULE-COMPONENT: DATA CONTENT                                      |
+===================================================================-->

<template>
  <div class="data-content container" :class="{ 'subscribe-container' : module.moduleType === 'Subscribe' }">
    <div
      v-if="module.moduleType === 'Subscribe'"
      @loading="loading=$event"
    >
      <div class="tiled my-0">
        <div class="tile panel-media">
          <juit-image
            v-if="module.image"
            :src="module.image.src"
          />
        </div>
        <div class="tile panel-text">
          <juit-spinner v-if="loading" class="large w-full h-full bg-white bg-opacity-70" />
          <h1 v-if="!userIsSubscribed">
            {{ module.title }}
          </h1>
          <rich-text
            v-if="module.description && !userIsSubscribed"
            :document="module.description"
            class="subheading"
          />
          <juit-subscribe @user-subscribed="userIsSubscribed=true" />
        </div>
      </div>
    </div>

    <div v-else>
      <span class="border-top" />
      <div class="text-top">
        <h1>{{ module.title }}</h1>
        <rich-text
          v-if="module.description"
          :document="module.description"
          class="subheading"
        />
      </div>

      <juit-cta v-if="module.moduleType === 'App Download CTA'" />
      <juit-contact v-if="module.moduleType === 'Contact'" />
      <juit-careers v-if="module.moduleType === 'Careers'" :module="module" />

      <rich-text
        v-if="module.contentBottom"
        :document="module.contentBottom"
        class="subheading"
        :class="[ module.moduleType.replaceAll(' ', '')?.toLowerCase() ]"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { DataContent } from '../content'

  import juitContact from './data-content/contact.vue'
  import juitCareers from './data-content/careers.vue'
  import juitCta from './data-content/cta-app.vue'
  import juitSubscribe from './data-content/subscribe.vue'

  export default defineComponent({
    name: 'JuitDishContent',
    components: { juitContact, juitCta, juitCareers, juitSubscribe },
    props: {
      module: {
        type: Object as PropType<DataContent>,
        required: true,
      },
    },
    data: () => ({
      userIsSubscribed: false,
      loading: false,
    }),
  })
</script>

<style scoped lang="pcss">
.border-top {
  @apply border-t-2 border-black;
  @apply mt-8 md:mt-12 pt-8 md:pt-12;
}
.subscribe-container {
  @apply mt-12 lg:mt-16 lg:py-0;
  .tiled {
    @apply mx-0;
    .tile {
      @apply w-full;
      &.panel-media {
        @apply relative md:w-2/3;
        @apply min-h-1/3-screen-h md:min-h-3/4-screen-h;
        @apply p-0 mobile:w-full;
      }
      &.panel-text {
        @apply mobile:px-0 md:w-1/3 text-left mobile:order-1 self-center pb-0 md:pr-0 md:pl-8 lg:pl-10 order-1;
        @apply pt-10 md:pt-0;
      }
    }
  }
}
</style>
