import { reactive } from 'vue'
import { watch, watchEffect } from '@vue/runtime-core'
import { client, user } from './client'
import { log, logError } from './log'
import { reactiveCheckout } from './reactive-order'
import type { Address } from '@juitnow/api-addresses-v2'
import { favDishes, userMandates, ordersList, userPaymentMethods } from './state'

type Status = 'inactive' | 'waiting' | 'pending' | 'ongoing' | 'done'
// eslint-disable-next-line no-var
export var checkoutQueue = reactive({
  init_cart: 'inactive' as Status,
  init_checkout: 'inactive' as Status,
  init_inventory: 'inactive' as Status,
  init_login: 'inactive' as Status,
  manual_login: 'inactive' as Status,
  login_session: 'inactive' as Status,
  magic_link_login: 'inactive' as Status,
  loading_user_preference: 'inactive' as Status,
  loading_address: 'inactive' as Status,
  loading_mandates: 'inactive' as Status,
  loading_checkout_page: 'inactive' as Status,
  checking_step: 'inactive' as Status,
})

// User logged in, load and store the user data
watch(user, async (newUser, oldUser) => {
  if (!oldUser && newUser?.uuid) {
    if (checkoutQueue.init_login === 'done') checkoutQueue.manual_login = 'ongoing'
    checkoutQueue.loading_address = 'ongoing'
    checkoutQueue.loading_mandates = 'ongoing'
    log(`%cUser logged in: ${newUser.given_name} ${newUser.family_name}`, 'color: green')

    // Load the user preference
    checkoutQueue.loading_user_preference = 'pending'

    // Load addresses and mandates
    try {
      const [ addresses, mandates ] = await Promise.all([
        await client.addresses.list({ default: true }),
        await client.mandates.list({}),
      ])
      if (mandates.length) userMandates.value = mandates
      if (addresses.length) {
        addresses.forEach((address) => {
          if (address.type === 'shipping') reactiveCheckout.addresses.shipping = address
          else reactiveCheckout.addresses.billing = address
        })
        log('%cUser default addresses loaded:', 'color: green', reactiveCheckout.addresses)
        // Same address for billing and shipping?
        const [ billing, shipping ] = [ reactiveCheckout.addresses.billing, reactiveCheckout.addresses.shipping ]
        if (billing && shipping) {
          const keys = Object.keys(shipping).filter((key) => ![ 'type', 'uuid', 'created', 'modified' ].includes(key)) as Array<keyof Address>
          reactiveCheckout.addresses.same = !(keys.find((key) => billing[key] !== shipping[key]))
        }
      }
    } catch (e) {
      logError(e)
    } finally {
      checkoutQueue.loading_address = 'done'
      checkoutQueue.loading_mandates = 'done'
      checkoutQueue.loading_user_preference = 'done'
    }

  // User logged out, clear the user data
  } else if (oldUser && !newUser) {
    log('%cUser logged out. Clearing addresses', 'color: green')
    reactiveCheckout.addresses.billing = undefined
    reactiveCheckout.addresses.shipping = undefined
    checkoutQueue.checking_step = 'pending'
    checkoutQueue.loading_user_preference = 'inactive'
    checkoutQueue.manual_login = 'inactive'
    favDishes.value = []
    ordersList.value = []
    userMandates.value = []
    userPaymentMethods.value = []
  }
})

watchEffect(async () => {
  // After initializing, load the checkout components
  log(checkoutQueue.init_cart, checkoutQueue.init_checkout, checkoutQueue.init_inventory, checkoutQueue.init_login)
  if (checkoutQueue.init_cart === 'done' &&
      checkoutQueue.init_checkout === 'done' &&
      checkoutQueue.init_inventory === 'done' &&
      checkoutQueue.loading_checkout_page !== 'done' &&
     (checkoutQueue.magic_link_login === 'inactive' || checkoutQueue.magic_link_login === 'done') &&
     (checkoutQueue.loading_address === 'done' || !user.value)) checkoutQueue.loading_checkout_page = 'pending'

  // After we dealt with addresses OR if there is no user and page already loaded, check for checkout step
  if (checkoutQueue.loading_checkout_page === 'done' &&
     (checkoutQueue.login_session === 'inactive' || checkoutQueue.login_session === 'done') &&
     (checkoutQueue.loading_address === 'done' || !user.value)) checkoutQueue.checking_step = 'pending'
})
