import { watch } from 'vue'
import { client, user } from './client'
import { dishRatings, dishRatingsMine } from './state'
import { logError } from './log'

/* ========================================================================== */

export function initializeDishRatings(): void {
  // Fetch personal ratings when user changes
  watch(user, (newUser, oldUser) => {
    if (newUser && (!oldUser || newUser.uuid !== oldUser.uuid)) fetchRatingScores(true)
    if (!newUser) dishRatingsMine.value = undefined
  }, { immediate: true })

  fetchRatingScores()
}

async function fetchRatingScores(user?: boolean): Promise<void> {
  // My rating
  if (user && !dishRatingsMine.value) {
    const ratings = await client.ratings.favorites().catch((e) => logError(e))
    if (ratings) {
      dishRatingsMine.value = {}
      ratings.forEach((rating) => dishRatingsMine.value![rating.ean] = rating.average)
    }
  }
  // All users Rating
  if (dishRatings.value) return
  const ratings = await client.ratings.scores().catch((e) => logError(e))
  if (ratings) {
    dishRatings.value = {}
    ratings.forEach((rating) => dishRatings.value![rating.ean] = rating.average)
  }
}
