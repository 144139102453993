import { ref } from 'vue'
import { Dish, fetchAllProducts } from '../content'
import { checkoutQueue } from './checkout-queue'
import { client } from './client'
import { env } from './env'
import { locale } from './i18n'
import { log, logError } from './log'

let eans_with_stock: string[] = []
export const available_eans = ref([] as string[])
export const availableDishes = ref([] as Dish[])

export const initializeInventories = async (): Promise<void> => {
  if (checkoutQueue.init_inventory === 'ongoing') return
  try {
    checkoutQueue.init_inventory = 'ongoing'
    const inventories = await client.inventories.list({ location_uuid: env.VITE_DEFAULT_DELIVERY_LOCATION })
    eans_with_stock = inventories.filter((dish) => dish.quantity > 0).map((dish) => dish.ean)
    log('%cInventories fetched', 'color: firebrick')
    await visibilityCheckAll()
  } catch (err) {
    logError(err)
  } finally {
    checkoutQueue.init_inventory = 'done'
  }
}

export const visibilityCheck = (dishes: Dish[]): Dish[] => {
  const visible = [] as Dish[]
  available_eans.value = available_eans.value.length ? available_eans.value : [ ...eans_with_stock ]
  dishes.forEach((dish) => {
    if (eans_with_stock.includes(dish.ean)) {
      if (dish.sellable) visible.push(dish)
      else if (available_eans.value.includes(dish.ean)) available_eans.value.splice(available_eans.value.indexOf(dish.ean), 1)
    } else if (dish.sellable && dish.alwaysDisplay) visible.push({ ...dish, soldout: true })
  })
  return visible
}

export const visibilityCheckAll = async (): Promise<void> => {
  log('%cChecking availability for all dishes...', 'color: firebrick')
  const dishes = await fetchAllProducts(locale.value)
  availableDishes.value = dishes ? visibilityCheck(dishes) : []
}

