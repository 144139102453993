/* ===================================================================+
| WIDGET: TAG ICONS                                                   |
+=================================================================== */

import { defineComponent, h, PropType } from '@vue/runtime-core'
import {
  tags_flame as flame,
  tags_chicken as chicken,
  tags_fish as fish,
  tags_meat as meat,
  tags_vegan as leaf,
  tags_protein as trophy,
  tags_ban as ban,
  tags_special as special,
  tags_salmon as salmon,
  tags_asparagus as asparagus,
  tags_sweet as sweet,
  tags_planted as planted,
  heart as heart,
} from '../assets/async'

const cats = {
  'new': flame,
  'back': flame,
  'veg': leaf,
  'diet': trophy,
  'without': ban,
  'secondary': ban,
  'with': ban, // Fix this later
  'favorite': heart,
  'Special': special,
  'Wagyu': special,
  'Fisch': salmon,
  'Fish': salmon,
  'Asparagus': asparagus,
  'Spargel': asparagus,
  'Sweets': sweet,
  'Extra': undefined,
  'XL Portion': undefined,
}

const names = {
  'meat': meat,
  'chicken': chicken,
  'fish': fish,
  'planted': planted,
}

export const favTag = 'favorite.default.favorite'
export type TagCategory = keyof typeof cats
export type Tags = Record<string, [ TagCategory, string, string ]>

export function isTagCategory(what: string): what is TagCategory {
  return what in cats
}

// Split tag into catrgory, color(string) and name(string)
export function splitTags(tags: string[]): Record<string, [ TagCategory, string, string ]> {
  const record: Record<string, [ TagCategory, string, string ]> = {}
  tags.forEach((tag) => {
    const [ category, color, name ] = tag.split('.')
    if (isTagCategory(category) && color && name) record[tag] = [ category, color, name ]
  })
  return record
}

export default defineComponent({
  inheritAttrs: false,
  props: {
    cat: {
      type: String as PropType<TagCategory>,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
  },
  render() {
    if (this.name && this.name in names) return h(names[this.name as keyof typeof names], this.$attrs)
    else if ((this.cat !== 'XL Portion') && (this.cat !== 'Extra')) return h(cats[this.cat], this.$attrs)
  },
})
