<!--==================================================================+
| WIDGET: DELIVERY SLOTS                                              |
+===================================================================-->

<template>
  <div class="subheading">
    {{ $t( nordfrost ? 'pre-checkout.choose-nordfrost-cw' : 'pre-checkout.choose-delivery-slot' ) }}
  </div>
  <div v-if="selected_slot_uuid" class="close" @click="$emit('active')">
    <span>+</span>
  </div>
  <div ref="wrapper" class="slot-list-wrapper no-scrollbar" :class="{ nordfrost }">
    <!--==================================================================+
    | CAROUSEL                                                            |
    +===================================================================-->
    <juit-carousel
      ref="roller"
      class="gradient-arrow small-arrow desktop-only-gradient mobile-slot:overflow-hidden"
      :card-width="cardwidth"
      :scroll-multiples="window.innerWidth >= 480 ? 2 : 1"
    >
      <div
        v-for="(slot, index) in (nordfrost ? weeks : slots)"
        :key="index"
        ref="card"
        :class="[{ loading }, 'tile']"
        @click="chooseSlot(slot, index)"
      >
        <div
          class="slot"
          :class="{ selected: 'uuid' in slot ? slot.uuid === selected_slot_uuid : slot.cw && slot.cw === selected_week?.cw,
                    urbify_frozen: 'uuid' in slot && (slot.name === 'Urbify Frozen Day' || slot.name === 'Urbify Frozen Evening')
          }"
        >
          <juit-spinner v-if="loading" />
          <div class="slot-content">
            <div class="flex mr-2.5 lg:mr-3 flex-grow">
              <p class="date-part-1">
                {{ nordfrost ? 'CW' + (slot as NordfrostWeek).cw : $getDD((slot as DeliverySlot).date) }}
              </p>
              <div class="date-part-2">
                <span v-if="!nordfrost" class="dd">
                  {{ $getDay((slot as DeliverySlot).date) }}
                </span>
                <span class="mm-yy">
                  {{ nordfrost ? $getDate((slot as NordfrostWeek).firstDay.toString()) + ' –' : $getMMYY((slot as DeliverySlot).date) }}
                </span>
                <span v-if="nordfrost" class="mm-yy">
                  {{ $getDate((slot as NordfrostWeek).lastDay.toString()) }}
                </span>
              </div>
            </div>
            <div class="flex flex-col justify-center">
              <div v-if="!nordfrost" class="clock">
                <p>{{ (slot as DeliverySlot).slot || '0' }}</p>
              </div>
              <div v-if="!modify && !nordfrost" class="price">
                <p>{{ $currencyOld((slot as DeliverySlot).shipping_fee) }}</p>
              </div>
            </div>
            <div v-if="'uuid' in slot && slot.name" class="carrier">
              <div v-if="slot.uuid === selected_slot_uuid">
                <dpd-logo-selected v-if="slot.name.startsWith('DPD')" />
                <urbify-logo-selected v-if="slot.name.startsWith('Urbify')" />
              </div>
              <div v-else>
                <dpd-logo v-if="slot.name.startsWith('DPD')" />
                <urbify-logo v-if="slot.name.startsWith('Urbify')" />
              </div>
            </div>
            <div v-else class="carrier nordfrost">
              <nordfrost-logo />
              <span class="font-bold text-right mt-1 pr-3">{{ $t('checkout.nordfrost-delivery-cost') }}</span>
            </div>
          </div>
          <urbify-frozen-leaf v-if="'uuid' in slot && (slot.name === 'Urbify Frozen Day' || slot.name === 'Urbify Frozen Evening')" class="urbify-leaf" />
        </div>
      </div>
    </juit-carousel>
  </div>
  <div v-if="error" class="invalid-msg -mb-1 relative text-center">
    {{ $t( cant_deliver ? 'checkout.cant-deliver-location' : 'checkout.slot-not-available' ) }}
  </div>
</template>

<script lang="ts">
  import juitCarousel from '../widgets/juit-carousel.vue'
  import type { DeliverySlot, DeliverySlotsImpl } from '@juitnow/api-deliveries'
  import { analyticsEvent } from '../analytics'
  import { client } from '../init/client'
  import { defineComponent } from 'vue'
  import { reactiveCheckout, NordfrostWeek } from '../init/reactive-order'
  import {
    carriers_logo_nordfrost as nordfrostLogo,
    carriers_logo_dpd as dpdLogo,
    carriers_logo_dpd_selected as dpdLogoSelected,
    carriers_logo_urbify as urbifyLogo,
    carriers_logo_urbify_selected as urbifyLogoSelected,
    carriers_leaf as urbifyFrozenLeaf,
  } from '../assets/async'

  const emptySlot = { } as DeliverySlot

  export default defineComponent({
    components: {
      juitCarousel,
      dpdLogo,
      dpdLogoSelected,
      nordfrostLogo,
      urbifyLogo,
      urbifyLogoSelected,
      urbifyFrozenLeaf,
    },
    props: {
      zip: {
        type: String,
        default: '',
      },
      visible: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      modify: {
        type: Boolean,
        required: false,
        default: false,
      },
      price: {
        type: Number,
        required: false,
        default: 0,
      },
      b2b: {
        type: Boolean,
        default: false,
      },
      nordfrost: {
        type: Boolean,
        default: false,
      },
      selectedSlot: {
        type: String,
        default: '',
        required: false,
      },
    },
    emits: [ 'active', 'error', 'modify', 'urbify-frozen', 'scroll-y' ],
    data: (instance) => ({
      window: window,
      loading: instance.nordfrost ? false : true,
      cardwidth: 0,
      slots: [ emptySlot, emptySlot, emptySlot, emptySlot, emptySlot, emptySlot, emptySlot ] as DeliverySlot[],
      weeks: [ ] as NordfrostWeek[],
      week_counts: 4,
      selected_slot_uuid: instance.selectedSlot,
      cant_deliver: false,
    }),
    computed: {
      roller_wrapper() {
        return this.$refs.wrapper as HTMLElement | undefined
      },
      roller() {
        return (this.$refs.roller as InstanceType<typeof juitCarousel>).roller
      },
      slot_selected_ongoing() {
        return reactiveCheckout.slot
      },
      selected_week() {
        return reactiveCheckout.nordfrost_week
      },
      shipping_zip() {
        return this.zip || reactiveCheckout.addresses.shipping?.postal_code
      },
    },

    watch: {
      slots: {
        handler() {
          if (this.cardwidth) return
          this.$nextTick(() => this.getCardWidth())
        },
        immediate: true,
        deep: true,
      },
      /* ========================================================================== *
       * When shipping location changes or error occurs, reload the slots           *
       * -------------------------------------------------------------------------- */
      shipping_zip: {
        handler(zip) {
          if (zip && !this.nordfrost) this.initiateSlots(zip)
        },
        immediate: true,
      },

      b2b() {
        if (this.shipping_zip) this.initiateSlots(this.shipping_zip)
      },

      error(err) {
        if (!err) return
        reactiveCheckout.slot = undefined
        if (this.shipping_zip && !this.nordfrost) this.initiateSlots(this.shipping_zip)
      },
    },

    mounted() {
      if (this.roller_wrapper) this.roller_wrapper.addEventListener('scroll', this.onScrollY)
      window.addEventListener('resize', this.getCardWidth)
      window.addEventListener('keydown', this.closeSlotOptions)
      this.initiateCalendarWeeks(this.week_counts)
    },
    unmounted() {
      if (this.roller_wrapper) this.roller_wrapper.removeEventListener('scroll', this.onScrollY)
      window.removeEventListener('resize', this.getCardWidth)
      window.addEventListener('keydown', this.closeSlotOptions)
    },

    methods: {
      async initiateSlots(zip: string) {
        /* ========================================================================== *
         * Load and select the stored slot                                            *
         * -------------------------------------------------------------------------- */
        this.slots = await (client.deliverySlots as DeliverySlotsImpl).list({ postal_code: zip, b2b: this.b2b })
        if (this.slots?.length) {
          this.cant_deliver = false
          this.slots = this.slots.filter((slot) => slot.available > 0)
          // sort the slots by delivery fee (cheapest first) for EACH DAY individually
          this.sortSlotsByPrice()
          // check if we have Urbify Frozen in order to display the additional info
          const urbifyFrozen = this.slots.some((slot) => slot.name.startsWith('Urbify Frozen'))
          this.$emit('urbify-frozen', urbifyFrozen)
        } else {
          this.$emit('error')
          this.cant_deliver = true
        }
        // If we are modifying an order, only show the slots with same shipping fee
        if (this.modify) this.slots = this.slots.filter((slot) => slot.shipping_fee === this.price)
        // Otherwise, try to choose a slot
        else {
          const valid_stored_slot = this.slot_selected_ongoing ? this.slots.find((slot) => slot.uuid === this.slot_selected_ongoing?.uuid) : undefined
          // If there is a previously selected slot but no longer valid, clear it and pop error message
          if (this.slot_selected_ongoing && !valid_stored_slot) {
            reactiveCheckout.slot = undefined
            this.$emit('error')
          // Otherwise, use the stored slot
          } else if (valid_stored_slot) this.chooseSlot(valid_stored_slot)
        }
        this.loading = false
      },

      initiateCalendarWeeks(week_counts: number) {
        for (let i = 1; i < week_counts + 1; i++) {
          const currentDate = new Date
          const first = currentDate.getDate() + i * 7 - currentDate.getDay() + 1
          const last = first + 4
          currentDate.setDate(first)
          const firstDay = new Date(currentDate)
          const lastDay = new Date((new Date).setDate(last))

          const startDate = new Date(currentDate.getFullYear(), 0, 1)
          const days = Math.floor((Number(currentDate) - Number(startDate)) / (24 * 60 * 60 * 1000))
          const cw = Math.ceil(days / 7) + 1
          this.weeks.push({ cw, firstDay, lastDay })
        }
      },

      chooseSlot(slot: DeliverySlot | NordfrostWeek, index = 0) {
        this.$emit('active')
        if ('cw' in slot) reactiveCheckout.nordfrost_week = slot as NordfrostWeek
        else if ('uuid' in slot) {
          this.selected_slot_uuid = slot.uuid
          // If we are modifying an order, just emit the uuid
          if (this.modify) return this.$emit('modify', slot)

          // Otherwise, update the ongoing order data and fire the GA Event: Choose slot
          else if (slot.uuid && slot.uuid !== this.slot_selected_ongoing?.uuid) {
            reactiveCheckout.slot = slot
            analyticsEvent('select_delivery_window', {
              delivery_date: this.slot_selected_ongoing!.date,
              delivery_slot: this.slot_selected_ongoing!.slot,
            })
          }
        }
      },

      closeSlotOptions(e: KeyboardEvent) {
        if ((e.key === 'Escape' || e.key === 'Esc') && this.selected_slot_uuid) this.$emit('active')
      },

      sortSlotsByPrice() {
        let index = 0
        let sortedSlots = [] as DeliverySlot[]

        while (index < this.slots.length) {
          // get the slots for the same day
          const slotsArray = this.slots.filter((slot) => slot.date === this.slots[index].date)
          // sort by shipping fee
          slotsArray.sort((a, b) => a.shipping_fee - b.shipping_fee)
          // save in temporary array and update index for next iteration
          sortedSlots = [ ...sortedSlots, ...slotsArray ]
          index = index + slotsArray.length
        }
        this.slots = sortedSlots
      },

      // Carousel Related
      getCardWidth() {
        const cards = this.$refs.card as HTMLElement[]
        if (cards?.length) this.cardwidth = cards[0].getBoundingClientRect().width
      },

      onScrollY() {
        if (!this.roller_wrapper || !this.roller) return
        const rollerHeight = this.roller.clientHeight
        const scrollValue = this.roller_wrapper.scrollTop
        this.$emit('scroll-y', scrollValue < 10 ? 'at_start' :
          scrollValue > rollerHeight - this.roller_wrapper.clientHeight - 2 ? 'at_end' : 'scrolling')
      },
    },

  })
</script>

<style scoped lang="pcss">
  .subheading {
    @apply font-semibold w-full text-center;
  }
  .close {
    @apply font-mono text-2xl sm:text-3xl !absolute -top-1 sm:-top-1.5 w-8 h-8 -right-2;
    span {
      @apply absolute leading-tight transform rotate-45 text-center w-8;
    }
  }
  .slot-list-wrapper {

    :deep() .arrow-wrapper {
      @apply -left-2;
      &.right {
        @apply left-auto -right-2;
      }
    }
    :deep() .roller-wrapper{
      @apply -mx-1 mobile-slot:mx-0;
      .tiled {
        @apply mobile-slot:flex-wrap mx-0;
        .tile {
          @apply cursor-pointer;
          @apply py-2 mobile-slot:py-1;
          @apply mobile-slot:flex-card-1/1 flex-card-2/5 md-slot:flex-card-2/7 lg-slot:flex-card-2/9 xl-slot:flex-card-2/11;
          .slot {
            @apply h-full min-h-12 overflow-hidden;
            @apply mobile-slot:px-4 px-3 xl:px-5 mobile-slot:py-1 py-2;
            @apply border-2 border-transparent transition-all;
            @apply flex relative text-sm shadow-juit bg-white;
            &:hover {
              @apply border-black;
            }
            &.urbify_frozen {
              @apply !bg-greenUrbify/20;
            }
            .slot-content {
              @apply flex mx-auto flex-grow relative z-10;
              .date-part-1 {
                @apply font-headline text-5xl self-center mobile-slot:w-10 mobile-slot:text-center;
              }
              .date-part-2 {
                @apply flex flex-col self-center text-left;
                @apply mx-auto mobile-slot:pl-3 pl-2.5 xl:pl-4;
                @apply flex-grow;
                .dd {
                  @apply tracking-tight -mb-0.5 font-semibold text-base md:text-lg lg:text-xl mobile-slot:text-lg;
                }
                .mm-yy {
                  @apply text-xs sm:text-sm opacity-60;
                }
              }
            }
            .clock, .price {
              @apply my-0.5 font-bold;
            }
            &.selected {
              @apply border-black !bg-black;
              .dd, .mm-yy, p {
                @apply text-ash;
              }
              .urbify-leaf {
                @apply opacity-30;
              }
            }
          }

          &.loading .slot-content {
            @apply opacity-0;
          }
        }
      }
    }

    &.nordfrost {
      :deep() .roller-wrapper .tiled .tile {
        @apply mobile-slot:flex-card-1/1 flex-card-1/2 md:flex-card-3/7 md-slot:flex-card-2/5 md-slot-dashboard:flex-card-1/3 lg-slot:flex-card-1/4;
        .slot .slot-content {
          .date-part-1 {
            @apply mobile-slot:w-24 pl-2;
          }
          .date-part-2 {
            @apply pl-5;
          }
        }
      }
    }
  }

  .short .roller-wrapper .tiled .tile {
    @apply mobile-slot:flex-card-1/1 flex-card-2/5 md:flex-card-1/2 lg:flex-card-2/5 xl:flex-card-1/3 xl-slot:flex-card-2/7;
    .carrier {
      @apply lg:w-12 lg:-mr-1 xl:w-14 xl:-mr-1 xl:ml-2.5 xxl:w-20 xxl:ml-3 xxl:mr-0 xxl:ml-4;
    }
    .slot .slot-content .date-part-2 {
      @apply xl:pl-2.5 xxl:pl-4;
    }
    .clock p {
      @apply mobile-slot:text-lg;
    }
  }

  .white-carousel-bg {
    .slot-list-wrapper .tiled .tile .slot {
      @apply bg-ash;
      &.selected {
        @apply border-black bg-black;
        .carrier.nordfrost {
          @apply text-white;
          :deep() svg {
            .nordfrost-text {
              @apply !fill-white;
            }
          }
        }
      }
    }
  }

  .carrier {
    @apply mobile-slot:ml-5 ml-2 lg:ml-2.5 lg:-mr-0.5 xl:ml-3.5 xl:mr-0;
    @apply relative top-1/2 transform -translate-y-1/2 w-14;
    @apply flex flex-col justify-center;
    &.nordfrost {
      @apply w-28;
    }
  }
  .urbify-leaf {
    @apply transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 absolute py-2.5;
    @apply h-full fill-white opacity-75 h-3/2;
  }
</style>
