/* ========================================================================== *
 * RANDOM USER COHORT                                                         *
 * ========================================================================== */

import { userCohort } from './state'
import { log } from './log'

const cohort_key = 'jn:cohort'

function random1to12(): number {
  return parseInt(crypto.randomUUID().substring(24), 16) % 12 + 1
}

export function initCohort(): void {
  const localData = localStorage?.getItem(cohort_key)
  userCohort.value = localData && Number(localData) ? Number(localData) : random1to12()
  localStorage?.setItem(cohort_key, JSON.stringify(userCohort.value))
  log(`%cCohort assigned: ${userCohort.value}`, 'color: green')
}
