<template>
  <div class="progress-bar">
    <div class="points">
      <div
        v-for="(point, index) in thresholds"
        :key="index"
        class="point"
        :class="getClass(point, index)"
        :style="`width: ${getWidth(point, index, thresholds.slice(-1)[0]) * 100}%;`"
      >
        <span v-if="shownumber" class="number">{{ point }}<span v-if="nomax && index === (thresholds.length - 1)">+</span></span>
        <span class="bar">
          <span
            v-if="getClass(point, index) === 'reaching'"
            class="counter"
            :style="`width: ${((current - (thresholds[index - 1] || 0)) / (point - (thresholds[index - 1] || 0))) * 100}%`"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'

  export default defineComponent({
    props: {
      thresholds: {
        type: Array as PropType<number[]>,
        required: true,
      },
      current: {
        type: Number,
        default: 0,
      },
      goal: {
        type: Number,
        default: 0,
      },
      shownumber: {
        type: Boolean,
        default: false,
      },
      nomax: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      getWidth(point: number, index: number, reference: number) {
        const net_value = index === 0 ? point : point - this.thresholds[index - 1]
        return net_value / reference
      },

      getClass(point: number, index: number) {
        if (this.current >= point) return 'reached'
        if ((this.current < point) && (this.current > (this.thresholds[index - 1] || 0))) return 'reaching'
        if ((this.current < point) && (this.current < this.goal) && (point <= this.goal)) return 'notyet'
      },

      getCounter() {

      },
    },
  })
</script>

<style scoped lang="pcss">
  .progress-bar {
    @apply w-full;
    .points {
      @apply flex justify-between overflow-visible;
      margin-left: -1px;
      margin-right: -2px;
      .point {
        margin-left: 1px;
        margin-right: 2px;
        @apply flex-grow;
        .bar {
          @apply relative block bg-gray-200 h-1.5 md:h-2 w-full;
        }
        .number {
          @apply text-right block -mr-2 -mt-1 mb-0.5;
        }
        &:first-child .bar {
          @apply rounded-l-sm;
        }
        &:last-child .bar {
          @apply rounded-r-sm;
        }
        &.reached .bar {
          @apply bg-greenUrbify;
        }
        &.notyet .bar {
          @apply bg-greenUrbify/30;
        }
        &.reaching .bar {
          @apply bg-greenUrbify/30;
          .counter {
            @apply absolute h-full bg-greenUrbify;
          }
        }
      }
    }
  }
</style>
