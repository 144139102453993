<!-- eslint-disable vue/no-v-html -->
<!--==================================================================+
| WIDGET: GLOBAL RATING                                               |
+===================================================================-->

<template>
  <div v-if="!loading" class="rating-container">
    <div class="content-group cursor-pointer" tabindex="0">
      <juit-info class="w-4 self-center mr-1" />
    </div>
    <div class="toggle" />
    <div class="tooltip">
      <div class="bg" />
      <juit-info class="w-6 self-center mr-2 flex-shrink-0 z-1" />
      <span class="z-1" v-html="$t( 'global-rating.legal-text' )" />
    </div>
    <div class="content-group">
      <p class="global-rating-text">
        {{ $number(stats.total) }} {{ $t( 'global-rating.reviews' ) }}
      </p>
      <p class="global-rating-text padding">
        |
      </p>
    </div>
    <div class="content-group stars">
      <p class="global-rating-text bold">
        {{ stats.avg }}
      </p>
      <juit-stars class="text-yellow global-rating-stars fill-current" :value="Math.round(stats.avg*4)/4" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Statistics } from '@juitnow/api-comments'
  import juitStars from '../widgets/juit-stars'
  import { checkout_info as juitInfo } from '../assets/async'
  import { fetchGlobalRatingStats } from '../content/fetch'

  export default defineComponent({
    components: { juitStars, juitInfo },
    data: () => ({
      loading: true,
      stats: {
        total: 0,
        avg: 0,
      } as Statistics,
    }),

    async mounted() {
      this.stats = await fetchGlobalRatingStats() || this.stats
      this.loading = false
    },
  })

</script>

<style scoped lang="pcss">
  .rating-container {
    @apply w-full;
    @apply flex flex-row justify-end items-center;
    @apply self-end relative;
  }
  .toggle {
    @apply relative w-0;
    &:before {
      @apply absolute w-4 h-4 -left-5 -top-1.5 pointer-events-none cursor-pointer;
      content: '';
    }
  }
  .content-group {
    @apply flex flex-row justify-start items-center mt-1 relative;
  }
  .tooltip {
    @apply cursor-default absolute right-0 bottom-9 w-full sm:w-80;
    @apply p-2.5 text-xs bg-white;
    @apply flex-row justify-center hidden;
    .bg {
      @apply absolute w-full h-full z-0 top-0;
      @apply bg-greenUrbify/20;
    }
  }
  .content-group:focus + .toggle:before {
    @apply pointer-events-auto;
  }
  .content-group:focus + .toggle +.tooltip {
    @apply flex;
  }
  .global-rating-text {
    @apply text-lg lg:text-xl;
  }
  .global-rating-stars {
    @apply w-32 md:w-40;
  }
  .padding {
    @apply px-1;
  }
  .bold {
    @apply font-bold;
    @apply pr-2;
  }
</style>
