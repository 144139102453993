<template>
  <div v-intersect="onIntersection" class="image-container">
    <img
      ref="placeholder"
      class="placeholder"
      alt="placeholder"
      :src="placeholder"
    >
    <img
      v-if="preview && !loaded && !contentType?.includes('svg')"
      class="preview"
      :class="fit"
      :src="lowres"
    >
    <img
      v-if="sources"
      v-bind="{ ...$attrs, ...sources }"
      class="image"
      :class="[ { loading }, fit ]"
      @load="onLoad"
    >
    <juit-spinner v-if="loading" class="large max-w-full p-2" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'

  // Placeholders and respective ratios
  const placeholders = {
    '4x3': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAQAAAAe/WZNAAAADklEQVR42mNkgAJGDAYAAFEABCaLYqoAAAAASUVORK5CYII=',
    '5x3': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAADCAQAAADxPw1zAAAADklEQVR42mNkgANGrEwAAGMABP93vXMAAAAASUVORK5CYII=',
    '1x1': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
  }
  const ratios: Record<keyof typeof placeholders, number> = {
    '4x3': 1 * 4 / 3,
    '5x3': 1 * 5 / 3,
    '1x1': 1,
  }

  export default defineComponent({
    inheritAttrs: false,
    props: {
      src: {
        type: String as PropType<string | null | undefined>,
        default: undefined,
      },
      ratio: {
        type: String as PropType<keyof typeof placeholders>,
        default: '4x3',
      },
      contentType: {
        type: String as PropType<string | undefined>,
        default: undefined,
      },
      preview: {
        type: Boolean,
        default: true,
      },
      quality: {
        type: Number,
        required: false,
        default: 75,
        validator: (q: number) => (q >= 0) && (q <= 100),
      },
      // this needs to be a prop, as we use it also to tell contentful
      // _how_ to rescale the image to our intended size...
      fit: {
        type: String as PropType<'cover' | 'contain'>,
        default: 'cover',
      },
    },

    data() {
      return {
        sources: undefined as Record<string, string> | undefined,
        placeholder: placeholders[this.ratio],
        lowres: undefined as string | undefined,
        visible: false,
        loading: false,
        loaded: false,
      }
    },

    watch: {
      src() {
        this.lowres = undefined
        this.sources = undefined
        this.loading = false
        this.onIntersection(this.visible)
      },
    },

    methods: {
      onIntersection(visible: boolean) {
        if (visible && this.src && (! this.sources)) {
          const { src, contentType, fit } = this

          // SVG images are downloaded "as-is"
          if (contentType === 'image/svg+xml') {
            this.sources = { src }
            this.loading = true
            return
          }

          const sources = {} as Record<string, string>
          const source = new URL(src, window.location.href)

          const format =
            contentType === 'image/gif' ? 'gif' : // gifs come as-is
            contentType === 'image/png' ? window.webpSupport ? 'webp' : 'png' :
            contentType === 'image/jpeg' ? window.webpSupport ? 'webp' : 'jpg' :
            window.webpSupport ? 'webp' : 'jpg' // all other are webp or jpg

          source.searchParams.set('fm', format)
          source.searchParams.set('q', '' + this.quality)
          if (fit === 'cover') source.searchParams.set('fit', 'fill')

          // Calculate width and height of the source / sourceset / preview
          // images to request to Contentful. We calculate everything according
          // to our aspect ratio and constrain to a 50px based grid (so that
          // Contentful can more easily cache images)

          const placeholder = this.$refs['placeholder'] as HTMLImageElement
          const { offsetWidth: w, offsetHeight: h } = placeholder
          if (w & h) {
            // Scale width and height according to ratio vertically and horizontally
            const [ vw, vh ] = [ h * ratios[this.ratio], h ]
            const [ hw, hh ] = [ w, w / ratios[this.ratio] ]
            const [ vsize, hsize ] = [ vw * vh, hw * hh ]

            // The image width and height is the bigger (or smaller) if the fit
            // is cover (or contain)
            const [ iw, ih ] =
              this.fit === 'cover' ?
                vsize > hsize ? [ vw, vh ] : [ hw, hh ] :
                vsize > hsize ? [ hw, hh ] : [ vw, vh ]

            // Calculate image width and height to the next step (50px)
            const s = 50
            const [ sw, sh ] = [
              (Math.floor(iw / s) + (iw % s ? 1 : 0)) * s,
              (Math.floor(ih / s) + (ih % s ? 1 : 0)) * s,
            ]

            // Scale stepped width and height according to ratio
            const [ svw, svh ] = [ sh * ratios[this.ratio], sh ]
            const [ shw, shh ] = [ sw, sw / ratios[this.ratio] ]
            const [ svsize, shsize ] = [ svw * svh, shw * shh ]

            // The request width and height is the bigger of the stepped ones
            const [ rw, rh ] = svsize > shsize ?
              [ Math.floor(svw), Math.floor(svh) ] :
              [ Math.floor(shw), Math.floor(shh) ]

            source.searchParams.set('w', '' + rw)
            source.searchParams.set('h', '' + rh)

            // Calculate the scaled "srcset" for the image
            sources.srcset = [ 2, 1.5, 1 ].map((scale) => {
              const [ scaledw, scaledh ] = [
                Math.floor(rw * scale),
                Math.floor(rh * scale),
              ]

              // Limit each entry in the sourceset to 3Mpx (2000x1500 at 1x1)
              if ((scaledw * scaledh) > 3000000) return

              // Inject a srcset component
              const scaled = new URL(source)
              scaled.searchParams.set('w', '' + scaledw)
              scaled.searchParams.set('h', '' + scaledh)
              return `${scaled.href} ${scale}x`
            }).filter((source) => !! source).join(', ')

            if (this.preview) {
              const lowres = new URL(source)
              lowres.searchParams.set('h', '' + Math.floor(rh / 5))
              lowres.searchParams.set('w', '' + Math.floor(rw / 5))
              lowres.searchParams.set('q', '1')
              this.lowres = lowres.href
            }
          }

          sources.src = source.href

          this.loading = true
          this.sources = sources
        }
        this.visible = visible
      },
      onLoad(event: Event) {
        this.loading = false
        setTimeout(() => this.loaded = true, 350)
      },
    },
  })
</script>

<style scoped lang="pcss">
  div.image-container {
    @apply opacity-100 w-full h-full relative;
    overflow: hidden;
  }

  img.placeholder {
    @apply w-full h-full object-cover;
  }

  img.preview {
    @apply w-full absolute;
    @apply filter blur-lg saturate-50 opacity-40;

    &.cover {
      @apply w-full absolute h-full top-0 left-0;
      @apply object-cover;
    }

    &.contain {
      @apply w-full absolute h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
      @apply object-contain;
    }
  }

  img.image {
    @apply w-full absolute pointer-events-none;
    @apply transition-opacity duration-300;
    @apply opacity-100;

    &.cover {
      @apply w-full absolute h-full top-0 left-0;
      @apply object-cover;
    }

    &.contain {
      @apply w-full absolute h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
      @apply object-contain;
    }

    &.loading {
      @apply opacity-0;
    }
  }
</style>
