<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="no-result">
    <div class="box">
      <no-result-svg class="w-10 md:w-16 mr-5 relative z-1 flex-shrink-0" />
      <div v-if="isfav" class="heart-wrapper">
        <heart class="w-3 md:w-5" />
      </div>
      <div class="flex flex-col">
        <span class="uppercase font-bold" v-html="$t( isfav ? 'dishes.no_fav_1' : 'dishes.no_result_1')" />
        <span>{{ $t( isfav ? 'dishes.no_fav_2' : 'dishes.no_result_2') }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { no_result as noResultSvg, heart } from '../assets/async'

  export default defineComponent({
    components: { noResultSvg, heart },
    props: {
      isfav: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  })
</script>

<style scoped lang="pcss">
  .no-result {
    @apply w-full;
    .box {
      @apply relative border border-black flex mx-auto max-w-md;
      @apply px-6 py-5;
      .heart-wrapper {
        @apply absolute text-red top-1/2 transform -translate-x-1/2 z-0;
        @apply -mt-2.5 left-10 md:left-12 md:-mt-4 md:pl-0.5;
      }
    }
  }

</style>
