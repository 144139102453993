<script lang="ts">
  import { defineComponent, h, PropType } from 'vue'
  import { RouterLink, RouteLocationRaw } from 'vue-router'

  export default defineComponent({
    props: {
      href: {
        type: String as PropType<string | undefined>,
        required: false,
        default: undefined,
      },
      to: {
        type: [ Object, String ] as PropType<RouteLocationRaw | string | undefined>,
        required: false,
        default: undefined,
      },
    },
    render() {
      const node =
        this.to ? h<any>(RouterLink, { ...this.$attrs, to: this.to }, this.$slots) :
        this.href ? h('a', { ...this.$attrs, href: this.href }, this.$slots) :
        h('button', { ...this.$attrs }, this.$slots)
      return node
    },
  })

</script>

<style scoped lang="pcss">
  a, button {
    line-height: 100%!important;
    padding-top: 3px;
    padding-bottom: 2px;
    @apply inline-block relative;
    @apply px-2 tablet:py-1;

    @apply border border-blue;
    @apply bg-blue;
    @apply text-white;

    @apply transition-all duration-100;
    @apply text-3xl xl:text-4xl;
    @apply uppercase font-headline tracking-wide top-0 left-0;
    @apply hover:shadow-none hover:bg-dblue hover:border-dblue;

    &.small {
      @apply text-lg font-text font-bold;
      @apply px-6 py-2;
    }

    &.label {
      @apply text-sm font-normal py-1 px-5;
    }

    &.outline {
      @apply outline-none bg-transparent hover:bg-blue border-blue;
      @apply text-blue hover:text-white;
    }
  }
  button:disabled, .disabled {
    @apply pointer-events-none;
    @apply bg-gray-300 border-gray-300;
    &.outline {
      @apply border-gray-300 bg-transparent text-gray-300;
    }
  }
</style>
