<!-- eslint-disable vue/no-v-html -->
<!--==================================================================+
| MODULE-COMPONENT: SINGLE PANEL                                      |
+===================================================================-->

<template>
  <div v-if="module" class="container">
    <div class="tiled my-0">
      <div v-if="active_list" class="tile panel-text mobile_only">
        <h1 v-html="module.title" />
        <rich-text
          v-if="module.description"
          :document="module.description"
          class="subheading"
        />
      </div>
      <div class="tile panel-text relative z-1" :class="{ with_list: active_list }">
        <h1 v-html="module.title" />
        <rich-text
          v-if="module.description"
          :document="module.description"
          class="subheading"
        />

        <!--==================================================================+
        | MULTIPLE BUNDLE OPTIONS                                             |
        +===================================================================-->
        <div v-if="multiple_bundle" class="tiled boxes" :style="`padding-bottom: ${cardwidth + 34}px`">
          <div
            v-for="list in module.preFilledCarts"
            :key="list.slug"
            class="tile"
            :class="{ selected: active_list?.slug === list.slug }"
            @click="active_list = list"
          >
            <div class="box-text" :class="`box-${list.bundleSize}`">
              <div class="icon-wrapper">
                <boxIcon class="box-icon" />
              </div>
              <span class="box-title">{{ list.title }}</span>
            </div>
          </div>
        </div>
        <juit-social-media-links v-if="module.socialMediaLinks" class="reverse md:mb-2 md:mt-7" />

        <!--==================================================================+
        | BUNDLE INFO AND CTA                                                 |
        +===================================================================-->
        <div v-if="active_list?.slug" ref="bundle_cta">
          <div class="box-info-top">
            <p v-if="pre_order_data">
              <span class="font-bold">
                <span v-if="voucher" class="opacity-50 line-through">{{ $currency(pre_order_data.summary.subtotal) }}</span>
                {{ $currency(pre_order_data.summary.total) }}
              </span> {{ $t('order.shipping_cost_info') }}
            </p>
            <span class="md:text-sm md:mt-0.5">{{ $t('boxes.delivery-info') }}</span>
          </div>
          <juit-button
            ref="cta"
            :to="`/${$i18n.locale}/carts/${active_list?.slug}`"
            class="mt-4 button"
          >
            <span class="cta-btn-text">{{ module.cta?.text }}</span>
          </juit-button>
          <div ref="cta_bot" class="box-info-bot">
            <div class="payment-icons">
              <payments-visa />
              <payments-master />
              <payments-amex />
              <payments-paypal />
              <payments-gpay />
              <payments-apple />
              <payments-klarna />
            </div>
            <router-link class="juit-link" :to="`/${$i18n.locale}/order`">
              {{ $t('boxes.create-box') }}
            </router-link>
          </div>
        </div>

        <!--==================================================================+
        | REGULAR CTA BUTTON                                                  |
        +===================================================================-->
        <juit-button
          v-if="!active_list && module.cta?.slug"
          ref="cta"
          :to="`/${$i18n.locale}/${module.cta?.slug}`"
          class="mt-4 button"
        >
          <span class="cta-btn-text">{{ module.cta?.text }}</span>
        </juit-button>
        <juit-button
          v-else-if="module.ctaExternal?.slug"
          :href="`${module.ctaExternal.slug}`"
          target="_blank"
          class="mt-4"
          @click="externalTracking(module.ctaExternal.slug)"
        >
          <span class="cta-btn-text">{{ module.ctaExternal.text }}</span>
        </juit-button>
      </div>

      <div class="tile panel-media" :class="{ multiple_bundle }" :style="active_list ? `margin-bottom: ${cardwidth + 30}px` : ''">
        <juit-image
          v-if="module.image.contentType.includes('image')"
          v-bind="module.image"
          :class="{ clipped: !module.shrink }"
          :fit="module.shrink ? 'contain' : 'cover'"
        />
        <juit-video
          v-if="module.image.contentType.includes('video')"
          v-bind="module.image"
          loop
          muted
        />

        <!--==================================================================+
        | BUNDLE CAROUSEL                                                     |
        +===================================================================-->
        <div v-if="active_list && available_eans.length" class="carousel-wrapper" :style="[`bottom: -${cardwidth + 38}px`, multiple_bundle ? `top: -${cardwidth + bundle_cta_height}px` : '']">
          <juit-carousel
            class="dish-carousel solid-arrow small-arrow"
            :card-width="cardwidth"
            :scroll-multiples="2"
            :dynamic-arrow-height="true"
          >
            <juit-dish-card
              v-for="(product, index) in availableBundleDishes(active_list)"
              :key="index"
              :index="index"
              :product="product"
              :cta="false"
              @cardwidth="cardwidth = $event"
            />
          </juit-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Panel, PreFilledCart } from '../content'
  import juitSocialMediaLinks from '../widgets/juit-social-media-links.vue'
  import juitCarousel from '../widgets/juit-carousel.vue'

  import {
    box as boxIcon,
    payment_amex as paymentsAmex,
    payment_gpay as paymentsGpay,
    payment_klarna2 as paymentsKlarna,
    payment_master as paymentsMaster,
    payment_ppal as paymentsPaypal,
    payment_apple as paymentsApple,
    payment_visa as paymentsVisa,
  } from '../assets/async'
  import { client } from '../init/client'
  import { env } from '../init/env'
  import { PreorderRequest, PreorderResponse, ProductData } from '@juitnow/api-orders-v2'
  import { available_eans } from '../init/inventories'

  export default defineComponent({
    name: 'JuitPanel',
    components: {
      juitSocialMediaLinks,
      juitCarousel,
      paymentsAmex,
      paymentsGpay,
      paymentsMaster,
      paymentsPaypal,
      paymentsVisa,
      paymentsKlarna,
      paymentsApple,
      boxIcon,
    },
    props: {
      module: {
        type: Object as PropType<Panel>,
        required: true,
      },
    },
    data: (instance) => ({
      active_list: instance.module.preFilledCarts?.length ? instance.module.preFilledCarts[0] : undefined,
      cardwidth: 0,
      pre_order_data: undefined as undefined | PreorderResponse,
      bundle_cta_height: 0,
    }),

    computed: {
      multiple_bundle() {
        return this.module.preFilledCarts && this.module.preFilledCarts.length > 1
      },

      available_eans() {
        return available_eans.value
      },

      active_list_as_lines() {
        const lines = {} as Record<string, number>
        this.availableBundleDishes(this.active_list)?.map((dish) => dish.ean).forEach((ean) => {
          if (ean in lines) lines[ean] += 1
          else lines[ean] = 1
        })
        return Object.entries(lines).map((line) => {
          return { ean: line[0], quantity: line[1] }
        })
      },

      voucher() {
        return this.pre_order_data?.lines.find((line) => line.type === 'discount')?.unit_price || 0
      },
    },

    // Get box price when active list changes
    watch: {
      active_list_as_lines: {
        async handler(lines) {
          if (!lines.length) return
          await this.getBundlePrice(lines)
          this.getBundleCTAHeight()
        },
        immediate: true,
        deep: true,
      },
    },

    mounted() {
      window.addEventListener('resize', this.getBundleCTAHeight)
    },
    unmounted() {
      window.removeEventListener('resize', this.getBundleCTAHeight)
    },

    methods: {
      availableBundleDishes(bundle: PreFilledCart | undefined) {
        if (!bundle) return
        return bundle.list.filter((dish) => this.available_eans.includes(dish.ean)).slice(0, bundle.bundleSize)
      },

      getBundleCTAHeight() {
        if (!this.$refs.bundle_cta) return
        this.bundle_cta_height = (this.$refs.bundle_cta as HTMLElement).clientHeight + 40
      },

      async getBundlePrice(lines: ProductData[]) {
        const options = { lines: lines, location_uuid: env.VITE_DEFAULT_DELIVERY_LOCATION as string & { __uuid: never; }, locale: this.$i18n.locale, options: { skip_inventory_check: true, skip_sellable_check: true } } as PreorderRequest
        if (this.active_list?.voucher) options.voucher_code = this.active_list.voucher
        this.pre_order_data = await client.ordersV2.preorder(options)
      },

      externalTracking(url: string) {
      },
    },
  })
</script>

<style scoped lang="pcss">
.tiled {
  @apply mx-0;
}
  .tile {
    @apply w-full;

    &.panel-text {
      @apply md:w-1/3 md:text-left mobile:order-1 self-center pb-0 pt-6 md:py-10 lg:py-14 xl:py-20;
      @apply pl-0 md:pr-8 lg:pr-10 text-left;

      .subheading {
        @apply text-lg md:text-xl leading-normal;
        :deep() {
          li {
            @apply mobile:m-0 mobile:block;
          }
        }
      }
      .boxes {
        @apply sm:-mx-1 mb-2 md:my-2 flex-wrap md:pt-2 md:!pb-0;
        .tile {
          max-width: calc(50% - 8px);
          @apply relative bg-gray-200 transition-all;
          @apply mobile-xs:max-w-[100%] w-full md:w-auto;
          @apply flex sm:flex-col sm:flex-margin-1/2 flex-grow-0;
          @apply py-2 md:py-2 lg:py-3 xl:py-4 mb-2 mx-auto sm:mx-1;
          @apply cursor-pointer;
          .box-text {
            @apply flex sm:flex-col md:flex-1 md:mt-1;
            @apply justify-center;
            .box-title {
              @apply px-3 md:px-0 md:mt-1 text-left md:text-center self-center;
              @apply whitespace-nowrap font-bold text-xl sm:text-2xl md:text-xl xl:text-3xl xl:mb-1 font-headline uppercase;
            }
          }
          .icon-wrapper {
            @apply mobile:-order-1;
          }
          :deep() svg.box-icon {
            @apply ml-1 sm:mx-auto my-0.5;
            @apply w-7 sm:w-9 md:w-10 xl:w-12;
          }
          &.selected {
            @apply bg-black text-white;
          }
        }
      }
      .box-info-top {
        @apply mt-5 -mb-2 flex flex-row justify-between md:flex-col;
      }
      .box-info-bot {
        @apply mt-2.5;
        .payment-icons {
          @apply flex mobile:justify-center;
          svg {
            @apply mr-0.5 w-8 mb-4;
          }
        }
        .juit-link {
          @apply mobile:block mobile:text-center;
        }
      }
      &.mobile_only {
        @apply -order-1 pt-0 pb-4 px-0 md:hidden;
      }
      &.with_list {
        @apply mobile:pt-0 mobile:px-0;
        h1, .subheading {
          @apply mobile:hidden;
        }
        .button {
          @apply mobile:w-full mobile:text-center;
        }
      }
    }
    &.panel-media {
      @apply relative md:w-2/3;
      @apply min-h-1/3-screen-h md:min-h-1/2-screen-h;
      @apply p-0 mobile:w-full;
      .image-container {
        @apply md:absolute w-full left-0 top-0;
      }
      &.multiple_bundle {
        @apply mobile:!mb-0 mobile:min-h-0 mobile:order-1;
        .image-container {
          @apply mobile:hidden;
        }
        .carousel-wrapper {
          @apply mobile:mb-2 md:!top-auto md:bottom-0;
        }
      }
      .carousel-wrapper {
        width: calc(100% + 2rem);
        @apply absolute overflow-hidden;
        @apply -left-4 md:w-full md:left-0;
        :deep() {
          .arrow-wrapper {
            @apply -top-1;
          }
          .roller-wrapper {
            @apply -mx-1 px-4 md:px-0;
            &:before, &:after {
              content: '';
              @apply md:hidden;
              @apply bg-white absolute h-full w-4 z-20;
            }
            &:before {
              @apply left-0;
            }
            &:after {
              @apply right-0;
            }
            .tile.dish-card {
              @apply w-1/2 sm:w-1/3 sm-order:w-1/4 md:w-1/5 py-0;
              .dish-details {
                @apply mt-1.5 min-h-0 h-8;
                .title {
                  @apply text-xs xl:text-sm xxl:text-base;
                }
                .nutritions {
                  @apply hidden;
                }
              }
              &:hover {
                .dish-details .title {
                  @apply opacity-100;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
