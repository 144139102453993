<!--==================================================================+
| MODULE-COMPONENT: FAQ                                               |
+===================================================================-->

<template>
  <div v-if="module" class="container faq">
    <h1>{{ module.title }}</h1>

    <!--==================================================================+
    | SIMPLE LIST                                                         |
    +===================================================================-->
    <div v-if="module.layout === 'Simple'" class="simple">
      <div
        v-for="(qa, qa_index) in module.list"
        :key="qa_index"
        :class="[ { active: active_faq.nocat.includes(qa_index) }, 'box']"
        :style="`--tw-delay: ${qa_index + 1}`"
      >
        <div class="single-qa">
          <div class="arrow-indicator" @click="toggleFaq(qa_index)">
            <div class="arrow" />
            <p>{{ qa.title }}</p>
          </div>
          <div
            :class="[ 'answer', active_faq.nocat.includes(qa_index) ?
              '[transition:all_.3s,max-height_.3s_cubic-bezier(1,0,1,0)]' :
              '[transition:all_.3s,max-height_.3s_cubic-bezier(0,1,0,1)]']"
          >
            <rich-text :document="qa.description" />
          </div>
        </div>
      </div>
    </div>

    <!--==================================================================+
    | CATEGORIZED LIST                                                    |
    +===================================================================-->
    <div v-if="module.layout === 'Categorized'" class="categorized">
      <div
        v-for="(cat, cat_index) in Object.keys(categorized)"
        :key="cat_index"
        class="box"
        :style="`--tw-delay: ${cat_index + 1}`"
      >
        <span class="cat">{{ $t(`qa-categories.${cat}`) }}</span>
        <div
          v-for="(qa, qa_index) in categorized[cat]"
          :id="`${cat}-${qa_index}`"
          :key="qa_index"
          :class="[ { active: active_faq[cat] && active_faq[cat].includes(qa_index) }, 'single-qa' ]"
        >
          <div class="arrow-indicator" @click="toggleFaq(qa_index, cat)">
            <div class="arrow" />
            <p>{{ qa.title }}</p>
          </div>
          <div
            :class="[ 'answer', active_faq[cat] && active_faq[cat].includes(qa_index) ?
              '[transition:all_.3s,max-height_.3s_cubic-bezier(1,0,1,0)]' :
              '[transition:all_.3s,max-height_.3s_cubic-bezier(0,1,0,1)]']"
          >
            <rich-text :document="qa.description" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { QA, QAList } from '../content/convert'

  export default defineComponent({
    name: 'JuitFaq',
    props: {
      module: {
        type: Object as () => QAList,
        default: null,
      },
    },

    data: () => ({
      active_faq: {
        nocat: [],
      } as Record<string, number[]>,
    }),

    computed: {
      /* ==================================================================== *
       * Sorting single QAs                                                   *
       * -------------------------------------------------------------------- */
      categorized(): Record<string, QA[]> {
        if (this.module.layout !== 'Categorized') return {}
        else {
          const qa_list: QA[] = this.module?.list
          return qa_list.reduce(function(sort, qa) {
            sort[qa.category] = sort[qa.category] || []
            sort[qa.category].push(qa)
            return sort
          }, Object.create(null))
        }
      },
    },

    mounted() {
      // Check if we need to lead the user to a specific QA
      const targetID = (new URLSearchParams(globalThis.window?.location.search)).get('target')
      if (!targetID) return
      const [ cat, index ] = targetID.split('-')
      const targetDOM = document.querySelector(`#${targetID}`)
      if (targetDOM && cat && index) {
        this.toggleFaq(Number(index || 0), cat)
        targetDOM.scrollIntoView()
      }
    },

    methods: {
      toggleFaq(target: number, cat?: string) {
        const active_cat = cat || 'nocat'
        const qa_arr = this.active_faq[active_cat] = this.active_faq[active_cat] || []
        if (qa_arr.includes(target)) qa_arr.splice(qa_arr.indexOf(target), 1)
        else qa_arr.push(target)
      },
    },

  })
</script>

<style scoped lang="pcss">

/* ====================================================================== *
 * Global styles                                                          *
 * ====================================================================== */
.simple, .categorized {
  @apply mt-4;
}
.box {
  @apply text-left transition-all;
  .cat {
    @apply block font-bold uppercase text-lg lg:text-xl xl:text-2xl mb-3;
  }
  .single-qa {
    @apply pt-3 border-t border-gray-300 transition-all;
    &.active {
      @apply border-black;
    }
  }
  .arrow-indicator {
    @apply cursor-pointer pb-3 text-lg font-bold flex transition-all;
    .arrow {
      @apply w-3 h-3 border-b-2 border-r-2 border-black transition-all transform -rotate-45 self-center mt-0 mr-3 flex-shrink-0;
    }
  }
  .answer {
    @apply overflow-hidden max-h-0;
  }
}

/* ====================================================================== *
 * Simple Layout (without categories)                                     *
 * ====================================================================== */
.simple .box {
  &:last-child {
    @apply border-b border-gray-300;
  }
  &.active {
    @apply border-b border-black;
    .single-qa {
      @apply border-black;
    }
    + .box .single-qa {
      @apply border-t-transparent;
    }
  }
}

/* ====================================================================== *
 * Categorized Layout                                                     *
 * ====================================================================== */
.categorized .box {
  @apply mb-8;
  .single-qa:last-child {
    @apply border-b border-gray-300;
    &.active {
      @apply border-t-black border-b-transparent;
    }
  }
  .single-qa.active + .single-qa {
    @apply border-t-transparent;
  }
  .answer {
    @apply border-b border-transparent;
  }
}

/* ====================================================================== *
 * Box / Single QA active state                                           *
 * ====================================================================== */
.categorized .single-qa.active,
.simple .box.active {
  .arrow-indicator {
    @apply border-transparent;
    .arrow {
      @apply rotate-45 -mt-1.5;
    }
  }
  .answer {
    @apply -mt-1 pb-2 max-h-max border-black;
  }
}

</style>
