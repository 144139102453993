<!--==================================================================+
| MODAL: COOKIES                                                      |
+===================================================================-->

<template>
  <juit-modal
    ref="modal"
    class="small"
    :locked="locked"
  >
    <!-- INTRO / SETTINGS / PRIVACY SCREENS -->
    <slot>
      <intro-screen
        v-if="screen === 'intro'"
        @agree="save"
        @settings="screen = 'settings'"
        @privacy="screen = 'privacy'"
      />
      <settings-screen
        v-if="screen === 'settings'"
        :tier="currentTier"
        @back="locked ? screen = 'intro' : save()"
        @save="save"
      />
      <privacy-screen
        v-if="screen === 'privacy'"
        @back="screen = 'intro'"
      />
    </slot>
  </juit-modal>
</template>

<script lang="ts">
  import IntroScreen from './intro-screen.vue'
  import SettingsScreen from './settings-screen.vue'
  import PrivacyScreen from './privacy-screen.vue'
  import { defineComponent } from 'vue'
  import { PrivacyTier, privacyTier } from '../../analytics/privacy'
  import { log } from '../../init/log'

  import JuitModal from '../modal.vue'

  type Screen = 'intro' | 'settings' | 'privacy'

  export default defineComponent({
    name: 'JuitCookies',

    components: {
      IntroScreen,
      SettingsScreen,
      PrivacyScreen,
    },

    data: () => ({
      screen: privacyTier.value ? 'settings' : 'intro' as Screen,
      currentTier: privacyTier.value || 1 as PrivacyTier,
      locked: ! privacyTier.value,
    }),

    computed: {
      modal() {
        return this.$refs['modal'] as InstanceType<typeof JuitModal>
      },
    },

    methods: {
      // Save cookie to ref (therefore browser), close modal with animation
      save(tier?: PrivacyTier) {
        if (privacyTier.value == tier) {
          this.modal.closeModal()
          return
        }

        if (!tier) privacyTier.value = 3
        else privacyTier.value = tier
        log(`Saving privacyTier "${privacyTier.value.toString()}"`)
        this.modal.closeModal()
      },
    },
  })
</script>
