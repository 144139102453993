import { Directive, warn } from '@vue/runtime-core'
import { env } from './env'

type Callback = (
  isIntersecting: boolean,
  entry: IntersectionObserverEntry
) => any

function createIntersectionObserver(): Directive {
  // No real implementation in SSR environments
  if (env.SSR) return {}

  // Map between elements and their callback functions
  const callbacks = new WeakMap<Element, Callback>()

  // Our intersection observer, for all elements
  const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      const binding = callbacks.get(entry.target)
      if (binding) binding(entry.isIntersecting, entry)
    }
  })

  // Our directive declaration
  return {
    // On creation, wrap the directive's value (function or string class)
    // so that it can be easily called above from our intesection observer
    created(element, binding) {
      const instance = binding.instance
      const value = binding.value

      if (typeof value === 'function') {
        callbacks.set(element, (intersecting, entry) => {
          if (intersecting) {
            element.setAttribute('data-intersection-visible', 'true')
          } else {
            element.setAttribute('data-intersection-visible', 'false')
          }
          value.call(instance, intersecting, entry)
        })
      } else if (typeof value === 'undefined') {
        callbacks.set(element, (intersecting) => {
          if (intersecting) {
            element.setAttribute('data-intersection-visible', 'true')
          } else {
            element.setAttribute('data-intersection-visible', 'false')
          }
        })
      } else {
        warn(`Intersection observer value must be a function (was: ${typeof value})`)
      }
    },

    // When mounted start observing the element
    mounted(element) {
      observer.observe(element)
    },

    // Before unmounting unobserve the element and clear our callbacks
    beforeUnmount(element) {
      observer.unobserve(element)
      callbacks.delete(element)
    },
  } as Directive<Element>
}

export const intersectionObserver = createIntersectionObserver()
