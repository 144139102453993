<!--==================================================================+
| MODULE-COMPONENT: PANEL LIST                                        |
+===================================================================-->

<template>
  <juit-panel
    v-for="(panel, index) in module.list"
    :key="index"
    :module="panel"
  />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { PanelList } from '../content'
  import juitPanel from './panel.vue'

  export default defineComponent({
    name: 'JuitPanelList',
    components: {
      juitPanel,
    },
    props: {
      module: {
        type: Object as PropType<PanelList>,
        required: true,
      },
    },
  })
</script>

<style scoped lang="pcss">
  .container:nth-child(2n+1) {
    :deep() .panel-text {
      @apply pl-0 md:pr-8 lg:pr-10 text-left;
    }
  }

  .container:nth-child(2n) {
    :deep() .panel-media {
      @apply -order-1;
      .image-container {
        @apply left-auto right-0;
      }
    }
    :deep() .panel-text {
      @apply pr-0 md:pl-8 lg:pl-10 md:text-right;
    }
  }
</style>
