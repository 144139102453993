import { defineAsyncComponent } from 'vue'

/* ========================================================================== *
 * UNCATEGORIZED ICONS                                                        *
 * ========================================================================== */

export const arrow = defineAsyncComponent(() => import('./arrow.svg'))
export const heart = defineAsyncComponent(() => import('./heart.svg'))
export const percentage = defineAsyncComponent(() => import('./percentage.svg'))
export const box = defineAsyncComponent(() => import('./box.svg'))
export const no_result = defineAsyncComponent(() => import('./no-result.svg'))
export const close_eye = defineAsyncComponent(() => import('./juit-close-eye.svg'))
export const open_eye = defineAsyncComponent(() => import('./juit-open-eye.svg'))
export const mail = defineAsyncComponent(() => import('./mail.svg'))
export const phone = defineAsyncComponent(() => import('./phone.svg'))
export const verified_customer = defineAsyncComponent(() => import('./rating/verified_customer.svg'))
export const spinner = defineAsyncComponent(() => import('./spinner.svg'))

/* ========================================================================== *
 * RATING STARS                                                               *
 * ========================================================================== */

// Full stars
export const stars0 = defineAsyncComponent(() => import('./rating/0.svg?component'))
export const stars1 = defineAsyncComponent(() => import('./rating/1.svg?component'))
export const stars2 = defineAsyncComponent(() => import('./rating/2.svg?component'))
export const stars3 = defineAsyncComponent(() => import('./rating/3.svg?component'))
export const stars4 = defineAsyncComponent(() => import('./rating/4.svg?component'))
export const stars5 = defineAsyncComponent(() => import('./rating/5.svg?component'))

// Half stars (05 = 0.5) > for old comments that still allowed them
export const stars05 = defineAsyncComponent(() => import('./rating/0.5.svg?component'))
export const stars15 = defineAsyncComponent(() => import('./rating/1.5.svg?component'))
export const stars25 = defineAsyncComponent(() => import('./rating/2.5.svg?component'))
export const stars35 = defineAsyncComponent(() => import('./rating/3.5.svg?component'))
export const stars45 = defineAsyncComponent(() => import('./rating/4.5.svg?component'))

// Fourth stars (475 = 4.75) > for more precision in the Global Rating
export const stars375 = defineAsyncComponent(() => import('./rating/3.75.svg?component'))
export const stars425 = defineAsyncComponent(() => import('./rating/4.25.svg?component'))
export const stars475 = defineAsyncComponent(() => import('./rating/4.75.svg?component'))

/* ========================================================================== *
 * CARRIER LOGOS                                                              *
 * ========================================================================== */

export const carriers_leaf = defineAsyncComponent(() => import('./carriers/leaf.svg'))
export const carriers_logo_dpd_selected = defineAsyncComponent(() => import('./carriers/logo-dpd-selected.svg'))
export const carriers_logo_dpd = defineAsyncComponent(() => import('./carriers/logo-dpd.svg'))
export const carriers_logo_urbify_selected = defineAsyncComponent(() => import('./carriers/logo-urbify-selected.svg'))
export const carriers_logo_urbify = defineAsyncComponent(() => import('./carriers/logo-urbify.svg'))
export const carriers_logo_nordfrost = defineAsyncComponent(() => import('./carriers/logo-nordfrost.svg'))

/* ========================================================================== *
 * CHECKOUT ICONS                                                             *
 * ========================================================================== */

export const checkout_clock = defineAsyncComponent(() => import('./checkout/clock.svg'))
export const checkout_delivery = defineAsyncComponent(() => import('./checkout/delivery.svg'))
export const checkout_download = defineAsyncComponent(() => import('./checkout/download.svg'))
export const checkout_edit = defineAsyncComponent(() => import('./checkout/edit.svg'))
export const checkout_euro = defineAsyncComponent(() => import('./checkout/euro.svg'))
export const checkout_invoice = defineAsyncComponent(() => import('./checkout/invoice.svg'))
export const checkout_info = defineAsyncComponent(() => import('./checkout/info.svg'))
export const checkout_voucher = defineAsyncComponent(() => import('./checkout/voucher.svg'))

/* ========================================================================== *
 * PAYMENT ICONS                                                              *
 * ========================================================================== */

export const payment_amex = defineAsyncComponent(() => import('./payment/amex.svg'))
export const payment_amex2 = defineAsyncComponent(() => import('./payment/amex2.svg'))
export const payment_apple = defineAsyncComponent(() => import('./payment/apple.svg'))
export const payment_applepay = defineAsyncComponent(() => import('./payment/applepay.svg'))
export const payment_applepay2 = defineAsyncComponent(() => import('./payment/applepay2.svg'))
export const payment_creditcard = defineAsyncComponent(() => import('./payment/creditcard.svg'))
export const payment_creditcard_gpay = defineAsyncComponent(() => import('./payment/creditcard_gpay.svg'))
export const payment_creditcard_gpay2 = defineAsyncComponent(() => import('./payment/creditcard_gpay2.svg'))
export const payment_gpay = defineAsyncComponent(() => import('./payment/gpay.svg'))
export const payment_klarna = defineAsyncComponent(() => import('./payment/klarna.svg'))
export const payment_klarna2 = defineAsyncComponent(() => import('./payment/klarna2.svg'))
export const payment_master = defineAsyncComponent(() => import('./payment/master.svg'))
export const payment_paypal = defineAsyncComponent(() => import('./payment/paypal.svg'))
export const payment_ppal = defineAsyncComponent(() => import('./payment/ppal.svg'))
export const payment_visa = defineAsyncComponent(() => import('./payment/visa.svg'))
export const payment_sepa = defineAsyncComponent(() => import('./payment/sepa.svg'))
export const payment_sepa2 = defineAsyncComponent(() => import('./payment/sepa2.svg'))
export const payment_invoice = defineAsyncComponent(() => import('./payment/invoice.svg'))

/* ========================================================================== *
 * PROFILE ICONS                                                              *
 * ========================================================================== */

export const profile_magic_link = defineAsyncComponent(() => import('./profile/magic-link.svg'))
export const profile_social_email = defineAsyncComponent(() => import('./profile/social-email.svg'))
export const profile_social_fb = defineAsyncComponent(() => import('./profile/social-fb.svg'))
export const profile_social_whatsapp = defineAsyncComponent(() => import('./profile/social-whatsapp.svg'))

/* ========================================================================== *
 * SOCIAL MEDIA ICONS                                                         *
 * ========================================================================== */

export const social_facebook = defineAsyncComponent(() => import('./social-media/social-media-fb.svg'))
export const social_instagram = defineAsyncComponent(() => import('./social-media/social-media-ig.svg'))
export const social_twitter = defineAsyncComponent(() => import('./social-media/social-media-tw.svg'))
export const social_whatsapp = defineAsyncComponent(() => import('./social-media/social-whatsapp.svg'))

/* ========================================================================== *
 * PRODUCT TAGS ICONS                                                         *
 * ========================================================================== */

export const tags_ban = defineAsyncComponent(() => import('./tags/ban.svg'))
export const tags_chicken = defineAsyncComponent(() => import('./tags/chicken.svg'))
export const tags_fish = defineAsyncComponent(() => import('./tags/fish.svg'))
export const tags_flame = defineAsyncComponent(() => import('./tags/flame.svg'))
export const tags_meat = defineAsyncComponent(() => import('./tags/meat.svg'))
export const tags_protein = defineAsyncComponent(() => import('./tags/protein.svg'))
export const tags_vegan = defineAsyncComponent(() => import('./tags/vegan.svg'))
export const tags_special = defineAsyncComponent(() => import('./tags/special.svg'))
export const tags_salmon = defineAsyncComponent(() => import('./tags/salmon.svg'))
export const tags_asparagus = defineAsyncComponent(() => import('./tags/asparagus.svg'))
export const tags_planted = defineAsyncComponent(() => import('./tags/planted.svg'))
export const tags_sweet = defineAsyncComponent(() => import('./tags/sweet.svg'))

/* ========================================================================== *
 * NUTRITION PREFERENCE ICONS                                                 *
 * ========================================================================== */

export const pref_protein = defineAsyncComponent(() => import('./preferences/muscle.svg'))
export const pref_calories = defineAsyncComponent(() => import('./preferences/apple-measure.svg'))
export const pref_default = defineAsyncComponent(() => import('./preferences/meal.svg'))
