<template>
  <div class="text-center">
    <h2 class="mt-1 mb-3">
      {{ $t( 'cookies.title' ) }}
    </h2>
    <p class="text-sm sm:text-base">
      {{ $t( 'cookies.description' ) }}
    </p>
    <div class="juit-link pt-2 pb-0 mb-6 w-full" @click="$emit('privacy')">
      {{ $t( 'cookies.privacy' ) }}
    </div>
    <juit-button class="w-full small" @click="$emit('agree')">
      {{ $t( 'cookies.save' ) }}
    </juit-button>
    <div class="juit-link mt-2 w-full" @click="$emit('settings')">
      {{ $t( 'cookies.settings' ) }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import juitButton from '../../widgets/juit-button.vue'

  export default defineComponent({
    name: 'JuitCookiesIntro',
    components: { juitButton },
    emits: [ 'agree', 'settings', 'privacy' ],
  })
</script>
