<!--==================================================================+
| MODULE-COMPONENT: ICON-TEXT COLUMNS                                 |
+===================================================================-->

<template>
  <div v-if="module" class="container">
    <div class="tiled">
      <div class="tile" :class="module.layout?.toLowerCase()">
        <h1>{{ module.title }}</h1>
        <div class="boxes">
          <div
            v-for="item in module.list"
            :key="item.$id"
            class="box"
          >
            <div class="img-wrapper">
              <juit-image
                v-if="item.image"
                fit="contain"
                ratio="1x1"
                v-bind="item.image"
                :preview="false"
              />
            </div>
            <div class="text">
              <span class="title">{{ item.title }}</span>
              <rich-text
                v-if="item.description"
                :document="item.description"
              />
            </div>
          </div>
        </div>
        <div v-if="module.cta" class="cta">
          <juit-button :to="`/${$i18n.locale}/${module.cta?.slug}`">
            <span class="cta-btn-text">{{ module.cta?.text }}</span>
          </juit-button>
        </div>
      </div>
      <div v-if="module.layout === 'Panel-like'" class="tile img">
        <juit-image v-bind="module.image" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { IconTextList } from '../content/convert'
  import { defineComponent, PropType } from 'vue'

  export default defineComponent({
    name: 'JuitIconText',
    props: {
      module: {
        type: Object as PropType<IconTextList>,
        default: null,
      },
    },
  })
</script>

<style scoped lang="pcss">
  .tile {
    @apply flex-shrink-0;
    @apply md:w-1/3 text-left flex-col flex;
    .boxes {
      @apply h-full flex flex-col justify-center;
      .box {
        @apply flex my-3 lg:my-4 xxl:my-6 md:mr-4;
        .img-wrapper {
          @apply w-20 xl:w-28 xxl:w-36 pr-6 flex-shrink-0;
        }
        .text {
          @apply flex flex-col self-center;
          .title {
            @apply block font-bold text-lg xl:text-2xl uppercase md:my-0.5;
          }
          :deep() p {
            @apply mb-0 leading-tight;
          }
        }
      }
    }
    .cta {
      @apply mt-4;
    }
    &.simple {
      @apply w-full;
      h1 {
        @apply text-center;
      }
      .boxes {
        @apply -mt-3 md:mt-1 flex-row flex-wrap;
        .box {
          @apply mt-6 md:mt-2 flex-col mx-0 mb-0 w-1/2 md:w-1/4;
          .img-wrapper {
            @apply px-0 pb-2 md:pb-2.5 mx-auto w-12 lg:w-16;
          }
          .text {
            @apply text-center px-2 md:px-4;
            .title {
              @apply my-0;
            }
            :deep() p {
              @apply mt-0.5 md:mt-1;
            }
          }
        }
      }
      .cta {
        @apply mx-auto mobile-sm:w-full;
        a {
          @apply w-full text-center;
        }
      }
    }
    &.img {
      @apply hidden md:block md:w-2/3 pl-0;
      img {
        @apply w-full h-full object-cover;
      }
    }
  }
</style>
