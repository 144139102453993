import { createTextVNode, defineComponent, h, PropType } from '@vue/runtime-core'
import {
  BLOCKS, INLINES, MARKS,

  // Block nodes
  // AssetLinkBlock,
  // EntryLinkBlock,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Hr,
  ListItem,
  OrderedList,
  Paragraph,
  Quote,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  UnorderedList,

  // Inine nodes
  // AssetHyperlink,
  // EntryLinkInline,
  // EntryHyperlink,
  Hyperlink,

  // Other nodes
  Text, Inline, Block, Document,
} from '@contentful/rich-text-types'

/* ========================================================================== *
 * TYPES                                                                      *
 * ========================================================================== */

/** Node types we can render */
type Nodes =
  // Blocks
  // | AssetLinkBlock
  // | EntryLinkBlock
  | Heading1
  | Heading2
  | Heading3
  | Heading4
  | Heading5
  | Heading6
  | Hr
  | ListItem
  | OrderedList
  | Paragraph
  | Quote
  | Table
  | TableCell
  | TableHeaderCell
  | TableRow
  | UnorderedList
  // Inines
  // | AssetHyperlink
  // | EntryLinkInline
  // | EntryHyperlink
  | Hyperlink
  // Text
  | Text

/** Extract node type from node */
type ExtractNodeTypes<T extends Nodes> = T['nodeType']

/** Return value from conversion function */
type Converted = ReturnType<typeof h> | undefined | void

/** Map of all our converters */
type Converters = {
  [ N in Nodes as ExtractNodeTypes<N>]: (node: N) => Converted
}

/* ========================================================================== *
 * CONVERSION                                                                 *
 * ========================================================================== */

/** Create a simple converter based only on tag name */
function converter(tag: string): (node: Block | Inline) => Converted {
  return function(node: Block | Inline): Converted {
    return h(tag, node.data, convertChildren(node))
  }
}

/** Convert the _children_ of any container node */
function convertChildren(node: Block | Inline): Converted[] {
  return node.content.map((child) => convert(child))
}

/** Convert any node using our converters table below */
function convert(node: Block | Inline | Text): Converted {
  return converters[node.nodeType as keyof Converters]?.(node as any)
}

/** Converter for text nodes */
function convertText(node: Text): Converted {
  let converted = createTextVNode(node.value)
  for (const mark of node.marks) {
    switch (mark.type) {
      case MARKS.BOLD: converted = h('b', {}, converted); break
      case MARKS.CODE: converted = h('code', {}, converted); break
      case MARKS.ITALIC: converted = h('i', {}, converted); break
      case MARKS.UNDERLINE: converted = h('u', {}, converted); break
    }
  }
  return converted
}

/** Converter for hyperlink nodes */
function convertHyperlink(node: Hyperlink): Converted {
  return h('a', { href: node.data.uri }, convertChildren(node))
}

// function convertAssetLinkBlock(node: AssetLinkBlock): Converted {
//   node.data.target.
// }

// function convertEntryLinkBlock(node: EntryLinkBlock): Converted {
//   node.data.target
// }

// function convertEntryHyperlink(node: EntryHyperlink): Converted {
//   node.data.target
// }

// function convertAssetHyperlink(node: AssetHyperlink): Converted {
//   node.data.target
// }

// function convertEntryLinkInline(node: EntryLinkInline): Converted {
//   node.data.target
// }

const converters: Converters = {
  // [BLOCKS.EMBEDDED_ASSET]: convertAssetLinkBlock,
  // [BLOCKS.EMBEDDED_ENTRY]: convertEntryLinkBlock,
  [BLOCKS.HEADING_1]: converter('h1'),
  [BLOCKS.HEADING_2]: converter('h2'),
  [BLOCKS.HEADING_3]: converter('h3'),
  [BLOCKS.HEADING_4]: converter('h4'),
  [BLOCKS.HEADING_5]: converter('h5'),
  [BLOCKS.HEADING_6]: converter('h6'),
  [BLOCKS.HR]: converter('hr'),
  [BLOCKS.LIST_ITEM]: converter('li'),
  [BLOCKS.OL_LIST]: converter('ol'),
  [BLOCKS.PARAGRAPH]: converter('p'),
  [BLOCKS.QUOTE]: converter('blockquote'),
  [BLOCKS.TABLE]: converter('table'),
  [BLOCKS.TABLE_CELL]: converter('td'), // safe, data is "colspan" "rowspan"
  [BLOCKS.TABLE_HEADER_CELL]: converter('th'), // safe, data is "colspan" "rowspan"
  [BLOCKS.TABLE_ROW]: converter('tr'),
  [BLOCKS.UL_LIST]: converter('ul'),

  [INLINES.HYPERLINK]: convertHyperlink, // data.uri must become "href"
  // [INLINES.ENTRY_HYPERLINK]: convertEntryHyperlink,
  // [INLINES.ASSET_HYPERLINK]: convertAssetHyperlink,
  // [INLINES.EMBEDDED_ENTRY]: convertEntryLinkInline,

  'text': convertText,
}

export default defineComponent({
  props: {
    document: {
      type: Object as PropType<Document>,
      required: true,
    },
    wrapper: {
      type: String,
      required: false,
      default: 'div',
    },
  },
  render() {
    return h(this.wrapper, {}, convertChildren(this.document))
  },
})
