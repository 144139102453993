<!--==================================================================+
| MODULE-COMPONENT: USER RATINGS                                      |
+===================================================================-->

<template>
  <div v-if="module && comments.length !==0" class="container">
    <h1>{{ module.title }}</h1>

    <div class="ratings-component">
      <!--==================================================================+
      | GLOBAL RATING + SORTING (FIXED POSITION)                            |
      +===================================================================-->
      <div v-if="stats" class="global-rating-tile">
        <p class="global-rating-value">
          {{ stats.avg }}
        </p>
        <!-- we pass the rating-average-value ROUNDED to the closest 0,25 (for quarter stars) -->
        <juit-stars class="text-yellow global-rating-stars fill-current" :value="Math.round(stats.avg*4)/4" />
        <div class="global-rating-text cursor-pointer flex" tabindex="0">
          {{ $t( 'global-rating.based-on' ) }} {{ $number(stats.total) }} {{ $t( 'global-rating.reviews' ) }}
          <juit-info class="w-5 self-center ml-2" />
        </div>
        <div class="toggle" />
        <div class="tooltip">
          <div class="bg" />
          <juit-info class="w-6 self-center mr-2 flex-shrink-0 z-1" />
          <span class="z-1" v-html="$t( 'global-rating.legal-text' )" />
        </div>

        <!-- SORTING -->

        <div class="select-menu">
          <!-- The actual html menu, with opacity 0 -->
          <select id="select-menu" ref="selectMenu" class="select-menu-invisible">
            <option
              v-for="(category, idx) in sortingCategories"
              :key="idx"
              :value="category.value"
            >
              {{ labelText(idx, category.label.toString()) }}
            </option>
          </select>

          <!-- The customised select-menu -->
          <div class="select-menu-visible">
            <span class="text">{{ labelText(selectedIndex, sortingCategories[selectedIndex].label.toString()).toUpperCase() }}</span>
            <span class="line" />
            <arrow class="menu-arrow" />
          </div>
        </div>
      </div>

      <!--==================================================================+
      | INDIVIDUAL RATINGS (CAROUSEL IN DESKTOP, ARROWS ON MOBILE)          |
      +===================================================================-->
      <juit-carousel
        v-if="comments.length !==0"
        class="gradient-arrow mobile-only-small"
        :class="browserWindowWidth < 599 && 'comments-container'"
        :style="browserWindowWidth < 599 && `height: ${containerHeight}px`"
        :card-width="cardwidth"
        :scroll-reset="scrollReset"
        @scroll-end="browserWindowWidth > 599 && fetchMoreComments()"
        @active-index="changeActiveCommentIndex"
      >
        <div
          v-for="(comment, $index) in comments"
          :key="$index"
          ref="card"
          class="tile comment-tile"
          :style="`--tw-delay: ${$index + 1}`"
        >
          <div class="card">
            <div class="card-first-line">
              <p class="first-name">
                {{ comment.user_name.toUpperCase() }}
              </p>
              <!-- we pass the rating-value ROUNDED to the closest 0,5 (for old comments that allowed half stars) -->
              <juit-stars class="text-yellow stars fill-current" :value="Math.round(comment.score*2)/2" />
            </div>
            <div class="card-second-line">
              <verified-customer />
              <p class="verified-customer">
                {{ $t( 'global-rating.verified-customer' ) }}
              </p>
            </div>
            <p class="description no-scrollbar">
              {{ comment.comment }}
            </p>
            <p class="date">
              {{ ((new Date(comment.created).getDate()) < 10 ? '0' : '') + (new Date(comment.created).getDate()) }}.{{ ((new Date(comment.created).getMonth()+1) < 10 ? '0' : '') + (new Date(comment.created).getMonth()+1) }}.{{ new Date(comment.created).getFullYear() }}
            </p>
          </div>
        </div>
      </juit-carousel>

      <!--==================================================================+
      | ARROWS ON MOBILE                                                    |
      +===================================================================-->
      <div v-if="browserWindowWidth < 599" :class="!lessArrowVisible || !moreArrowVisible ? 'one-arrow' : 'two-arrows'" class="arrows-mobile">
        <!-- show less comments -->
        <div :class="lessArrowVisible ? 'arrow-group' : 'hidden'" @click="showLessComments()">
          <p class="arrow-text">
            {{ $t( 'global-rating.show-less' ) }}
          </p>
          <arrow v-if="browserWindowWidth < 599" class="arrow less" />
        </div>
        <!-- show more comments -->
        <div :class="moreArrowVisible ? 'arrow-group' : 'hidden'" @click="showNextComments()">
          <p class="arrow-text">
            {{ $t( 'global-rating.show-more' ) }}
          </p>
          <arrow v-if="browserWindowWidth < 599" class="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import juitCarousel from '../widgets/juit-carousel.vue'
  import juitStars from '../widgets/juit-stars'
  import { Comment, Statistics } from '@juitnow/api-comments'
  import { defineComponent, PropType } from 'vue'
  import { analyticsEvent } from '../analytics'
  import { TestimonialList } from '../content/convert'
  import { client } from '../init/client'
  import { arrow, verified_customer as verifiedCustomer, checkout_info as juitInfo } from '../assets/async'
  import { fetchGlobalRatingStats } from '../content/fetch'

  type Query = {
    offset?: number;
    limit?: number;
    sort_by?: 'oldest_first' | 'newest_first' | 'best_first' | 'worst_first';
    visible?: boolean;
    name?: string;
    from?: Date;
    to?: Date;
    max_length?: number;
    min_order_sequence?: number;
    score?: number;
    empty?: boolean;
  };

  // Sometimes a "number", sometimes "NodeJS.Timeout", ... WHY?
  type Timeout = ReturnType<typeof setTimeout>

  export default defineComponent({
    components: { juitCarousel, juitInfo, juitStars, verifiedCustomer, arrow },
    props: {
      module: {
        type: Object as PropType<TestimonialList>,
        default: null,
      },
    },
    data: () => ({
      cardwidth: 0,
      comments: [] as Comment[],
      fetching: false,
      fetchedComments: 50,
      lastFetch: false,
      scrollReset: false,
      selectedIndex: 0, // index of the current selected option
      sortingCategories: [
        {
          label: 'most-relevant',
          value: 'relevant',
        },
        {
          label: 'newest',
          value: 'newest',
        },
        {
          label: '★★★★★',
          value: 'score_5',
        },
        {
          label: '★★★★',
          value: 'score_4',
        },
        {
          label: '★★★',
          value: 'score_3',
        },
        {
          label: '★★',
          value: 'score_2',
        },
        {
          label: '★',
          value: 'score_1',
        },
      ] as { label: String, value: String }[],
      index: 2,
      commentTiles: [] as any,
      containerHeight: 0,
      lessArrowVisible: false,
      moreArrowVisible: true,
      query: {
        offset: 0,
        limit: 20,
        visible: true,
        min_order_sequence: 2,
        empty: false,
      } as Query,
      stats: null as Statistics | null,
      browserWindowWidth: 0,
      activeIndexTimeout: null as Timeout | null, // we use "setTimeout" to determine when scrolling ends
      activeIndexBeforeScrolling: null as number | null, // the active index before starting to scroll
      menuLoaded: false,
    }),

    async mounted() {
      // fetch first 50 comments
      this.fetchComments(this.query)

      // fetch global rating
      this.stats = await fetchGlobalRatingStats() || this.stats

      // get the current browser window width and track its resize
      this.browserWindowWidth = window.innerWidth
      window.addEventListener('resize', this.calculateBrowserWindowWidth)
    },

    updated() {
      if (!this.stats || this.menuLoaded) return

      // we add an event listener to the select-menu, to get the user input, save it in data(), and display it in our customised menu
      const selectMenu = this.$refs.selectMenu as HTMLSelectElement
      if (selectMenu) selectMenu.addEventListener('change', () => this.getUserSelection(selectMenu.selectedIndex, selectMenu.value))
      this.menuLoaded = true
    },

    methods: {
      labelText(index: number, label: string) {
        return index !== 0 && index !== 1 ? label : this.$t( `global-rating.${label}` )
      },

      /* ========================================================================== *
       * Save the user's selection from the sorting menu                            *
       * -------------------------------------------------------------------------- */
      getUserSelection(index: number, category: string) {
        this.selectedIndex = index
        this.fetchCommentsSorted(index, category)

        // send event back to analytics
        this.sendReviewsSorting(category)
      },

      /* ========================================================================== *
       * Fetch Comments (passing the Query in Data())                               *
       * -------------------------------------------------------------------------- */
      async fetchComments(params: Query, resetSearch?: boolean) {
        this.comments = await client.comments.list( params )

        // reset some values
        this.scrollReset = true
        this.lastFetch = false

        this.$nextTick(()=>{
          const cards = this.$refs.card as HTMLElement[]
          if (cards?.length) this.cardwidth = cards[0].getBoundingClientRect().width
          if (this.browserWindowWidth < 599) {
            this.commentTiles = document.querySelectorAll('.comment-tile')
            this.calculateInitialContainerHeight()
          }

          // if we are coming from fetchCommentsSorted:
          if (resetSearch === true && this.browserWindowWidth < 599) this.showLessComments(true)

          // we save the number of comments (for mobile)
          this.fetchedComments = this.comments.length

          // we check if this is the last fetch
          if (this.comments.length < 50) this.lastFetch = true

          // we reset the value for the next fetch
          this.scrollReset = false
        })
      },

      /* ========================================================================== *
       * Fetch further 50 Comments (updating and passing the Query in Data())       *
       * -------------------------------------------------------------------------- */
      async fetchMoreComments() {
        if (this.fetching || this.lastFetch) return
        if (this.query.offset != undefined) this.query.offset += 50

        this.fetching = true
        await client.comments.list( this.query )
          .then((res)=> {
            if (res) this.comments.push(...res)
            if (res.length < 50) this.lastFetch = true
            // we save the number of comments (for mobile)
            this.fetchedComments = res.length
          })

        this.fetching = false

        // we update the value of commentTiles to include the new comments (on mobile)
        if (this.browserWindowWidth < 599) this.commentTiles = document.querySelectorAll('.comment-tile')
      },

      /* ========================================================================== *
       * Sort the comments                                                          *
       * -------------------------------------------------------------------------- */
      async fetchCommentsSorted(index: number, category: string) {
        // we reset the original query values and add the sorting category
        if (category === 'relevant') this.query = { offset: 0, limit: 50, sort_by: 'newest_first', visible: true, min_order_sequence: 2, empty: false }
        else if (category === 'newest') this.query = { offset: 0, limit: 50, sort_by: 'newest_first', visible: true, min_order_sequence: 1, empty: false }
        else this.query = { offset: 0, limit: 50, sort_by: 'newest_first', visible: true, score: Number(category.slice(6)), empty: false }

        // we fetch the first 50 comments with the new category
        this.fetchComments(this.query, true)
      },

      /* ========================================================================== *
       * Calculate Container Height for first two comments (MOBILE ONLY)            *
       * -------------------------------------------------------------------------- */
      calculateInitialContainerHeight() {
        if (this.commentTiles?.length) this.containerHeight = this.commentTiles[0].clientHeight + this.commentTiles[1].clientHeight
      },

      /* ========================================================================== *
       * Show next two comments (MOBILE ONLY)                                       *
       * -------------------------------------------------------------------------- */
      async showNextComments() {
        // if we arrived to the last comment and it is the last fetch
        if (this.index === this.commentTiles.length && this.lastFetch) {
          this.lessArrowVisible = true
          this.moreArrowVisible = false
          return
        }

        // if we only have one comment left and it is the last fetch
        if (this.index === this.commentTiles.length-1) {
          this.containerHeight += this.commentTiles[this.index].clientHeight
          this.lessArrowVisible = true
          this.moreArrowVisible = false
          return
        }

        // if we need to fetch further 50 comments
        if (this.index === this.commentTiles.length && !this.lastFetch) await this.fetchMoreComments()

        // else, we display the next two comments
        else {
          this.fetchedComments -=2

          // calculate the new height
          this.containerHeight += this.commentTiles[this.index].clientHeight + this.commentTiles[this.index+1].clientHeight

          // update the value of the index
          this.index += 2

          // show the "show less" arrow
          this.lessArrowVisible = true

          // if we arrive to the last comment:
          if (this.lastFetch && this.fetchedComments <= 2) this.moreArrowVisible = false
        }

        // send event back to analytics
        this.sendReviewsShowMore(this.index)
      },

      /* ========================================================================== *
       * Go back to the original two comments (MOBILE ONLY)                         *
       * -------------------------------------------------------------------------- */
      showLessComments(omitEvent?: boolean) {
        this.calculateInitialContainerHeight()

        // restart the value of the index
        this.index = 2
        this.fetchedComments = this.comments.length

        // display the corresponding arrows
        this.lessArrowVisible = false
        this.moreArrowVisible = true

        // center the first comment on screen
        if (this.commentTiles?.length) this.commentTiles[0].scrollIntoView({ block: 'center' })

        // send event back to analytics (if necessary)
        if (!omitEvent) this.sendReviewsShowLess(this.index)
      },

      /* ==================================================================== *
       * Change the active comment index in carousel                          *
       * -------------------------------------------------------------------- */
      changeActiveCommentIndex(index: number): void {
        let previous: number
        if (this.activeIndexBeforeScrolling !== null) {
          previous = this.activeIndexBeforeScrolling
        } else {
          this.activeIndexBeforeScrolling = index
          previous = index
        }

        if (this.activeIndexTimeout !== null) {
          clearTimeout(this.activeIndexTimeout)
        }

        this.activeIndexTimeout = setTimeout(() => {
          this.activeIndexTimeout = null
          this.activeIndexBeforeScrolling = null

          // values are adjusted by 2, as we (on average) display 2 cards
          // (could be 1, or 2.5~ish, but more-or-less, we want the # of cards)
          if (index > previous) this.sendReviewsShowMore(index + 2)
          else if (index < previous) this.sendReviewsShowLess(index + 2)
        }, 500)
      },

      /* ==================================================================== *
       * GA events                                                            *
       * -------------------------------------------------------------------- */

      sendReviewsShowLess(cards_shown: number): void {
        analyticsEvent('reviews_showless', { cards_shown })
      },

      sendReviewsShowMore(cards_shown: number): void {
        analyticsEvent('reviews_showmore', { cards_shown })
      },

      sendReviewsSorting(category: string): void {
        analyticsEvent('reviews_sorting', { category })
      },

      /* ========================================================================== *
       * we update this value every time the browser window is resized IN WIDTH     *
       * -------------------------------------------------------------------------- */
      calculateBrowserWindowWidth() {
        // we do nothing if the resize was in height (to avoid weird behaviour on mobile due to browser automatic scrollbar)
        if (this.browserWindowWidth === window.innerWidth) return

        // if the resize is in width (user resizing browser on desktop to < 599), we go back to the first two comments (to have the right container height)
        this.browserWindowWidth = window.innerWidth
        if (this.browserWindowWidth < 599) {
          this.commentTiles = document.querySelectorAll('.comment-tile')
          this.showLessComments(true)
          this.moreArrowVisible = true
          this.lessArrowVisible = false
        }
      },
    },
  })

</script>

<style scoped lang="pcss">
/* ====================================================================== *
 * Changes to the juit-carousel default-css                               *
 * ====================================================================== */
:deep() .carousel-and-arrows {

  /* Width of the carousel */
  @apply w-full sm-testm:w-2/3 md-testm:w-3/4;
  .roller-wrapper {
    .tiled {
      @apply py-0;
      @apply md-testm:snap-center lg-testm:snap-x;
      /* no carousel on mobile: */
      @apply mobile-sm:flex-wrap;
      @apply lg-testm:scroll-p-[100px];
    }
  }

  /* Arrows */
  .arrow-wrapper {
    @apply -left-2 sm:-left-6 md-testm:-left-1 md:-left-6 lg:-left-10;
    /* no arrows on mobile: */
    @apply mobile-sm:hidden;
    &.right {
      @apply -right-2 sm:-right-6 md-testm:-right-1 md:-right-6 lg:-right-10;
    }
    .arrow {
      @apply md:w-4 lg-testm:w-6;
    }
  }
}

/* ====================================================================== *
 * Local Changes                                                          *
 * ====================================================================== */
.arrows-mobile {
  &.one-arrow {
    @apply flex flex-row justify-center items-center;
  }
  &.two-arrows {
    @apply flex flex-row justify-between;
  }
  .arrow-group {
    @apply flex flex-row justify-center items-center;
    @apply pl-1 pr-3;
  }
  .arrow-text {
    @apply font-bold;
  }
  .arrow {
    @apply w-4 rotate-90;
    @apply ml-4;
    &.less {
      @apply -rotate-90;
    }
  }
}
.ratings-component {
  @apply sm-testm:flex sm-testm:flex-row sm-testm:justify-between;
}
.global-rating-tile {
  @apply sm-testm:w-1/3 md:w-1/4 lg:pr-5 relative;
  @apply flex flex-col justify-center items-center;
  .global-rating-value {
    @apply font-bold text-4xl;
    @apply mb-2;
  }
  .global-rating-stars {
    @apply w-44 lg-testm:w-56;
    @apply mb-4;
  }
  .global-rating-text {
    @apply font-bold text-base sm-testm:text-sm md:text-base lg:text-lg;
    @apply mb-4;
  }
  .toggle {
    @apply relative w-full;
    &:before {
      @apply absolute w-full -top-12 h-9 pointer-events-none cursor-pointer;
      content: '';
    }
  }
  .tooltip {
    @apply cursor-default absolute top-1 sm-testm:top-12 md:top-14 w-full sm:w-80;
    @apply left-1/2 transform -translate-x-1/2 sm-testm:left-0 sm-testm:transform-none;
    @apply p-2.5 text-xs bg-white;
    @apply flex-row justify-center hidden z-60;
    .bg {
      @apply absolute w-full h-full z-0 top-0;
      @apply bg-greenUrbify/20;
    }
  }
  .global-rating-text:focus + .toggle:before {
    @apply pointer-events-auto;
  }
  .global-rating-text:focus + .toggle + .tooltip {
    @apply flex;
  }
}
/* the following class is for mobile only */
.comments-container {
  @apply overflow-hidden;
}
.tile {
  @apply snap-start md-testm:snap-center lg-testm:snap-start;
  @apply w-full md-testm:w-2/3 md:w-7/12 lg:w-1/2 lg-testm:w-2/5;
  /* @apply sm:mr-1.5 lg:mr-3; */
  .card {
    @apply text-left border-2 h-[17rem] border-black p-4 sm:p-6 bg-white flex flex-col;
    /* height variable on mobile: */
    @apply mobile-sm:h-full;
    .stars {
      @apply w-32 sm:w-36;
    }
    .text {
      @apply flex justify-center flex-col flex-grow;
    }
  }
}
.card-first-line {
  @apply flex flex-row justify-between items-center align-middle;
  @apply mb-3;
}
.card-second-line {
  @apply flex flex-row justify-start items-center align-middle;
  @apply mb-2;
}
.first-name {
  @apply font-bold text-base sm:text-xl xxl:text-2xl;
  @apply inline-block;
  @apply w-1/2 truncate;
}
.verified-customer {
  @apply font-bold;
  @apply ml-2;
}
.description {
  @apply leading-tight;
  @apply mb-2;
  @apply sm-order:h-36 sm-order:overflow-scroll;
}
.date {
  @apply font-bold;
}
.last-tile {
  @apply flex flex-row justify-center items-center;
  @apply mobile:h-96;
}
.last-comment {
  @apply font-bold;
}
.sorting {
  @apply flex flex-row justify-start items-center;
  @apply mt-10;
}
.sorting > h2 {
  @apply mr-5 mb-0;
  @apply cursor-pointer;
}

.select-menu {
  @apply w-48 h-9;
  @apply relative;
  @apply mb-10 sm:mb-4;
}
.select-menu-visible {
  @apply w-full h-full;
  @apply absolute top-0 left-0;
  @apply border-solid border-2 border-black bg-white;
  @apply flex flex-wrap justify-end items-center;
  .text {
    @apply grow;
    @apply font-text font-bold text-base text-center align-middle;
  }
  .line {
    @apply h-full;
    @apply border-solid border-r-2 border-black;
  }
  .menu-arrow {
    @apply h-3/5 rotate-90;
    @apply px-3;
  }
}

.select-menu-invisible {
  @apply w-full h-full;
  @apply absolute top-0 left-0;
  @apply opacity-0;
  @apply z-10;
}

</style>
