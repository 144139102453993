import { watch } from 'vue'
import { user } from '../init/client'
import { privacyTier, PrivacyTier } from '../analytics/privacy'
import { User } from '@juitnow/lib-schema/types'
import { log } from './log'

// Type for standard visitor traits
interface VisitorTraits {
  [ key: string ]: string | undefined
  first_name?: string
  last_name?: string
  email?: string
  organization?: string
  department?: string
  job_title?: string
  phone?: string
  website?: string
  country?: string
  address_one?: string
  address_two?: string
  city?: string
  state?: string
  zip?: string
  fax?: string
  annual_revenue?: string
  employees?: string
}

// Type for survey display options
interface SurveyOptions {
  forceDisplay?: boolean,
  displayMethod?: 'immediately' | 'delayed' | 'exitIntent' | 'onScroll',
  displayOptions?: {
    delay?: number,
    scrolledPercentage?: number
  }
}

// Types for survicate object
declare global {
  interface Window {
    _sva?: {
      retarget(): void
      getVisitorId(): string
      setVisitorTraits(traits: VisitorTraits): void
      destroyVisitor(cb?: () => void): void
      showSurvey(survey: string, options?: SurveyOptions): void
    }
  }
}

// Be nice...
log('Initializing Survicate surveys')

// Initial values for user and privacy tier
let _user: User | undefined = user.value as User
let _tier = privacyTier.value

// Watch changes over user and privacy tier
watch(user, (user) => {
  _user = user as User
  updateSurvicate(_user, _tier)
}, { immediate: true })

watch(privacyTier, (tier) => {
  _tier = tier
  updateSurvicate(_user, _tier)
}, { immediate: true })

// Update survicate "visitor traits"
function updateSurvicate(user: User | undefined, tier: PrivacyTier): void {
  if (user && (tier >= 3)) {
    globalThis.window._sva?.setVisitorTraits({
      email: user.email,
      first_name: user.given_name,
      last_name: user.family_name,
      uuid: user.uuid,
    })
  }
}
