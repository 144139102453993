<!--==================================================================+
| MODAL COMPONENT: RESET PASSWORD                                     |
+===================================================================-->

<template>
  <div class="text-center reset-password">
    <div v-if="!success">
      <h2 class="mt-1 mb-3">
        {{ $t( 'authentication.reset-password-header' ) }}
      </h2>

      <!-- RESET PASSWORD FORM -->
      <form id="reset-pw" class="mt-6 flex flex-col" @submit.stop.prevent="resetPassword">
        <input-email v-model:email="email" v-model:valid="email_valid" />
        <p v-if="server_error" class="invalid-msg">
          {{ $t( 'authentication.server-error-try-again' ) }}
        </p>
        <juit-button
          :disabled="!email_valid"
          type="submit"
          form="reset-pw"
          class="w-full mt-2 small"
        >
          {{ $t( 'authentication.reset-password' ) }}
        </juit-button>
      </form>
    </div>

    <!-- PASSWORD RESET SUCCESS -->
    <div v-else>
      <h2 class="mt-1 mb-3">
        {{ $t( 'authentication.server-success-header' ) }}
      </h2>
      <p>
        {{ $t( 'authentication.reset-password-success' ) }}
      </p>
    </div>

    <!-- BACK (CHANGE SCREEN) -->
    <div class="juit-link w-full py-3 -mb-3" @click="$emit('screen')">
      {{ $t( 'authentication.back' ) }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { client } from '../../init/client'
  import { log } from '../../init/log'
  import juitButton from '../../widgets/juit-button.vue'

  export default defineComponent({
    name: 'JuitResetPWScreen',
    components: { juitButton },
    emits: {
      loading: (confirm: boolean) => true,
      screen: () => true,
    },
    data: () => ({
      server_error: false,
      success: false,
      email: '',
      email_valid: false,
    }),
    methods: {
      /* ==================================================================== *
       * Reset Password                                                       *
       * -------------------------------------------------------------------- */
      resetPassword() {
        this.$emit('loading', true)
        this.server_error = false

        client.resetPassword(this.email).then(() => {
          this.$emit('loading', false)
          this.success = true
        }).catch((e) => {
          this.$emit('loading', false)
          this.server_error = true
          log(e)
        })
      },
    },
  })
</script>
