<template>
  <div class="social-media">
    <a
      href="https://www.facebook.com/juitdeutschland/"
      target="_blank"
      rel="noopener"
      aria-label="Juit on facebook"
    >
      <iconFb />
    </a>
    <a
      href="https://twitter.com/juit_de"
      target="_blank"
      rel="noopener"
      aria-label="Juit on twittter"
    >
      <iconTw />
    </a>
    <a
      href="https://www.instagram.com/juit/"
      target="_blank"
      rel="noopener"
      aria-label="Juit on Instagram"
    >
      <iconIg />
    </a>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    social_facebook as iconFb,
    social_instagram as iconIg,
    social_twitter as iconTw,
  } from '../assets/async'


  export default defineComponent({
    components: {
      iconFb,
      iconTw,
      iconIg,
    },
  })
</script>

<style scoped lang="pcss">
.social-media {
  @apply flex text-black self-center flex-grow xl:flex-grow-0;
  @apply mobile:pt-4 justify-end;
  a {
    @apply w-8 h-8 md:w-12 md:h-12 rounded-full bg-ash flex mobile:ml-3 md:ml-4;
    :deep() svg {
      @apply h-5 w-5 md:w-7 md:h-7 self-center mx-auto fill-current;
    }
  }

  &.reverse {
    a {
      @apply bg-black text-white;
    }
  }
}
</style>
