/* ========================================================================== *
 * LOG WRAPPER - wrap (and disable in production) console.log                 *
 * ========================================================================== */
/* eslint-disable no-console */

import { env } from './env'

const showLogging = env.DEV || env.MODE === 'development' || window.location.host === 'staging.juit.com'

/** The logging function, disabled in production */
export const log: typeof console.log = showLogging ? console.log : () => {}

export const logGroupCollapsed: typeof console.groupCollapsed = showLogging ? console.groupCollapsed : () => {}

export const logGroupEnd: typeof console.groupEnd = showLogging ? console.groupEnd : () => {}

/** The error logging function, always enabled */
export const logError: typeof console.error = console.error
