<template>
  <div class="address" :class="{ b2b }">
    <!-- NAME -->
    <p v-if="shipping && !profilePage" class="font-semibold text-base mb-2">
      {{ $t( 'order.shipping_address' ) }}*
    </p>
    <div class="input-group">
      <div class="w-1/2">
        <input-text
          v-model:text="rest.given_name"
          v-model:valid="given_name_valid"
          :autocomplete="'given-name'"
          :placeholder="$t( 'order.first_name' )"
          :requirement="true"
        />
      </div>
      <div class="w-1/2">
        <input-text
          v-model:text="rest.family_name"
          v-model:valid="family_name_valid"
          :autocomplete="'family-name'"
          :placeholder="$t( 'order.last_name' )"
          :requirement="true"
        />
      </div>
    </div>

    <!-- ADDRESS (GOOGLE AUTOCOMPLETE) -->
    <div class="input-group">
      <div class="w-full">
        <form class="flex flex-wrap -my-1" method="get">
          <div class="mb-1 w-full flex">
            <input
              ref="streetname"
              v-model="place.street_name"
              :placeholder="$t( 'order.street_name' )"
              :class="{ 'valid-false': !place.street_name && touched.street_name_status }"
              type="text"
              name="streetname"
              autocomplete="street-address"
              required
              @focus="touched.street_name_status = false"
              @blur="touched.street_name_status = true"
              @input="google_place_id=''"
            >
            <input
              ref="housenumber"
              v-model="place.house_number"
              class="ml-2 flex-card-1/4"
              :placeholder="$t( 'order.house_number' )"
              :class="{ 'valid-false': !place.house_number && touched.house_number_status }"
              type="text"
              name="housenumber"
              required
              @focus="touched.house_number_status = false"
              @blur="touched.house_number_status = true"
              @input="google_place_id=''"
            >
          </div>
          <div v-if="!place.street_name && touched.street_name_status || !place.house_number && touched.house_number_status" class="mb-1 -mt-1 w-full flex">
            <div class="w-full">
              <span v-if="!place.street_name && touched.street_name_status" class="w-full invalid-msg">*{{ $t('contact.missing-field') }}</span>
            </div>
            <div class="ml-2 flex-card-1/4">
              <span v-if="!place.house_number && touched.house_number_status" class="w-full invalid-msg">*{{ $t('contact.missing-field') }}</span>
            </div>
          </div>
          <div class="my-1 mt-1 w-full flex">
            <input
              ref="addressextra"
              v-model="rest.address_extra"
              type="text"
              name="addressextra"
              :placeholder="$t( 'order.address_extra' )"
            >
          </div>
          <div class="my-1 w-full flex">
            <input
              ref="postalcode"
              v-model="place.postal_code"
              type="text"
              inputmode="numeric"
              class="mr-2 flex-card-1/3"
              :class="{ 'valid-false': !place.postal_code && touched.postal_code_status }"
              :placeholder="$t( 'order.zip' )"
              name="postalcode"
              maxlength="5"
              required
              @focus="touched.postal_code_status = false"
              @blur="touched.postal_code_status = true"
              @input="zipCheck()"
            >
            <input
              ref="city"
              v-model="place.city"
              type="text"
              :placeholder="$t( 'order.city' )"
              :class="{ 'valid-false': !place.city && touched.city_status }"
              name="city"
              required
              @blur="touched.city_status = true; alertAllRequiredFields()"
              @input="google_place_id=''"
            >
          </div>
          <div v-if="!place.postal_code && touched.postal_code_status || !place.city && touched.city_status" class="-mt-1 w-full flex">
            <div class="mr-2 flex-card-1/3">
              <span v-if="!place.postal_code && touched.postal_code_status" class="w-full invalid-msg">*{{ $t('contact.missing-field') }}</span>
            </div>
            <div>
              <span v-if="!place.city && touched.city_status" class="w-full invalid-msg">*{{ $t('contact.missing-field') }}</span>
            </div>
          </div>
          <p v-if="zip_valid === false" class="w-full invalid-msg">
            {{ $t( 'order.zip_invalid' ) }}
          </p>
        </form>
        <div v-if="errors.length" class="invalid-msg">
          <div v-for="(error, index) in errors" :key="index">
            {{ error }}<br>
          </div>
        </div>
      </div>
    </div>

    <!-- COMPANY and VAT NUMBER (OPTIONAL) -->
    <div class="input-group">
      <div :class="!shipping && !b2b ? 'w-full md:w-2/3 mb-4 md:mb-0' : 'w-full'">
        <p>{{ $t( b2b ? 'order.company-b2b' : 'order.company' ) }}</p>
        <div>
          <input
            v-model="rest.company"
            type="text"
            :requred="b2b"
            :requirement="b2b"
            :placeholder="$t( b2b ? 'order.company-b2b' : 'order.company' )"
          >
        </div>
      </div>
      <div v-if="!shipping" class="w-full md:w-1/3 vat">
        <p>{{ $t( b2b ? 'order.vat-number-b2b' : 'order.vat-number' ) }}</p>
        <div>
          <input-text
            v-model:text="vat"
            v-model:alert="vat_alert"
            :requred="b2b"
            :requirement="b2b"
            :placeholder="$t( b2b ? 'order.vat-number-b2b' : 'order.vat-number' )"
            @input="validateVat"
          />
        </div>
        <span v-if="vat && !vat_valid" class="w-full md:w-1/3 invalid-msg">{{ $t( 'order.vat-invalid' ) }}</span>
      </div>
    </div>

    <!-- PHONE NUMBER -->
    <div class="input-group">
      <p>{{ $t( 'order.number' ) }}*</p>
      <div class="w-full">
        <input
          v-model="vue_tel"
          type="text"
          :class="{ 'valid-false': !vue_tel && touched.phone_status }"
          placeholder="+49 123 456 7890"
          @blur="touched.phone_status = true"
          @input="validatePhone"
        >
      </div>
      <span v-if="rest.phone && !phone_valid" class="w-full invalid-msg">{{ $t( 'order.number_invalid' ) }}</span>
      <span v-if="!vue_tel && touched.phone_status" class="w-full invalid-msg">*{{ $t('contact.missing-field') }}</span>
      <span class="text-xs mt-1">{{ $t( 'order.number_info' ) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { user } from '../../init/client'
  import { reactiveCheckout } from '../../init/reactive-order'
  import type { Address, CreateAddress } from '@juitnow/api-addresses-v2'
  import { log } from '../../init/log'
  import { checkoutQueue } from '../../init/checkout-queue'
  import inputText from '../../widgets/input-text.vue'

  export default defineComponent({
    components: { inputText },
    props: {
      errors: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
      shipping: {
        type: Boolean,
        default: true,
      },
      b2b: {
        type: Boolean,
        default: false,
      },
      profilePage: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    emits: {
      create: (_body: CreateAddress, _isShipping: boolean, _formComplete: boolean) => true,
      complete: (_formComplete: boolean) => true,
      validvat: (_valid: boolean) => true,
      escape: () => true,
    },

    data: (instance) => ({
      autocomplete: null as null | google.maps.places.Autocomplete,
      phone_valid: null as null | boolean,
      vat_alert: false,
      vue_tel: '',
      zip_valid: null as null | boolean,
      google_place_id: '',
      place: {
        street_name: '',
        house_number: '',
        postal_code: '',
        city: '',
      },
      rest: {
        given_name: user.value ? user.value.given_name : '',
        family_name: user.value ? user.value.family_name : '',
        phone: '',
        address_extra: '',
        company: '',
        type: instance.shipping ? 'shipping' : 'billing' as 'shipping' | 'billing',
        default: true,
      },
      touched: {
        given_name_status: false,
        family_name_status: false,
        phone_status: false,
        street_name_status: false,
        house_number_status: false,
        postal_code_status: false,
        city_status: false,
      },
      vat: '',
      given_name_valid: false,
      family_name_valid: false,
      vat_valid: false,
    }),

    computed: {
      /* ========================================================================== *
       * All required field inputs                                                  *
       * -------------------------------------------------------------------------- */
      form_inputs() {
        return [
          this.place.city,
          this.place.street_name,
          this.place.house_number,
          this.zip_valid,
          this.rest.phone,
          this.phone_valid,
          this.vue_tel,
          this.given_name_valid,
          this.family_name_valid,
          // we pass this optional data only if the user wrote something in the input field
          this.rest.address_extra || true,
          this.b2b ? this.rest.company : (this.rest.company || true),
          // we pass the VAT number only for billing addresses and if the user wrote something in the input field
          this.shipping ? true : this.b2b ? this.vat_valid : (this.vat ? this.vat_valid : true),
        ]
      },
      // We need to watch google place id for emit as well
      update_create() {
        return [ this.google_place_id, ...this.form_inputs ]
      },

      /* ========================================================================== *
       * Check if all required fields are having valid input                        *
       * -------------------------------------------------------------------------- */
      form_complete() {
        return this.form_inputs.every((item) => item)
      },

      /* ========================================================================== *
       * If there is a validated address OR createAddress from session, use it      *
       * -------------------------------------------------------------------------- */
      loaded_add(): Address | CreateAddress | undefined {
        if (checkoutQueue.login_session === 'waiting') {
          return this.shipping ? reactiveCheckout.addresses.create_shipping : reactiveCheckout.addresses.create_billing
        } else return this.shipping ? reactiveCheckout.addresses.shipping : reactiveCheckout.addresses.billing
      },
    },
    watch: {
      vat_alert(value) {
        if (value) document.querySelector('.addresses.b2b')?.classList.add('vat_alert')
        else document.querySelector('.addresses.b2b')?.classList.remove('vat_alert')
      },

      loaded_add: {
        handler(add: Address | undefined) {
          // If the user is creating a new address in the profile page, we load an empty form
          if (this.profilePage) return
          // If createAddress from session failed, stop
          if (this.errors.length && checkoutQueue.login_session !== 'ongoing') return
          // Otherwise, if we got an valid address (with uuid), continue
          if (add?.uuid) this.loadAddress(add)
        },
        deep: true,
        immediate: true,
      },

      /* ========================================================================== *
       * Clear google place id when place changes                                   *
       * -------------------------------------------------------------------------- */
      place: {
        handler() {
          this.google_place_id = ''
        },
        deep: true,
      },

      /* ========================================================================== *
       * Update the form to address.vue, with or without google place id            *
       * -------------------------------------------------------------------------- */
      update_create: {
        handler(newValue, oldValue) {
          // Only emit the event when the create address values are different
          if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return
          const createAddObject = this.shipping ?
            { google_place_id: this.google_place_id, ...this.place, ...this.rest } :
            { google_place_id: this.google_place_id, ...this.place, ...this.rest, vat: this.vat }
          this.$emit('create', createAddObject, this.shipping, this.form_complete)
        },
        deep: true,
        immediate: true,
      },
    },

    mounted() {
      /* ========================================================================== *
       * Create the autocomplete object for Germany                                 *
       * -------------------------------------------------------------------------- */
      const input_street = this.$refs.streetname as HTMLInputElement
      this.autocomplete = new google.maps.places.Autocomplete(input_street, {
        componentRestrictions: { country: [ 'de' ] },
        fields: [ 'address_components', 'geometry', 'place_id' ],
        types: [ 'address' ],
      })

      /* ========================================================================== *
       * Populate the address fields in the form after user select                  *
       * -------------------------------------------------------------------------- */
      this.autocomplete.addListener('place_changed', this.fillInAddress)

      /* ========================================================================== *
       * If we are in the profile page > user is able to close form with escape     *
       * -------------------------------------------------------------------------- */
      if (this.profilePage) {
        document.addEventListener('keyup', this.onKeyUp)
      }
    },

    methods: {
      /* ========================================================================== *
       * Load the stored address if exist                                           *
       * -------------------------------------------------------------------------- */
      loadAddress(valid_add: Address | CreateAddress) {
        log(`%cAuto-filling the ${this.shipping ? 'shipping' : 'billing'} address: ${valid_add}, ${valid_add.street_name} ${valid_add.house_number}, ${valid_add.city}, ${valid_add.phone}`, 'color: blue')
        const place_keys = Object.keys(this.place) as Array<keyof typeof this.place>
        const rest_keys = Object.keys(this.rest) as Array<keyof typeof this.rest>
        place_keys.forEach((key) => this.place[key] = valid_add[key])
        rest_keys.forEach((key) => (this.rest as any)[key] = valid_add[key])
        this.vue_tel = valid_add.phone
        this.validatePhone()
        this.zipCheck()
        if (valid_add.type === 'billing' && valid_add.vat) {
          this.vat = valid_add.vat
          this.validateVat()
        }
        if (this.phone_valid && this.zip_valid) this.$emit('complete', true)
      },

      /* ========================================================================== *
       * Zipcode only 5 digits                                                      *
       * -------------------------------------------------------------------------- */
      zipCheck() {
        this.google_place_id = ''
        this.place.postal_code = this.place.postal_code ? this.place.postal_code.split('')
          .filter((digit) => /\d/.test(digit))
          .join('')
          .slice(0, 5) : ''
        this.zip_valid = this.place.postal_code.length === 5
      },

      /* ========================================================================== *
       * Validate Phone Number                                                      *
       * -------------------------------------------------------------------------- */
      validatePhone() {
        if (!this.vue_tel) return
        this.vue_tel = this.vue_tel
          .replace(/[^\d\s]+/g, '') // wipe all non-space non-digit
          .replace(/^[\s0]+/, '') // wipe all leading spaces and zeros
          .replace(/^(49)/, '+49') // add a + if already starting with 49
          .replace(/^(?!\+49)/, '+49') // otherwise, add +49
          .replace(/^\+49/, '+49 ') // and a space behind it
          .replace(/\s+/g, ' ') // normalize all spaces to ONE space

        this.rest.phone = this.vue_tel
          .replace(/[^\d]+/g, '') // strip all non-digit (including leading +)
          .replace(/^/, '+') // add a + to make it ITU format

        this.phone_valid = !! this.rest.phone.match(/\+[1-9][\d]{8,14}$/)
      },

      /* ========================================================================== *
       * Validate VAT Number                                                        *
       * -------------------------------------------------------------------------- */
      validateVat() {
        this.vat_valid = !! this.vat.match(/DE[0-9]{9}/) && this.vat.length === 11
        if (this.b2b) {
          this.$emit('validvat', this.vat_valid)
          if (this.vat && !this.vat_valid) document.querySelector('.addresses.b2b')?.classList.add('invalid_vat')
          else document.querySelector('.addresses.b2b')?.classList.remove('invalid_vat')
        }
      },

      /* ========================================================================== *
       * Google Map Autocomplete Function                                           *
       * -------------------------------------------------------------------------- */
      fillInAddress() {
        if (this.autocomplete) {
          let street = ''
          const place = this.autocomplete.getPlace()
          if (!place.address_components) return
          for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
            const componentType = component.types[0]
            switch (componentType) {
              case 'street_number': {
                this.place.house_number = component.long_name
                break
              }
              case 'route': {
                street += component.long_name
                break
              }
              case 'postal_code': {
                this.place.postal_code = component.long_name
                this.zip_valid = true
                break
              }
              case 'locality':
                this.place.city = component.long_name
                break
            }
          }
          this.place.street_name = street
          // Assign google place id after so it won't get wiped due to place change
          this.$nextTick(() => this.google_place_id = place.place_id || '' )
          this.alertAllRequiredFields()
        }
      },

      /* ========================================================================== *
       * Event handler for key up + escape                                          *
       * -------------------------------------------------------------------------- */
      onKeyUp(event: KeyboardEvent) {
        if (event.key !== 'Escape') return // do nothing if key is not "Escape"
        else {
          this.$emit('escape')
          document.removeEventListener('keyup', this.onKeyUp)
        }
      },

      alertAllRequiredFields() {
        this.touched = {
          given_name_status: true,
          family_name_status: true,
          phone_status: false,
          street_name_status: true,
          house_number_status: true,
          postal_code_status: true,
          city_status: true,
        }
      },
    },
  })
</script>


<style scoped lang="pcss">
  .address {
    .input-group {
      @apply flex flex-wrap -mx-1 mb-4;
      > * {
        @apply px-1;
      }
      p {
        @apply mb-1.5 w-full;
      }
      &:last-child {
        @apply mb-0;
      }
    }
  }
  .b2b {
    .vat {
      @apply absolute top-0;
      width: calc(100% - 1rem);
      .valid-false {
        @apply block mb-3;
      }
    }
  }
</style>
