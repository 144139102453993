<!--==================================================================+
| COMPONENT: CONTACT FORM                                             |
+===================================================================-->

<template>
  <form @submit.prevent="form_submit">
    <!-- Email -->
    <div class="input mb-1">
      <label for="email">{{ $t( 'contact.email-label' ) }}*</label>
      <input-email
        id="email"
        v-model:email="email"
        v-model:valid="email_valid"
        name="email"
      />
    </div>

    <!-- First name -->
    <div class="input mb-1">
      <label for="first_name">{{ $t( 'contact.fname-label' ) }}*</label>
      <input
        id="first_name"
        v-model="first_name"
        :placeholder="$t('contact.fname-label')"
        name="first_name"
        type="text"
      >
    </div>

    <!-- Last name -->
    <div class="input mb-1">
      <label for="last_name">{{ $t( 'contact.lname-label' ) }}*</label>
      <input
        id="last_name"
        v-model="last_name"
        :placeholder="$t('contact.lname-label')"
        name="last_name"
        type="text"
      >
    </div>

    <!-- Company -->
    <div class="input mb-1">
      <label for="company_name">{{ $t( 'contact.company-label' ) }}*</label>
      <input
        id="company_name"
        v-model="company_name"
        :placeholder="$t('contact.company-label')"
        name="company_name"
        maxlength="128"
        type="text"
      >
    </div>

    <!-- Phone number -->
    <div class="input mb-2">
      <label for="phone_number">{{ $t( 'contact.phone-label' ) }}</label>
      <input
        id="phone_number"
        v-model="phone_number"
        :placeholder="$t('contact.phone-label')"
        name="phone_number"
        type="text"
        @input="validate_phone"
      >
      <p v-if="phone_number && (!phone_number_valid)" class="invalid-msg">
        {{ $t( 'order.number_invalid' ) }}
      </p>
    </div>

    <!-- Employees -->
    <div class="input mb-2">
      <label class="employee" for="employee_count">{{ $t( 'contact.employees-label' ) }}*</label>
      <div
        id="employee_count"
        aria-required="true"
        tabindex="-1"
        class="radio-group"
      >
        <label class="radio-label">
          <input
            id="employee_count_option_0"
            v-model="employee_count"
            type="radio"
            name="employee_count"
            value="< 25"
          >
          <div class="radio-checked" />
          <span class="ml-6">&lt; 25</span>
        </label>
        <label class="radio-label">
          <input
            id="employee_count_option_1"
            v-model="employee_count"
            type="radio"
            name="employee_count"
            value="25 - 70"
          >
          <div class="radio-checked" />
          <span class="ml-6">25 - 70</span>
        </label>
        <label class="radio-label">
          <input
            id="employee_count_option_2"
            v-model="employee_count"
            type="radio"
            name="employee_count"
            value="70 - 250"
          >
          <div class="radio-checked" />
          <span class="ml-6">70 - 250</span>
        </label>
        <label class="radio-label">
          <input
            id="employee_count_option_3"
            v-model="employee_count"
            type="radio"
            name="employee_count"
            value="> 250"
          >
          <div class="radio-checked" />
          <span class="ml-6">&gt; 250</span>
        </label>
      </div>
    </div>

    <!-- Message, optional -->
    <div class="input ">
      <label for="customer_message">{{ $t( 'contact.message-label' ) }}</label>
      <textarea
        id="customer_message"
        v-model="customer_message"
        name="customer_message"
        :placeholder="$t('contact.message-label')"
        rows="4"
        max-rows="6"
        maxlength="250"
      />
    </div>
    <p class="float-right">
      {{ charsRemaining }} / 250
    </p>

    <juit-button type="submit" :disabled="!form_valid" class="submit mt-2 mb-6">
      {{ $t( 'contact.submit' ) }}
    </juit-button>
  </form>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { captureException } from '@sentry/browser'
  import alertModal from '../../modals/alert-modal.vue'
  import { logError } from '../../init/log'
  import { locale } from '../../init/i18n'

  export default defineComponent({
    name: 'JuitContact',
    data: () => ({
      email: '',
      email_valid: false,
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_number_valid: true,
      company_name: '',
      employee_count: '',
      customer_message: '',
    }),
    computed: {
      form_valid() {
        return this.email_valid &&
          this.first_name &&
          this.last_name &&
          this.phone_number_valid &&
          this.company_name &&
          this.employee_count
      },
      charsRemaining() {
        return 250 - this.customer_message.length
      },
      contact_type() {
        const url = this.$route.meta.alternateSlug as string || ''
        return url.includes('retail') ? 'retail' : url.includes('restaurants') ? 'gastro' : url.includes('freezer') ? 'freezer' : 'other'
      },
    },
    methods: {
      validate_phone() {
        if (!this.phone_number) return this.phone_number_valid = true
        this.phone_number = this.phone_number
          .replace(/[^\d]+/g, '') // wipe all non-digit
          .replace(/^0/, '49 ') // replace leading zero with 49
          .replace(/^/, '+') // add a + to make it ITU format

        this.phone_number_valid = !! this.phone_number.match(/\+[1-9][\d]{8,14}$/)
      },

      form_submit() {
        if (! this.form_valid) return

        Promise.resolve().then(() => this.$client.forms.b2bRegistration({
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          company_name: this.company_name,
          phone_number: this.phone_number || undefined,
          employee_count: this.employee_count,
          customer_message: this.customer_message,
          contact_type: this.contact_type,
          language: locale.value,
        })).catch((error) => {
          logError('Error submitting B2B Registration Form', error)
          captureException(error)
        })

        Promise.resolve().then(() => this.$createModal(alertModal as any, {
          title: this.$t('contact.confirm-email'),
          description: this.$t('contact.confirm-email-blurb'),
          locked: true,
        })).catch((error) => {
          logError('Error displaying B2B Registration confirmation alert', error)
          captureException(error)
        })

        return false // do not actually _submit_ the form!
      },
    },
  })
</script>
